.card_event_attending {
  width: 300px;
  height: 160px;

  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  border-radius: 16px;
  padding: 16px 0px;
  margin-right: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .share_btn {
    width: 32px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card_mark {
    width: 4px;
    height: 40px;
    background-color: #ff3e9a;
    margin-right: 12px;
  }

  .card_title {
    width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .card_desc {
    width: calc(100% - 26px);
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}
