.card_pending_invitation {
  width: 100%;
  padding: 18px 16px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  margin-top: 16px;

  .cancel_btn {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
