@import 'shared/utils/colors.scss';

.content_body_link {
  display: block;
  width: 477px;
  border: 1px solid $color-border;
  border-radius: 40px;
  background-color: $color-white;
  padding: 48px;
  justify-content: center;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .loading {
    display: flex;
    justify-content: center;
  }

  .verifying_link_txt {
    margin-top: 22px;
    text-align: center;
  }

  .will_take_time_txt {
    margin-top: 12px;
    text-align: center;
    color: rgba($color: #000000, $alpha: 0.6);
  }
}
