.mobile_personal_ai {
  display: flex;
  flex-direction: column;

  margin-bottom: 22px;

  .ai_plus_btn {
    width: 46px;
    height: 46px;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ai_list {
    margin: 0px 16px;
  }
}
