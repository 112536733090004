.mobile_prompt_add_bot {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;

  padding: 24px 0px;
  overflow: hidden;

  .header_view {
    padding: 0px 16px;

    .plus_btn {
      width: 36px;
      height: 36px;
      border-radius: 36px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .search_view {
    margin-top: 16px;
    padding: 0px 16px;
  }

  .mobile_add_bot_selected_list {
    min-height: 80px;
    padding: 0px 16px;
    margin-top: 6px;

    display: flex;
    flex-direction: row;
    overflow-x: auto;

    gap: 14px;
  }

  .mobile_add_bot_list {
    min-height: calc(100% - 180px);
    max-height: calc(100% - 180px);

    margin-top: 10px;

    display: flex;
    flex-direction: column;

    .list_body {
      overflow-y: auto;
      margin-top: 4px;

      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .action_view {
    padding: 0px 16px;
    padding-top: 16px;
  }

  .bot_icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0px;
    right: -6px;
  }
}
