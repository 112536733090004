.event_detail {
  display: flex;
  flex-direction: column;

  padding: 46px 80px;
  gap: 40px;

  .event_top {
    width: 100%;
    height: 350px;
    border-radius: 24px;
    background-color: rgba($color: #000000, $alpha: 0.1);

    position: relative;
    .event_top_content {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 24px;

      display: flex;
      flex-direction: column;

      .share_container {
        width: 56px;
        height: 56px;
        background-color: white;
        border-radius: 56px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .event_content_body {
        flex: 1;

        .event_detail_users {
          width: fit-content;
          padding: 12px;
          border-radius: 50px;
          background-color: white;

          .event_user_image {
            width: 36px;
            height: 36px;
            border-radius: 36px;
            object-fit: cover;
            background-color: rgba($color: #000000, $alpha: 0.2);
          }
        }
      }
    }
  }

  .event_bottom {
    display: flex;
    gap: 24px;

    &_left {
      flex: 1;
      height: fit-content;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
      border-radius: 24px;

      padding: 44px 32px;
      gap: 44px;

      display: flex;
      flex-direction: column;

      .reminde_me {
        width: 150px;
        height: 44px;
        border-radius: 44px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
      }
    }

    &_right {
      min-width: 300px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
      border-radius: 24px;

      padding: 44px 32px;
    }
  }

  .event_attending_card_image {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }

  .claim_btn {
    width: 100%;
    height: 38px;
    border-radius: 38px;
    background-color: #ff3e9a;
    color: white;
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .claimed_button {
    width: 100%;
    height: 38px;
    border-radius: 38px;
    color: black;
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #ffe5f2;
  }
}
