@import 'shared/utils/colors.scss';

.card_joined_group {
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 4px 12px 0px #00000026;
  padding: 24px 0px;
  margin-bottom: 24px;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0px 24px;
  }

  .card_joined_group_top {
    display: flex;
    align-items: center;
  }

  .description {
    color: rgba(0, 0, 0, 0.6);
    // margin-top: 8px;
  }

  .bottom {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .bottom_item {
      display: flex;
      align-items: center;
      justify-items: center;
      border: 1px solid $color-border;
      border-radius: 50px;
      margin-right: 8px;
      padding: 12px 24px 12px 24px;

      .bottom_item_txt {
        margin-left: 8px;
      }
    }
  }

  &_ask_join {
    width: 125px;
    margin-left: 16px;
  }

  &_questions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
    flex: 0 0 auto;
    overflow-y: auto;

    padding: 0px 24px;
  }
}
