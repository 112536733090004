.mobile_create_media {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f3f4f5;

  display: flex;
  flex-direction: column;

  .media_header {
    padding: 16px;

    .media_header_title {
      flex: 1;
      text-align: center;
    }

    .done_btn {
      width: 32px;
      height: 32px;
      border-radius: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .media_body {
    height: calc(100% - 70px);
    background: #ffffff;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 20px 0px;

    display: flex;
    flex-direction: column;

    .media_input {
      flex: 1;
      overflow-y: auto;
      margin: 32px 24px;
      border-radius: 24px;
      background-color: rgba(0, 0, 0, 0.05);

      display: flex;

      .media_content_body {
        flex: 1;
        border-radius: 24px;

        .media_view {
          width: 100%;
          height: 100%;
          border-radius: 24px;
          object-fit: contain;

          position: relative;
        }
      }
    }

    .media_action {
      width: 100%;
      gap: 8px;
      padding: 0px 16px;
      justify-content: center;

      .create_btn {
        flex: 1;
        height: 34px;
        border-radius: 34px;
        background-color: #ff3e9a;

        display: flex;
        align-items: center;
        justify-content: center;

        .create_btn_txt {
          color: white;
        }
      }

      .disabled_btn {
        flex: 1;
        height: 34px;
        border-radius: 34px;
        background-color: rgba(0, 0, 0, 0.1);

        display: flex;
        align-items: center;
        justify-content: center;

        .disabled_btn_txt {
          color: white;
        }
      }

      .creator_image {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        object-fit: cover;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .selected_creator_image {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        object-fit: contain;

        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #ff3e9a;
      }
    }
  }

  .slick-slider {
    width: 100%;
    height: 100%;

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;
      }

      .slick-track > div > div {
        height: 100%;
      }
    }
  }
}
