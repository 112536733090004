@import 'shared/utils/colors.scss';

.home_container {
  overflow-x: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .home_body {
    height: calc(100% - 101px);
    overflow-y: auto;

    .row_divide_line {
      width: 100%;
      height: 0px;
      opacity: 0.1;
      border: 1px solid #000000;
    }
  }
  .home_body_mobile {
    overflow-y: auto;
    height: calc(100% - 68px);
    // padding-left: 32px;
    // padding-right: 32px;
    // margin-top: 32px;
    background-color: white;
    .row_divide_line {
      width: 100%;
      height: 0px;
      opacity: 0.1;
      border: 1px solid #000000;
    }
  }
  // .AI_chat_button {
  //   position: fixed;
  //   right: 32px;
  //   bottom: 32px;
  //   width: auto;
  //   .ask_btn {
  //     display: flex;
  //     align-items: center;
  //     height: 46px;
  //     border-radius: 46px;
  //     background-color: $color-main;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     padding-left: 12px;
  //     padding-right: 24px;
  //     cursor: pointer;

  //     .ask_btn_txt {
  //       color: white;
  //       margin-left: 12px;
  //     }
  //   }
  // }
}
@media only screen and (max-width: 768px) {
  .react-horizontal-scrolling-menu--item {
    margin-right: 16px;
  }
}
