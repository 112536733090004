.org_un_answered {
  padding: 32px;
  padding-top: 60px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  .org_reference {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 36px;

    &_left {
      flex: 1;
      height: 60px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px #00000026;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding: 0px 20px;
    }

    &_right {
      width: 300px;
      height: 60px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px #00000026;
      border-radius: 16px;
      margin-left: 12px;
      display: flex;
      align-items: center;
      padding: 0px 20px;
    }

    .org_answer_reference_list {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: auto;
      margin-left: 8px;
    }
  }
}
