.mobile_feed_home {
  width: 100%;
  height: 100%;
  // background-color: #f3f4f5;
  background-color: white;

  display: flex;
  flex-direction: column;

  zoom: 80%;

  .feed_header {
    width: 100%;
    min-height: 80px;
    padding: 16px;
    padding-bottom: 0px;
    position: relative;

    display: flex;
    z-index: 3;
    gap: 6px;

    .left_view {
      width: calc(100% - 50px);
    }

    .action_item {
      width: 46px;
      height: 46px;
      border-radius: 46px;
      background-color: white;
      box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);

      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;

      position: relative;

      .filter_user_image {
        width: 38px;
        height: 38px;
        border-radius: 38px;
        object-fit: cover;
      }

      .filter_count {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background-color: #ff3e9a;
        border: 3px solid white;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0px;
        right: -4px;
      }

      .user_image {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        object-fit: cover;
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
    }
  }

  .feed_body {
    width: 100%;
    height: calc(100% - 56px);
    z-index: 2;
    margin-top: -24px;

    .feed_card_body {
      width: 100%;
      height: calc(100% - 32px);
      margin-top: 24px;
    }

    .right_action_view {
      position: absolute;
      height: fit-content;
      top: 0;
      bottom: 0;
      right: 0;
      margin-top: auto;
      margin-bottom: auto;
      background-color: white;
      box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
      padding: 16px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;

      .action_item {
        margin: 4px 0px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .vote_divider {
        margin-top: 16px;
        height: 1px;
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
    }

    .mobile_feed_card_chat,
    .mobile_card_bookmark,
    .mobile_card_article,
    .mobile_card_poll,
    .poll_graph,
    .mobile_card_file {
      box-shadow: none !important;
      border-radius: 0px !important;
    }

    .user_info {
      padding-top: 8px;
      gap: 8px;

      .user_img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        object-fit: cover;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .plus_btn {
    position: absolute;
    width: 64px;
    height: 64px;
    right: 16px;
    bottom: 120px;
    background: #ff3e9a;
    box-shadow: 0px 4px 28px 8px rgba(163, 0, 78, 0.2);
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 999;
  }

  .slick-slider {
    width: 100%;
    height: 100%;

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;
      }
    }
  }

  .ref_container {
    width: 100%;
    background: rgba(243, 244, 245, 0.5);
    border-radius: 40px;
    margin-top: 12px;
    padding: 8px 12px;

    .ref_text {
      margin-left: 6px;
      width: calc(100% - 60px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      opacity: 0.4;
    }
  }

  .slick-slide > div {
    width: 100%;
    height: 100%;
  }

  .slick-slide > div > div {
    width: 100%;
    height: 100%;
  }

  .react-card-front {
    background-color: white;
  }

  .react-card-back {
    background-color: white;
  }

  .loading_bar_container {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
