.mobile_individual_conversation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: white;
  padding-top: 16px;
}
