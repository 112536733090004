@import 'shared/utils/colors.scss';

.org_left_panel {
  padding: 60px 30px;
  height: 100%;
  width: calc(100% + 32px);
  margin-left: -16px;

  .infinite-scroll-component {
    padding: 8px;
  }

  .your_org_title {
    color: #000000;
    opacity: 0.3;
    backdrop-filter: blur(14px);
  }

  .all_org_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px #00000026;
    border-radius: 16px;
    margin-top: 16px;
    margin-left: 8px;
    margin-right: 8px;

    .all_org_box_icon {
      margin-right: 12px;
    }

    .all_org_box_label {
      backdrop-filter: blur(14px);
      color: $color-main;
    }
  }

  .sub_title {
    color: rgba(0, 0, 0, 0.3);
    margin-left: 8px;
  }

  .org_events {
    margin-top: 48px;

    .org_tab_container {
      display: flex;

      gap: 8px;
      margin-top: 16px;
      padding: 0px 8px;

      .selected_tab {
        flex: 1;
        height: 30px;
        background: #ffffff;
        border: 1px solid #ff3e9a;
        border-radius: 32px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .default_tab {
        flex: 1;
        height: 30px;
        background: #ffffff;
        border-radius: 32px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: rgba($color: #000000, $alpha: 0.4);
      }
    }

    .org_events_body {
      max-height: 400px;
      overflow-y: auto;
      margin-top: 16px;
      padding: 8px;
    }
  }

  .join_new_btn {
    color: $color-main;
    backdrop-filter: blur(14px);
    margin-top: 32px;
    margin-left: 8px;
  }

  .interest_title {
    color: #000000;
    opacity: 0.3;
    backdrop-filter: blur(14px);
    margin-top: 48px;
    margin-bottom: 16px;
  }

  .show_all_btn {
    color: $color-main;
    backdrop-filter: blur(14px);
    margin-top: 16px;
  }

  .event_empty_view {
    height: 320px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    margin: 0px 8px;
    margin-top: 16px;
    padding: 0px 64px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .create_event_btn {
      width: 180px;
      height: 44px;
      border-radius: 44px;
      background-color: #ff3e9a;
      color: white;
      margin-top: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
