.mobile_profile_top_org {
  background-color: white;
  padding-top: 24px;

  .add_btn {
    min-width: 40px;
    min-height: 40px;

    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(205, 193, 199, 0.6);
    border-radius: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    margin-left: 16px;
    position: relative;

    .btn_plus_bg {
      width: 14px;
      height: 14px;
      border-radius: 14px;
      background-color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  .org_list {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    overflow-x: auto;
  }
}
