.mobile_user_profile_record_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;

  .play_header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 24px;
  }

  .refresh_btn {
    width: 48px;
    height: 48px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .record_btn {
    width: 72px;
    height: 72px;
    border-radius: 72px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .record_stop_btn {
    width: 72px;
    height: 72px;
    border-radius: 72px;
    border: 5px solid #ff3e9a;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .record_refresh_btn {
    width: 72px;
    height: 72px;
    border-radius: 72px;

    background: #ff3e9a;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .done_btn {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload_btn {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #08b883;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .disabled_done_btn {
    width: 48px;
    height: 48px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .success_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .success_mark {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      background-color: #ff3e9a;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text_container {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0px 12px;
    }

    .continue_btn {
      width: 100%;
      height: 42px;
      border-radius: 42px;

      color: white;
      background-color: #ff3e9a;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .view_btn {
      width: 100%;
      height: 42px;
      border-radius: 42px;

      color: #ff3e9a;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.1);

      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }
  }
}

.top_indicator_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  .top_div {
    width: 60px;
    height: 4px;
    border-radius: 4px;
    background-color: #d9d9d9;
  }
}
