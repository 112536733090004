.send_question_modal {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .send_modal_title {
    flex: 1;
    text-align: center;
  }

  .org_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 0px;
    width: 100%;
    overflow-y: auto;
    max-height: 400px;

    .card_org {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 0px;
      gap: 12px;
      height: 72px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .org_box_icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .org_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        width: 100%;
        .first_line {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0px;
          width: 100%;
        }
        .second_line {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0px;
          gap: 8px;
          width: 100%;
          opacity: 0.4;
          // .members_count {

          // }
          .vip_members_count {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 8px;

            width: 326px;
            height: 18px;

            border-radius: 16px;
            .vip_avatars {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              padding: 0px;

              width: 40px;
              height: 16px;
            }
          }
        }
      }
      .org_select {
        .select_btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 6px 14px;
          gap: 8px;

          /* Main/Primary */

          background: #ff3e9a;
          border-radius: 48px;
        }
        .selected_btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 4px 12px;
          gap: 8px;
          height: 38px;
          opacity: 0.3;
          /* Background/White */
          background: #ffffff;
          /* Line/Default */
        }
      }
    }
  }

  .send_tab_container {
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;

    .default_tab_item {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 0.4);
    }

    .selected_tab_item {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 2px solid #ff3e9a;
    }
  }
}
