.mobile_individual_card_container {
  width: 100%;
  gap: 8px;

  .mobile_individual_chat_card {
    flex: 1;

    &_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 16px;

      gap: 4px;

      .user_image {
        width: 24px;
        height: 24px;
        border-radius: 24px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .creator_image {
        width: 20px;
        height: 20px;
        border-radius: 20px;
      }
    }

    .other_message {
      margin-top: 8px;
      border-radius: 0px 16px 16px 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 12px;
      width: fit-content;
      max-width: 80%;
      line-height: 25.2px;
    }

    .own_message {
      margin-top: 8px;
      border-radius: 16px 0px 16px 16px;
      padding: 12px;
      width: fit-content;
      background-color: #f3f4f5;
      max-width: 80%;
      line-height: 25.2px;
    }
  }

  .own_message_card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .mobile_individual_select_btn {
    width: 30px;
    height: 30px;

    .default_mark {
      width: 24px;
      height: 24px;

      /* Main/Gray Shade */

      background: #f3f4f5;
      /* Line/Default */

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }
  }
}
