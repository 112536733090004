@import 'shared/utils/colors.scss';

.org_question_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  border-radius: 24px;
  margin-bottom: 32px;
  margin-top: 8px;
  margin-left: 16px;
  margin-right: 16px;

  .send_star_btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px 8px 8px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    .send_star_btn_label {
      color: #000000;
      margin-left: 10px;
    }
  }

  .question_box_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .question_box_top_user {
      display: flex;
      align-items: center;

      .in_label {
        color: #000000;
        opacity: 0.6;
        margin-left: 12px;
        margin-right: 12px;
      }

      .org_name {
        color: #000000;
        backdrop-filter: blur(14px);
        margin-left: 8px;
      }

      .posted_question {
        color: #000000;
        opacity: 0.6;
        margin-left: 12px;
      }
    }
  }

  .question_text {
    border: none;
    border-width: 0px;
    width: 100%;
    cursor: pointer;
  }
  .question_text:focus {
    outline: none;
  }

  .question_feed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .question_feed_left {
      display: flex;
      align-items: center;

      .question_feed_number {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
      }

      .divide_line {
        width: 8px;
        height: 0px;
        opacity: 0.1;
        border: 1px solid #000000;
        transform: rotate(90deg);
        margin-left: 24px;
        margin-right: 24px;
      }
    }

    .question_feed_right {
      display: flex;
      align-items: center;

      .answer_date {
        color: #000000;
        opacity: 0.6;
      }

      .add_answer_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        background: $color-main;
        border: 1px solid $color-main;
        border-radius: 32px;
        color: #ffffff;
        margin-left: 24px;
      }
    }
  }

  .row_divide_line {
    width: 100%;
    height: 0px;
    opacity: 0.1;
    border: 1px solid #000000;
  }

  .org_ai_label {
    color: #000000;
    opacity: 0.3;
  }
  .general_ai_label {
    margin-left: 8px;
  }
  .general_ai_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
  }
  .switch_ai_btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px 8px 8px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    margin-right: 12px;
    cursor: pointer;
  }

  .refresh_btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px 8px 8px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
  }

  .question_detail_box {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 24px;

    .answer_text_area {
      width: 100%;
      height: 190px;
      border: none;
      border-width: 0px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .answer_text_area:focus {
      outline: none;
    }

    .cancel_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 32px;
      gap: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }

    .save_answer_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 32px;
      gap: 8px;
      background: $color-main;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      color: #ffffff;
    }

    .document_layout_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      gap: 8px;
      background: #ffffff;
      border: 1px solid $color-main;
      border-radius: 32px;
    }

    .user_star_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      margin-left: 8px;

      .star_icon {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px;
        gap: 8px;
        width: 32px;
        height: 32px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 40px;
      }
    }

    .my_chemistry_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px 8px 8px;
      gap: 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      margin-left: 8px;
      height: 48px;
    }

    .set_reference_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px 8px 12px;
      gap: 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      height: 48px;
      margin-left: 8px;
    }

    .edit_btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 12px;
      gap: 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }

    .post_as_answer_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 16px 12px 12px;
      gap: 8px;
      background: $color-main;
      border: 1px solid $color-main;
      border-radius: 32px;
      color: #ffffff;
      margin-left: 12px;
    }

    .reference_box {
      width: 100%;
      padding: 24px 24px 32px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0px 20px 20px 20px;
      margin-top: 16px;

      .reference_text {
        color: #000000;
        opacity: 0.8;
        margin-top: 24px;
        margin-bottom: 8px;
      }

      .reference_user {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 4px;

        .on_label {
          color: #000000;
          opacity: 0.4;
        }
      }
    }
  }

  .answer_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    width: 100%;

    .answer_box_top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .answer_box_top_left {
        display: flex;
        align-items: center;

        .top_voted_mark {
          padding: 4px 16px;
          background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
          border-radius: 24px;
          color: #ffffff;
          margin-left: 10px;
        }
      }

      .answer_box_top_right {
        display: flex;
        align-items: center;

        .answer_date {
          color: #000000;
          opacity: 0.4;
        }

        .divide_line {
          width: 12px;
          height: 0px;
          opacity: 0.1;
          border: 1px solid #000000;
          transform: rotate(90deg);
          margin-left: 28px;
          margin-right: 28px;
        }

        .answer_point {
          margin-right: 28px;
        }
      }
    }

    .answer_text {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .answer_box_bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .btn_label {
        white-space: nowrap;
      }

      .answer_box_bottom_left {
        display: flex;
        align-items: center;

        .agree_btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 20px 8px 12px;
          gap: 8px;
          background: rgba(255, 255, 255, 0.3);
          border: 1px solid rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(17px);
          border-radius: 32px;
          margin-right: 12px;

          .btn_number {
            color: #000000;
            opacity: 0.4;
            backdrop-filter: blur(14px);
          }
        }
      }

      .bookmark_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px 8px 12px;
        gap: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
      }
    }
  }
}
.similar_question_box {
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  border-radius: 24px;
  margin-top: 8px;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;

  .question_box_not_found {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .speech_balloon {
      width: 56px;
      height: 56px;
      margin-top: 24px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      line-height: 84px;
      display: flex;
      align-items: center;

      color: #000000;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .not_found_title {
      margin-top: 16px;
    }
    .not_found_description {
      margin-top: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      width: 23%;
      color: #000000;
      margin-bottom: 24px;
    }
  }
}
.post_question_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 24px;
  gap: 16px;
  height: 119px;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 12px 0px #00000026;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  .post_question_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    .post_question_text_title {
      font-style: normal;
      line-height: 30px;
      /* identical to box height */
      display: flex;
      align-items: center;

      color: #000000;
    }
    .post_question_text_description {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      display: flex;
      align-items: center;

      color: #000000;

      opacity: 0.4;
    }
  }
  .post_question_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    gap: 4px;

    width: 178px;
    height: 45px;

    /* Main/Primary */

    background: #ff3e9a;
    backdrop-filter: blur(14.5px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 48px;
    .post_question_btn_text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      display: flex;
      align-items: center;

      color: #ffffff;
    }
  }
}
.voted_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 24px;

  .voted_box_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .voted_box_top_left {
      display: flex;
      align-items: center;

      .is_voted_label {
        color: #000000;
        opacity: 0.6;
        margin-left: 4px;
        margin-right: 4px;
      }
    }

    .voted_box_top_right {
      display: flex;
      align-items: center;

      .divide_line {
        width: 12px;
        height: 0px;
        opacity: 0.1;
        border: 1px solid #000000;
        transform: rotate(90deg);
        margin-right: 12px;
      }

      .voted_date {
        color: #000000;
        opacity: 0.4;
        margin-right: 16px;
      }
    }
  }

  .question_answer_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    width: 100%;

    .question_answer_box_top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .question_answer_box_top_left {
        display: flex;
        align-items: center;

        .top_voted_mark {
          padding: 4px 16px;
          background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
          border-radius: 24px;
          color: #ffffff;
          margin-left: 10px;
        }
      }

      .question_answer_box_top_right {
        display: flex;
        align-items: center;

        .answer_date {
          color: #000000;
          opacity: 0.4;
          margin-right: 28px;
        }
      }
    }

    .question_text {
      margin-top: 16px;
    }

    .answer_text {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}

.answer_box_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .answer_box_bottom_left {
    display: flex;
    align-items: center;

    .agree_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 20px 8px 12px;
      gap: 8px;
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(17px);
      border-radius: 32px;
      margin-right: 12px;
    }
  }

  .bookmark_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 12px;
    gap: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
  }
}

.navigation_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 36px;

  .navigation_item {
    color: #000000;
    opacity: 0.2;
  }

  .cur_navigation_item {
    color: #000000;
  }

  .angel_right_icon {
    margin-left: 8px;
    margin-right: 8px;
  }
}
