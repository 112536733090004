.mobile_welcome_org {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;

  .loading_container {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .welcome_to {
    width: 100%;
    aspect-ratio: 344 / 228;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    position: relative;

    .welcome_to_bg {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      z-index: 2;

      position: absolute;
    }

    .welcome_main_body {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      z-index: 2;

      position: absolute;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .welcome_item {
        width: 90px;
        height: 90px;
        border-radius: 90px;
        background-color: white;
        box-shadow: 0px 4px 20px rgba($color: #ff3e9a, $alpha: 0.2);

        display: flex;
        align-items: center;
        justify-content: center;

        .welcome_item_image {
          width: 70px;
          height: 70px;
          border-radius: 70px;
        }
      }
    }

    .welcome_hand {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background-color: white;
      box-shadow: -8px 5.42857px 13.43px rgba(205, 12, 104, 0.1);

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      bottom: 20px;
      right: -16px;
    }
  }
}
