.card_received_question {
  margin-top: 32px;
  width: 100%;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 4px 12px 0px #00000026;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .border_btn {
    width: 48px;
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
