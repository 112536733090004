@import 'shared/utils/colors.scss';
.modal_vote_group {
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  .modal_vote_group_close {
    display: flex;
    justify-content: flex-end;
  }
  &_header {
    display: flex;
    align-items: center;

    .modal_back_btn {
      cursor: pointer;
    }

    .title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
    }
  }

  &_body {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    .vote_section {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      .satisfied {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        gap: 8px;
        background: #ffffff;
        border-radius: 40px;
        .thumbs_up {
          display: block;
          padding: 12px;
          gap: 8px;
          /* Main/Primary */
          text-align: center;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          cursor: pointer;
        }
        .thumbs_down {
          display: block;
          padding: 12px;
          gap: 8px;
          isolation: isolate;
          text-align: center;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          cursor: pointer;
        }
        .selected {
          border: 1px solid #ff3e9a;
          background: rgba(255, 230, 242, 0.5);
        }
        .non_selected {
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        .bacground_effect {
          position: relative;
          width: 100px;
          height: 100pxpx;
          left: calc(50% - 45px - 0.5px);
          top: -80px;

          background: #ffffff;
          mix-blend-mode: hue;
        }
      }
    }
    .sub_description {
      opacity: 0.6;
    }
    .btn_create {
      width: 30%;
    }
  }
}
