.mobile_chat_history {
  padding: 24px 16px;
  height: 100vh;
  overflow: hidden;

  &_title {
    color: rgba($color: #000000, $alpha: 0.3);
    margin-top: 60px;
    margin-bottom: 32px;
  }

  &_body {
    height: calc(100% - 24px);
    overflow-y: auto;
    margin-top: 16px;

    &_list {
      display: flex;
      flex-direction: column;
    }
  }

  .mobile_history_header {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &_left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 24px;
    }

    &_title {
      flex: 1;
      text-align: center;
    }
  }

  .loading_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
