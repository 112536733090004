@import 'shared/utils/colors.scss';

.card_my_question {
  background-color: white;

  &_left {
    width: 110px;

    &_marker {
      width: 36px;
      height: 3px;
      background-color: rgba(0, 0, 0, 0.4);
    }
    &_number {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  &_right {
    flex: 1;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    padding: 32px;

    .top_view {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .top_view_left {
        display: flex;
        align-items: center;

        .creator_name {
          margin-left: 8px;
          margin-right: 32px;
        }
      }

      .top_view_right {
        display: flex;
        align-items: center;

        .created_date_pre {
          width: 7px;
          opacity: 0.1;
          border: 1px solid #000000;
          transform: rotate(90deg);
        }

        .created_date {
          color: #000000;
          margin-left: 12px;
          margin-right: 12px;
          opacity: 0.4;
        }
      }
    }
  }

  .question_view {
    border-top: 1px solid $color-border;
    display: flex;
    flex-direction: column;
    gap: 14px;
    .answer_top {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .ai_tab {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .ai_general {
          padding: 8px 24px;
          filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.15));
          border-radius: 32px;
          color: #000000;
          opacity: 0.4;
        }

        .ai_group {
          padding: 8px 24px;
          background: #ffffff;
          border-bottom: 2px solid #ff3e9a;
          color: #ff3e9a;
        }
      }
    }

    .correct_answer {
      display: flex;
      margin-top: 32px;
    }

    .edit_answer {
      margin-top: 24px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
      border-radius: 24px;
      padding: 24px;

      .answer_box_input {
        width: 100%;
        border: none;
        border-width: 0px;
        margin-top: 32px;
        margin-bottom: 32px;
      }

      .answer_box_input:focus {
        outline-width: 0px;
      }

      .cancel_btn {
        width: 100px;
        height: 44px;
        border-radius: 44px;
        border: 1px solid $color-border;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .save_answer_btn {
        width: 144px;
        height: 48px;
        border-radius: 32px;
        background-color: $color-main;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .sub_info_right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add_regenerate_btn {
        width: 142px;
        height: 48px;
        border-radius: 32px;
        border: 1px solid $color-border;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }

      .edit_answer_btn {
        width: 153px;
        height: 48px;
        border-radius: 32px;
        border: 1px solid $color-border;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }

      .book_mark_btn {
        width: 144px;
        height: 48px;
        border-radius: 32px;
        background-color: $color-main;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .un_book_mark_btn {
        width: 193px;
        height: 48px;
        border-radius: 32px;
        background-color: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .sub_info {
      display: flex;
      align-items: center;

      .sub_info_left {
        flex: 1;
        display: flex;
        align-items: center;

        .reference_btn {
          width: 144px;
          height: 48px;
          border-radius: 32px;
          border: 1px solid $color-border;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .user_icon_btn {
          min-width: 140px;
          height: 48px;
          border-radius: 32px;
          border: 1px solid $color-border;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;

          .unlock_icon {
            width: 32px;
            height: 32px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 40px;
            display: flex;
            align-items: center;
            text-align: center;
            padding: 8px;
          }
        }

        .add_reference_btn {
          width: 152px;
          height: 48px;
          border-radius: 32px;
          border: 1px solid $color-border;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $color-border;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .answer_view {
    .answer_view_title {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
