.mobile_card_prompt_chat {
  width: 100%;

  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;

    gap: 4px;

    &_image {
      width: 24px;
      height: 24px;
      border-radius: 24px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .creator_image {
      width: 20px;
      height: 20px;
      border-radius: 20px;
    }
  }

  &_message {
    margin-top: 8px;
    border-radius: 0px 16px 16px 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 12px;
    width: fit-content;
    max-width: 80%;
    line-height: 25.2px;
  }

  &_own_message {
    margin-top: 8px;
    border-radius: 16px 0px 16px 16px;
    padding: 12px;
    width: fit-content;
    background-color: #f3f4f5;
    max-width: 80%;
    line-height: 25.2px;
  }

  .mobile_multichat_share_btn {
    color: #ff3e9a;
    white-space: nowrap;
    margin-top: 8px;
  }

  .summary_card {
    width: 100%;
    background: #f3f4f5;
    padding: 12px;
    border-radius: 12px;
    margin-top: 16px;
    padding-bottom: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .summary_message {
      width: 100%;
      padding: 8px;
      background-color: white;
      margin-top: 8px;
      text-align: center;
      line-height: 25.2px;

      border-radius: 8px;
    }
  }

  .audio_play_btn {
    width: 24px;
    height: 24px;
    background-color: white;
    /* Line/Default */

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mobile_multichat_card_container {
  width: 100%;
  gap: 8px;

  .mobile_multichat_select_btn {
    width: 30px;
    height: 30px;

    .default_mark {
      width: 24px;
      height: 24px;

      /* Main/Gray Shade */

      background: #f3f4f5;
      /* Line/Default */

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }
  }
}
