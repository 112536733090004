@import 'shared/utils/colors.scss';

.card_group_recommendation {
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  padding: 24px;
  margin-top: 24px;

  .group_image {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .dismiss_btn {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 48px;
    padding: 8px 24px;
    width: 48%;
    text-align: center;
  }

  .request_join_btn {
    padding: 8px 24px;
    background: $color-main;
    border-radius: 48px;
    color: #ffffff;
    width: 48%;
    text-align: center;
  }

  .requested_join_btn {
    padding: 8px 24px;
    background: #00000010;
    border-radius: 48px;
    color: black;
    width: 100%;
    text-align: center;
  }

  .card_group_invitation_top {
    display: flex;
    align-items: center;
  }

  .card_group_invitation_body {
    flex: 1;
    display: flex;
    align-items: center;

    .card_group_invitation_body_top {
      flex: 1;

      .card_group_invitation_body_top_body {
        flex: 1;
        display: flex;
        align-items: center;
      }
    }
    .card_group_invitation_body_right {
      display: flex;
      align-items: center;
      height: 28px;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 28px;
      border: 1px solid $color-border;
    }
  }

  .card_group_invitation_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    .dismiss_btn {
      flex: 1;
      height: 38px;
      border-radius: 38px;
      border: 1px solid $color-border;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .request_btn {
      flex: 1;
      height: 38px;
      border-radius: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-main;
      margin-left: 20px;
    }
  }
}
