@import 'shared/utils/colors.scss';

.header {
  height: 100px;
  background-color: white;
  border: 1px solid $color-border;
  background-color: $color-white;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  align-items: center;

  .leftView {
    display: flex;
    .title {
      margin-left: 8px;
      margin-bottom: 5px;
      letter-spacing: 2px;
    }
  }

  .centerView {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .rightView {
    display: flex;
    align-items: center;
    gap: 8px;
    .user_info {
      display: flex;
      border: 1px solid $color-border;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      width: 40px;
      height: 40px;
      cursor: pointer;

      .user_avatar {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        // background-color: lightgray;
      }

      .user_name {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
    .AI_chat_button {
      .ask_btn {
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 46px;
        background-color: $color-main;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 4px;
        width: 40px;
        cursor: pointer;

        .ask_btn_txt {
          color: white;
          margin-left: 12px;
        }
      }
    }
    .qr_code {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-border;
    }
  }
}
.header_mobile {
  padding: 16px;
  background-color: white;
  background-color: $color-white;
  // padding-left: 32px;
  // padding-right: 32px;
  display: flex;
  align-items: center;

  .leftView {
    display: flex;
    .title {
      margin-left: 22px;
    }
  }

  .centerView {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .rightView {
    display: flex;
    align-items: center;
    gap: 8px;
    .user_info {
      display: flex;
      border: 1px solid $color-border;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      width: 40px;
      height: 40px;
      cursor: pointer;

      .user_avatar {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        // background-color: lightgray;
      }

      .user_name {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
    .AI_chat_button {
      .ask_btn {
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 46px;
        background-color: $color-main;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 4px;
        width: 40px;
        cursor: pointer;

        .ask_btn_txt {
          color: white;
          margin-left: 12px;
        }
      }
    }
    .qr_code {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-border;
    }
  }
}
