.mobile_card_profile_top_org {
  margin-right: 12px;

  .selected_card {
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;

    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(205, 193, 199, 0.6);
    margin-bottom: 4px;
    margin-top: 4px;
  }

  .default_card {
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    background: #e9eaeb;
    opacity: 0.6;
    border-radius: 48px;
    margin-top: 4px;
  }

  .top_org_image {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    object-fit: contain;
  }

  .top_org_name {
    margin-left: 8px;
    white-space: nowrap;
  }
}
