.card_question_home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 0px 32px 32px;
  gap: 16px;
  height: 414px;
  margin-bottom: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  border-radius: 24px;
  overflow: hidden;
  position: relative;

  .bg_image {
    z-index: 0;
    width: 20%;

    position: absolute;
    right: 0px;
    img {
      visibility: visible;
      position: relative;
      float: right;
    }
  }

  .ref_container {
    width: 100%;
    background: rgba(243, 244, 245, 0.5);
    border-radius: 40px;

    padding: 8px 12px;
  }

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_top_bar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    height: 24px;
  }

  &_body {
    width: 100%;
    height: 240px;
    display: flex;
    align-items: center;

    .question_title_container {
      max-height: 100%;
      overflow-y: auto;
    }

    &_question_title {
      height: fit-content;
      position: relative;
      width: 80%;
    }
    &_answer {
      overflow-y: auto;
      height: 100%;
      width: 90%;
      padding-bottom: 100px;
    }
  }
  &_bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 20px;
    gap: 8px;
    height: 36px;
    background: #ffffff;
    /* Main/Primary */

    border: 1px solid #ff3e9a;
    border-radius: 32px;
    color: #ff3e9a;
  }
  &_bottom_back {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 20px;
    gap: 8px;

    height: 36px;
    opacity: 0.6;
    background: #ffffff;
    /* Main/Primary */
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 32px;
  }

  .bottom_container {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: calc(100%);
    padding-top: 64px;
    padding-bottom: 32px;
    background: linear-gradient(0deg, #ffffff 60%, rgba(255, 255, 255, 0) 100%);
  }
}
@media only screen and (max-width: 768px) {
  .card_question_home {
    height: auto;
  }
}
.card_question_home_mobile {
  padding: 32px 0px 32px 32px;

  height: 200px;
  margin-bottom: 32px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  &_inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    gap: 36px;
    overflow: auto;
    &_top_bar {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      height: 24px;
    }
    &_body {
      align-items: center;
      display: flex;
      width: 100%;
      &_question_title {
        position: relative;
        width: 90%;
      }
      &_answer {
        overflow-y: auto;
        height: 100%;
        width: 90%;
      }
      &_question_image {
        z-index: 0;
        width: 20%;
        img {
          visibility: visible;
          position: relative;
          float: right;
        }
      }
    }
    &_bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 20px;
      gap: 8px;
      height: 36px;
      background: #ffffff;
      /* Main/Primary */

      border: 1px solid #ff3e9a;
      border-radius: 32px;
      color: #ff3e9a;
    }
  }
  &_image {
    z-index: 0;
    width: 0%;
    img {
      visibility: visible;
      position: relative;
      float: right;
      scale: 1.5;
    }
  }
}
