@import 'shared/utils/colors.scss';

.tab_bar_item {
  cursor: pointer;

  .tab_item_default {
    display: flex;
    align-items: center;
    background-color: $color-white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 50px;
  }

  .tab_item_selected {
    display: flex;
    align-items: center;
    background-color: $color-pink;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 50px;
  }

  .selected_txt {
    color: $color-black;
    margin-left: 8px;
  }

  .default_txt {
    color: rgba($color: #000000, $alpha: 0.3);
    margin-left: 8px;
  }
}
