@import 'shared/utils/colors.scss';

.point_container {
  width: 800px;
  margin: 32px auto;

  .point_header {
    display: flex;
    align-items: center;
  }

  .point_available {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 48px;
    padding: 16px;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);

    &_icon {
      width: 44px;
      height: 44px;
      border-radius: 16px;
      background-color: #ffe5f280;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
