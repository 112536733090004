.mobile_welcome_list {
    margin-top: 24px;
    padding: 16px;

    background: #FFFFFF;
    /* Line/Default */

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    .item_container {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .item_divider {
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .progress_view {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background-color: white;
      box-shadow: 0px 4px 40px rgba($color: #FF3E9A, $alpha: 0.2);

    }

  .default_mark {
    width: 24px;
    height: 24px;

    /* Main/Gray Shade */

    background: #f3f4f5;
    /* Line/Default */

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
  }
}