.group_invitation_body {
  height: 100%;
  overflow: auto;
  padding: 0px 16px;
  padding-bottom: 100px;

  .invitation_empty_view {
    background-color: white;
    box-shadow: 0px 4px 12px 0px #00000026;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    border-radius: 16px;
    margin-left: -16px;

    .description {
      margin-top: 8px;
      margin-bottom: 24px;
      text-align: center;
    }
  }
}
