.mobile_chat {
  height: calc(100%);
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  .mobile_chat_main {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .qa_header {
      padding: 0px 16px;

      .row_align_items {
        gap: 8px;
      }
      .conversation_history_btn {
        width: 46px;
        height: 46px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 36px;
      }
    }

    .ongoing_chats_bar {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 20px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
      overflow-x: auto;

      .start_new {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;
        &_btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px;
          gap: 8px;

          width: 44px;
          height: 44px;

          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 16px;
        }
      }
      .ongoing_room {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;
        min-width: 80px;
      }
    }
    .top_creators {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0px 16px;
      margin-top: 16px;

      .top_creator_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        justify-content: space-between;

        .top_creator_filter {
          margin-left: 8px;

          border-bottom: 1px solid #ff3e9a;
        }
      }

      .search_top_creator_header {
        .search_top_view {
          width: 100%;
          padding-right: 24px;

          .title {
            text-align: center;
            flex: 1;
          }
        }
        .search_view {
          margin-top: 16px;
        }
      }

      .top_creator_tab {
        margin-top: 20px;

        display: flex;
        flex-direction: row;

        .default_btn {
          flex: 1;
          padding: 12px 16px;
          height: 34px;
          border-radius: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba($color: #000000, $alpha: 0.3);
          white-space: nowrap;
        }

        .selected_btn {
          flex: 1;
          padding: 12px 16px;
          height: 34px;
          border-radius: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          background-color: #ff3e9a;
          white-space: nowrap;
        }
      }

      .top_creators_body {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 16px;

        margin-top: 16px;
        padding-bottom: 16px;
      }
    }

    .empty_creator {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile_history_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    &_left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 18px;
      .back_icon {
        padding-bottom: 4px;
      }

      &_title {
        margin-left: 16px;
      }
    }
  }

  .mobile_history_body {
    width: calc(100% + 32px);
    margin-left: -16px;

    height: 100%;
    overflow: hidden;
    border-right: 1px solid rgba($color: #000000, $alpha: 0.1);

    padding: 0px 8px;
  }
}
