.mobile_card_explore_people {
  width: 64px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .user_image_container {
    position: relative;
  }

  .user_image {
    width: 64px;
    aspect-ratio: 1/1;
    border-radius: 100px;
    object-fit: cover;
    background-color: rgba($color: #000000, $alpha: 0.1);

    position: relative;
  }

  .user_name {
    width: 100%;
    text-align: center;

    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }

  .online_status_on {
    width: 20px;
    height: 20px;

    position: absolute;
    bottom: 0px;
    right: 0px;

    background: #22d654;
    border: 4px solid #ffffff;
    border-radius: 12px;
  }

  .audio_play_btn {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}
