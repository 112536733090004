.mobile_card_main_topic_item {
  min-width: 76px;
  height: 97px;
  padding: 0px 8px;

  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 56px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px 0px;

  .main_topic_name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 66px;
    color: #0000007a;
  }
}
