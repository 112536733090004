.mobile_feed_card_chat {
  height: 100%;

  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 8px 16px;
  padding-bottom: 24px;

  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;

  .chat_title {
    width: 100%;
    text-align: center;
  }

  .feed_chat_body {
    height: fit-content;
    background: linear-gradient(103.93deg, #ebf9f5 0%, #e9e1f0 101.36%);

    display: flex;
    flex-direction: column;
    gap: 16px;

    padding-bottom: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    border-radius: 16px;

    .feed_chat_message {
      padding: 0px 16px;
      border-radius: 16px;

      .creator_image {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        object-fit: cover;
        background-color: rgba(0, 0, 0, 0.1);
      }

      .creator_name {
        margin-left: 8px;
      }

      .message_body {
        width: 100%;
        border-radius: 0px 16px 16px 16px;
        background-color: white;
        padding: 16px;
        margin-top: 8px;
      }
    }
  }

  .did_know_bottom {
    position: fixed;
    left: 8px;
    bottom: -40px;
    width: calc(100% - 16px);
    height: 62px;
    border-radius: 16px;
    background: linear-gradient(
      180deg,
      #ffffff 20%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);

    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .top_shadow {
    position: absolute;
    left: 0px;
    top: 0px;
    width: calc(100% - 16px);
    height: 62px;
    background: linear-gradient(
      180deg,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 70%
    );
    z-index: 1;
  }

  .bot_icon {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0px;
    right: -6px;
  }
}
