.mobile_card_chat_history {
  background-color: white;
  border-radius: 16px;
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px;
  display: flex;
  justify-content: space-between;

  &_left_side {
    width: calc(100% - 30px);

    &_header {
      display: flex;
      flex-direction: row;
      align-items: center;

      &_image {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_group {
        margin-left: 8px;
      }
    }

    &_name {
      width: calc(100%);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_date {
      color: rgba(0, 0, 0, 0.4);
      margin-left: 8px;
    }
  }
  &_right_side {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff3e9a;
  }

  .trash_btn {
    min-width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
