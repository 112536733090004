@import 'shared/utils/colors.scss';

.mobile_profile_question_box {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px;
  background: #ffffff;
  border-radius: 24px;
  gap: 12px;
  margin-bottom: 24px;

  .right_panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    .send_star_btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 16px 8px 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      .send_star_btn_label {
        color: #000000;
        margin-left: 10px;
      }
    }
    .share_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;
      gap: 7px;
      width: 37px;
      height: 37px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }
  }

  .question_box_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .question_box_top_user {
      display: flex;
      align-items: center;

      .in_label {
        color: #000000;
        opacity: 0.6;
        margin-left: 4px;
        margin-right: 4px;
      }

      .org_name {
        color: #000000;
        backdrop-filter: blur(14px);
        margin-left: 0px !important;
      }

      .posted_question {
        color: #000000;
        opacity: 0.6;
        margin-left: 12px;
      }
    }
  }

  .question_feed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .question_feed_left {
      display: flex;
      align-items: center;

      .question_feed_number {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
      }

      .divide_line {
        width: 8px;
        height: 0px;
        opacity: 0.1;
        border: 1px solid #000000;
        transform: rotate(90deg);
        margin-left: 24px;
        margin-right: 24px;
      }
    }

    .question_feed_right {
      display: flex;
      align-items: center;

      .answer_date {
        color: #000000;
        opacity: 0.6;
      }

      .add_answer_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        background: $color-main;
        border: 1px solid $color-main;
        border-radius: 32px;
        color: #ffffff;
        margin-left: 24px;
      }
    }
  }

  .row_divide_line {
    width: 100%;
    height: 0px;
    opacity: 0.1;
    border: 1px solid #000000;
  }

  .org_ai_label {
    color: #000000;
    opacity: 0.3;
  }

  .switch_ai_btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px 8px 8px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    margin-right: 12px;
  }

  .refresh_btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px 8px 8px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
  }

  .question_detail_box {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-top: 32px;
    padding: 24px;
    margin-bottom: 32px;

    .answer_text_area {
      width: 100%;
      height: 190px;
      border: none;
      border-width: 0px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .answer_text_area:focus {
      outline: none;
    }

    .cancel_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 32px;
      gap: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }

    .save_answer_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 32px;
      gap: 8px;
      background: $color-main;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      color: #ffffff;
    }

    .document_layout_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      gap: 8px;
      background: #ffffff;
      border: 1px solid $color-main;
      border-radius: 32px;
    }

    .user_star_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      margin-left: 8px;

      .star_icon {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px;
        gap: 8px;
        width: 32px;
        height: 32px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 40px;
      }
    }

    .my_chemistry_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px 8px 8px;
      gap: 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      margin-left: 8px;
      height: 48px;
    }

    .set_reference_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px 8px 12px;
      gap: 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      height: 48px;
      margin-left: 8px;
    }

    .edit_btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 12px;
      gap: 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }

    .post_as_answer_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 16px 12px 12px;
      gap: 8px;
      background: $color-main;
      border: 1px solid $color-main;
      border-radius: 32px;
      color: #ffffff;
      margin-left: 12px;
    }

    .reference_box {
      width: 100%;
      padding: 24px 24px 32px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0px 20px 20px 20px;
      margin-top: 16px;

      .reference_text {
        color: #000000;
        opacity: 0.8;
        margin-top: 24px;
        margin-bottom: 8px;
      }

      .reference_user {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 4px;

        .on_label {
          color: #000000;
          opacity: 0.4;
        }
      }
    }
  }

  .answer_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    width: 100%;
    position: relative;
    margin-bottom: 0px !important;

    .top_voted_mark {
      position: absolute;
      top: 0px;
      left: -8px;
      padding: 2px 8px;
      background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
      border-radius: 8px 0px;
      color: #ffffff;
      margin-left: 10px;
    }

    .answer_box_top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .answer_box_top_left {
        display: flex;
        align-items: center;
        width: 50%;
      }

      .answer_box_top_right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .answer_date {
          color: #000000;
          opacity: 0.4;
        }

        .divide_line {
          width: 12px;
          height: 0px;
          opacity: 0.1;
          border: 1px solid #000000;
          transform: rotate(90deg);
          margin-left: 28px;
          margin-right: 28px;
        }
        .answer_top_bar_other_info_pt_btn {
          display: flex;
          flex-direction: row;
          gap: 28px;
          justify-content: space-between;
          .answer_point {
            display: flex;
            align-items: center;
          }
          .share_btn {
            margin-left: -20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px;
            gap: 7px;
            width: 37px;
            height: 37px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 32px;
          }
        }
      }
    }

    .answer_box_bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      // margin-top: 24px;

      .btn_label {
        white-space: nowrap;
      }

      .answer_box_bottom_left {
        display: flex;
        align-items: center;
        gap: 8px;

        .agree_btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 6px;
          gap: 8px;
          background: rgba(255, 255, 255, 0.3);
          border: 1px solid rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(17px);
          border-radius: 32px;

          .btn_number {
            color: #000000;
            opacity: 0.4;
            backdrop-filter: blur(14px);
          }
        }

        .agree_btn_highlighted {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 6px;
          gap: 8px;
          background: rgba(255, 255, 255, 0.3);
          border: 1px solid #ff3e9a;
          -webkit-backdrop-filter: blur(17px);
          backdrop-filter: blur(17px);
          border-radius: 32px;
          margin-right: 12px;
        }
      }

      .bookmark_btn {
        width: 210px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
      }
    }
  }

  .add_answer_bar {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    gap: 24px;
    height: 22px;
    width: 100%;

    .left_side {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .question_info {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 6px !important;
        height: 22px;

        .snack {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 4px !important;
        }
      }
      .question_points {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0px;
      }
    }
    .right_side {
      display: flex;
      justify-content: space-between;
      .question_date {
        display: flex;
        align-items: center;
        text-align: right;
        margin-right: 0px !important;
        color: rgba(0, 0, 0, 0.4);
      }
      .add_answer_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        height: 41px;
        background: #ff3e9a;
        border: 1px solid #ff3e9a;
        border-radius: 32px;
        color: #ffffff;
      }
    }
  }
  .line {
    opacity: 0.1;
    border: 1px solid #000000;
    width: 100%;
    margin: 24px 0px;
  }

  .round_btn {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}
