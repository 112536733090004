.mobile_chat_personal_ai {
  width: 100%;
  position: relative;

  .ai_image {
    width: 52px;
    height: 52px;
    border-radius: 52px;
    object-fit: cover;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }

  .ai_name {
    font-size: 18px;
    margin-top: 8px;
    text-align: center;

    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bot_icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 1px solid white;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0px;
    right: -6px;
  }
}
