.mobile_personal_type_second {
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  .chat_personal_card {
    width: 180px;
    padding: 8px 0px;
    border-radius: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
  }

  .card_number {
    width: 100%;
    color: rgba($color: #0f0f0f, $alpha: 0.25);
  }

  .card_right {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
  }

  .card_left {
    position: absolute;
    top: 24px;
    left: 0px;
    z-index: 2;
  }

  .card_center {
    margin-top: 110px;
    z-index: 3;
  }

  .tap_btn {
    margin-top: 24px;
    color: #ff3e9a;

    position: relative;
  }

  .tap_graph {
    width: fit-content;
    position: absolute;
    bottom: 8px;
    left: -80px;
    z-index: 4;
  }
}
