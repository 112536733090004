.card_all_shared_meme {
  width: 100%;
  height: 300px;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  margin-left: 16px;
  margin-bottom: 24px;

  background-color: white;
  position: relative;
  padding: 16px;

  display: flex;
  flex-direction: column;

  .meme_image {
    width: calc(100%);
    height: 220px;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.05);
    object-fit: contain;
  }

  .create_image {
    width: 32px;
    height: 32px;
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
