.mobile_search_prompt {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: white;

  padding: 0px 16px;
  padding-top: 24px;
  overflow: hidden;

  .prompt_search_header {
    width: 100%;
    gap: 16px;

    .search_view {
      flex: 1;
    }
  }

  .content_container {
    height: calc(100% - 50px);
    margin-top: 32px;

    display: flex;
    flex-direction: column;
  }

  .content_list {
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 8px;

    margin-top: 8px;
    padding-bottom: 50px;
  }
}
