.org_right_panel {
  padding-left: 36px;
}
.add_answer_right_panel {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 20px;
  height: 100%;
  .right_panel_tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    height: 56px;
    margin-top: 20px;
    justify-content: space-between;

    .selected_tab {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px 24px 16px 16px;
      gap: 12px;

      width: 50%;
      height: 56px;
      justify-content: center;
      background: #ffffff;
      /* Line/Default */

      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 32px;
    }

    .normal_tab {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px 24px 16px 16px;
      gap: 12px;
      height: 56px;
      width: 50%;
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
      border-radius: 32px;
      justify-content: center;
    }
  }
  .refernce_bar {
    margin-top: 20px;
    display: flex;
    .reference_select_btn {
      position: absolute;
      right: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      gap: 8px;
      width: 44px;
      height: 44px;
      border-radius: 32px;
      background-color: #ffffff;
      cursor: pointer;
    }
    .empty_reference_bar {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 24px 8px 16px;
      gap: 12px;
      background: #ffffff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
      border-radius: 32px;
      width: 100%;
      .icon_circle {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        gap: 8px;
        width: 34px;
        height: 34px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
      }
    }
    .selected_reference_bar {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;

      .reference_item1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px 8px 8px;
        gap: 8px;
        height: 40px;
        background: #ffffff;
        border-radius: 32px;
        .user_avatar {
          width: 30px;
          height: 30px;
          border-radius: 30px;
        }
        .user_name {
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }
  }
  .loading-circular {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  .empty_template_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 136px 0px;
    gap: 16px;
    border-radius: 16px;
    .light_bulb {
      width: 56px;
      height: 84px;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      line-height: 84px;
      /* identical to box height */

      display: flex;
      align-items: center;
    }
    .message_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 8px;

      width: 286px;
      height: 77px;
      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
      }
      .message {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
      }
    }
  }
}
