.mobile_bot_audio_option {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0px 24px;

  .audio_option_container {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 48px;

    .bot_choice_list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 40px;

      .choice_item {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
        border-radius: 24px;
        padding: 12px 16px;
        gap: 16px;

        .choice_text {
          flex: 1;
        }
      }
    }
  }
}
