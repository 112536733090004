.mobile_card_all_event {
  margin: 16px;

  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px 0px;
  padding: 24px 0px;

  display: flex;
  flex-direction: column;

  .card_header {
    padding: 0px 24px;

    .item_image {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      object-fit: cover;
      background-color: white;
    }

    .share_btn {
      width: 32px;
      height: 32px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .info_view {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 10px;
    flex: 1;

    .card_mark {
      width: 4px;
      height: 40px;
      background-color: #ff3e9a;
      margin-right: 12px;
    }

    .card_title {
      width: calc(100% - 26px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .card_desc {
      width: calc(100% - 26px);
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }

  .event_image {
    width: 130px;
    aspect-ratio: 1/1;
    border-radius: 16px;
    object-fit: cover;
    margin-right: 24px;
  }

  .claimed_button {
    width: 100%;
    height: 45px;
    border-radius: 38px;
    color: black;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #ffe5f2;
  }

  .claim_button {
    width: 100%;
    height: 45px;
    border-radius: 38px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #ff3e9a;
  }
}
