.mobile_community_search_users {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 16px;

  display: flex;
  flex-direction: column;

  .user_item {
    padding: 8px 20px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 40px;
  }

  .empty_creator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .loading_bar {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
