.question_detail {
  background: #f7fafc;
  height: calc(100%);

  .width20 {
    width: 20%;
  }
  .width80 {
    width: 80%;
  }

  .no_permission {
    padding: 100px 50px;
    padding-right: 82px;

    .no_permission_body {
      width: 100%;
      height: calc(100% - 100px);
      background: #ffffff;
      gap: 16px;
      /* Line/Default */

      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .request_btn {
      width: 200px;
      height: 45px;
      border-radius: 40px;
      background-color: #ff3e9a;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .requested_btn {
      width: 150px;
      height: 45px;
      border-radius: 40px;
      background-color: #ffe5f2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .selected_org_top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 32px;
    margin-bottom: 32px;
    &_info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
      gap: 32px;
      align-items: center;
      &_text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        // gap: 8px;
      }
      &_img {
        width: 84px;
        height: 84px;
        border-radius: 50%;
      }
    }
    &_members {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 24px;
      gap: 16px;
      height: 60px;
      background: #ffffff;
      justify-content: center;
      /* Line/Default */
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 56px;
    }
  }
}
