.question_title {
  flex: 1 1;
  text-align: start;
  margin-left: 20px;
}
.modal_post_question_body {
  margin-top: 24px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 24px;
  .textarea_question {
    margin-top: 24px;
    border: none;
  }
  .textarea_question:focus {
    outline: none;
  }
  .add_answer_bottom_bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 48px;
    .add_answer_bottom_bar_icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 16px;
      height: 44px;
      .icon_circle {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        gap: 8px;
        width: 44px;
        height: 44px;
        /* Line/Default */
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
      }
    }
    .add_answer_bottom_bar_btns {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px;
      gap: 7px;
      height: 48px;
      width: 100%;
      .add_answer_bottom_bar_referneces {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        height: 48px;
        .user_star_btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px;
          gap: 8px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 32px;
          margin-left: 8px;
          .user_avatar {
            width: 28px;
          }
          .star_icon {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px;
            gap: 8px;
            width: 32px;
            height: 32px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 40px;
          }
        }
        .user_star_btn_selected {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px;
          gap: 8px;
          background: #ffffff;
          border: 1px solid #ff3e9a;
          border-radius: 32px;
          margin-left: 8px;
          .user_avatar {
            width: 28px;
          }
          .star_icon {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px;
            gap: 8px;
            width: 32px;
            height: 32px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 40px;
          }
        }
      }
      .add_answer_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px 8px 12px;
        gap: 8px;
        background: #ff3e9a;
        border: 1px solid #ff3e9a;
        border-radius: 32px;
        color: #ffffff;
        width: 50%;
        justify-content: center;
      }
    }
  }
}
