.write_doc {
  width: 100%;
  height: calc(100%);

  .top {
    height: 80px;
    background-color: white;
    padding: 0px 32px;

    .cancel_btn {
      width: 100px;
      height: 40px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .save_btn {
      width: 100px;
      height: 40px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      background-color: #ff3e9a;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 900px;
    min-height: calc(100% - 150px);
    background-color: white;
    border-radius: 24px;
    margin-top: 60px;
    padding: 100px 72px;

    .text_input {
      flex: 1;
      border: none;
      border-width: 0px;
      outline-width: 0px;
    }

    .text_input:focus {
      outline-width: 0px;
    }
  }
}
