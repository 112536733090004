.did_you_know {
  .did_you_know_top {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 58px 0px 0px 80px;
    gap: 12px;
    .light_bulb {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px;
      gap: 16px;

      width: 60px;
      height: 60px;

      /* Main/Gradient */

      background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
      border-radius: 64px;
    }
  }

  .did_you_know_empty {
    background-color: white;
    border-radius: 16px;
    padding: 60px 32px;
    margin-top: 32px;
    margin-left: 80px;
    margin-right: 80px;
    display: flex;
    align-items: center;

    .create_new {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      background-color: #ff3e9a;
    }
  }

  .carouselWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    overflow: hidden;
    margin: 0px 80px 0px 80px;

    .carouselBox {
      width: 100%;
      height: 280px;
      flex: 1;

      & > div > div {
        width: calc(40%);
        transform: scale(0.8);
        opacity: 1 !important;

        &:first-child {
          transform: translateY(-50%) translateX(-10%) scale(0.8) !important;
          z-index: 1 !important;
          @media (max-width: 750px) {
            transform: translateY(-50%) translateX(-10%) scale(0.9) !important;
          }
        }

        &:nth-child(2) {
          z-index: 2 !important;
          transform: translateY(-50%) translateX(-30%) scale(0.87) !important;
          @media (max-width: 1050px) {
            transform: translateY(-50%) translateX(-30%) scale(0.9) !important;
            @media (max-width: 750px) {
              transform: translateY(-50%) translateX(-30%) scale(1) !important;
              & > div {
                opacity: 1;
              }
            }
          }
        }

        &:nth-child(3) {
          z-index: 4 !important;
          transform: translateY(-50%) translateX(-50%) scale(1) !important;
          & > div {
            opacity: 1;
          }
          @media (max-width: 1050px) {
            transform: translateY(-50%) translateX(-50%) scale(1) !important;
            & > div {
              opacity: 1;
            }
            @media (max-width: 750px) {
              &:last-child {
                transform: translateY(-50%) translateX(-50%) scale(0.9) !important;
              }
            }
          }
        }

        &:nth-child(4) {
          z-index: 3 !important;
          transform: translateY(-50%) translateX(-70%) scale(0.87) !important;
          @media (max-width: 1050px) {
            z-index: 2 !important;
            transform: translateY(-50%) translateX(-70%) scale(0.9) !important;
          }
        }

        &:nth-child(5) {
          z-index: 3 !important;
          transform: translateY(-50%) translateX(-90%) scale(0.93) !important;
          @media (max-width: 1050px) {
            z-index: 1 !important;

            &:last-child {
              transform: translateY(-50%) translateX(-90%) scale(0.8) !important;
            }
          }
        }

        &:nth-child(6) {
          z-index: 2 !important;
          transform: translateY(-50%) translateX(-50%) scale(0.87) !important;
        }

        &:last-child {
          z-index: 1 !important;
          transform: translateY(-50%) translateX(-90%) scale(0.8) !important;
          @media (max-width: 750px) {
            transform: translateY(-50%) translateX(-90%) scale(0.9) !important;
          }
        }

        & > div {
          width: 100% !important;
          opacity: 0.8;
          min-width: 380px !important;
          @media (max-width: 900px) {
            min-width: 380px !important;
          }

          & > div {
            width: 100%;
          }
        }
      }
    }
  }

  .arrowWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    gap: 16px;

    .arrowBox {
      display: flex;
      align-items: center;
      margin-right: 18px;
      margin-left: 18px;
      border-radius: 100px;
      cursor: pointer;
      position: relative;
      top: -187px;
      z-index: 100;
      box-shadow: 0px 4px 20px rgba(67, 134, 178, 0.3);
    }
  }
}
