.mobile_all_event {
  flex: 1;

  .tab_container {
    gap: 8px;
    margin: 0px 16px;
    margin-top: 16px;

    .selected_tab {
      flex: 1;
      height: 30px;
      background: #ffffff;
      border: 1px solid #ff3e9a;
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .default_tab {
      flex: 1;
      height: 30px;
      background: #ffffff;
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: rgba($color: #000000, $alpha: 0.4);
    }
  }

  .event_empty_view {
    margin: 0px 16px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    margin-top: 16px;
    padding: 32px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .create_event_btn {
      width: 150px;
      height: 36px;
      border-radius: 44px;
      background-color: #ff3e9a;
      color: white;
      margin-top: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
