@import 'shared/utils/colors.scss';

.out_of_service {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  .service_header {
    height: 100px;
    background-color: white;
    border: 1px solid $color-border;
    background-color: $color-white;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    align-items: center;

    .title {
      margin-left: 8px;
      margin-bottom: 5px;
      letter-spacing: 2px;
    }
  }

  .service_body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    padding-bottom: 100px;
  }
}
