.card_event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  border-radius: 16px;
  margin-bottom: 16px;

  .share_btn {
    width: 32px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card_event_user_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    justify-content: center;
  }

  .card_event_title {
    margin: 8px 0px;
  }

  .card_event_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .claimed_button {
      width: 100px;
      height: 30px;
      border-radius: 38px;
      color: black;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #ffe5f2;
    }

    .claim_button {
      width: 100px;
      height: 30px;
      border-radius: 38px;
      color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #ff3e9a;
    }
  }
}
