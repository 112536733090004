.mobile_point_history {
  flex: 1;
  border-radius: 16px;
  background: #ffffff;
  margin: 0px 16px;
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .calendar_view {
      padding: 12px 16px;
      border-radius: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .empty_creator {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
