.mobile_card_flip_question_container {
  height: 100%;

  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding-bottom: 0px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  position: relative;

  .user_avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .react-card-flip {
    height: 100%;
  }

  .card_container_flip {
    width: 100%;
    height: 100%;
  }

  .mark {
    background-color: transparent;

    .light_view {
      width: 32px;
      height: 32px;
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
