.mobile_community_search {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 16px;
  padding-bottom: 0px;
  overflow-y: auto;
  background-color: white;

  .top_view {
    .back_btn {
      width: 48px;
      height: 48px;

      background: #ffffff;
      border: 2px solid rgba(0, 0, 0, 0.16);
      border-radius: 48px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .header_title {
      flex: 1;
      text-align: center;
    }

    .filter_btn {
      width: 48px;
      height: 48px;
    }
  }
}
