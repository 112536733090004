.mobile_bot_modal_top_view {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  padding: 32px 16px;

  display: flex;
  flex-direction: column;

  .image_view {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    .user_image_container {
      width: 180px;
      height: 180px;

      border-radius: 180px;
      background-image: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
      padding: 5px;
      margin-bottom: 16px;

      position: relative;

      .user_image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      .profile_status {
        position: absolute;
        bottom: 10px;
        right: 10px;

        width: 40px;
        height: 40px;
        background: #ffffff;
        border-radius: 40px;
        padding: 2px;
      }
    }
  }
}
