.mobile_feed_creator_filter {
  height: 100%;
  margin-top: 12px;

  .mobile_feed_creator_filter_body {
    height: calc(100% - 50px);
    overflow-y: auto;
    margin-top: 12px;
  }  
}
