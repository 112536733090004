.mobile_invite_type {
  margin: 16px 16px;
  .invite_top_view {
    margin-left: 16px;
    gap: 16px;

    .selectedTab {
      color: #ff3e9a;
    }

    .defaultTab {
      color: rgba($color: #000000, $alpha: 0.2);
    }
  }

  .input_container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 12px 12px;
    margin-top: 12px;
  }
}
