.mobile_card_did_know {
  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 32px;
  margin: 0px 8px;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  position: relative;

  .user_avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .mark {
    width: fit-content;
    padding: 8px;

    background: rgba(255, 230, 242, 0.5);
    border-radius: 32px;
    gap: 8px;

    .light_view {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background-color: white;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .type_title {
      color: #ff3e9a;
      margin-right: 8px;
    }
  }

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .did_know_body {
    height: calc(100% - 46px);
    margin-top: 16px;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .feed_main_content {
      max-height: 100%;
      overflow-y: auto;
    }

    .did_know_image {
      width: 100%;
      aspect-ratio: 319 / 205;
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        #d9d9d9;
      border-radius: 16px;
    }

    .question_txt {
      font-size: 32px;
      line-height: 40.6px;
      margin-top: 16px;
    }
  }

  .did_know_bottom {
    position: absolute;
    left: 8px;
    bottom: 0px;
    width: calc(100% - 16px);
    border-radius: 24px;
    background: linear-gradient(
      180deg,
      #ffffff 70%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
    padding: 20px 16px;
    padding-bottom: 80px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    .link_container {
      transform: matrix(1, 0, 0, -1, 0, 0);

      padding: 8px 10px;
      background: rgba(243, 244, 245, 0.5);
      border-radius: 40px;
      gap: 8px;
    }

    .vote_container {
      gap: 8px;
      transform: matrix(1, 0, 0, -1, 0, 0);

      .vote_item {
        flex: 1;
        padding: 12px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        gap: 8px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .selected_vote_item {
        flex: 1;
        padding: 12px;
        background: #ffffff;
        border: 1px solid #ff3e9a;
        border-radius: 32px;
        gap: 8px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
