.card_jg_question {
  min-width: 370px;
  height: 250px;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  padding: 24px;
  border-radius: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &_top {
    display: flex;
    align-items: center;

    &_creator_name {
      margin-left: 8px;
    }

    &_created_date {
      color: rgba(0, 0, 0, 0.4);
      margin-left: 8px;
    }
  }

  &_body {
    display: flex;
    flex-direction: column;

    &_title {
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    &_answer {
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
}
