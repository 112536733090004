.mobile_community_search_recent {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .recent_search_txt {
    color: #000000;
    opacity: 0.4;
  }

  .delete_all_txt {
    color: #ff3e9a;
  }

  .history_list {
    display: flex;
    flex-direction: column;

    gap: 12px;
  }

  .empty_creator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
