.question_ask_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 16px;
  gap: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;

  .question_ask_box_input {
    border: none;
  }
  .question_ask_box_input:focus {
    outline: none;
  }

  .find_asnwer_btn {
    width: 190px;
    height: 44px;
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
