@import 'shared/utils/colors.scss';

.card_pending_member {
  width: 250px;
  height: 230px;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  padding: 24px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  .avatar {
    display: flex;
    justify-content: center;
  }
  .card_pending_member_action_view {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .reject_btn {
      width: 92px;
      height: 31px;
      border-radius: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-border;
    }
    .accept_btn {
      width: 92px;
      height: 31px;
      border-radius: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-border;
      background-color: $color-main;
      margin-left: 12px;
    }
  }
}
