.link_preview_top_filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 500px);

  &_tag {
    max-width: calc(100% - 50px);
  }

  .react-horizontal-scrolling-menu--arrow-right {
    display: none;
  }
}
