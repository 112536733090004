.mobile_my_ai {
  background-color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  padding: 32px 0px;
  padding-bottom: 0px;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  .ai_list {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;

    margin-top: 16px;
    padding-bottom: 16px;
  }
}
