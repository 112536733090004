.link_preview_body {
  margin-top: 40px;
  height: 100%;
  overflow-y: auto !important;
  padding: 0px 32px;

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
    padding-bottom: 24px;
  }

  /* Style your items */
}
