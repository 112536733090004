.mobile_org_member {
    margin: 16px;

    .org_member_header {
        gap: 8px;

        .org_image {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            object-fit: contain;
            background-color: rgba($color: #000000, $alpha: 0.1);
        }

        .org_title {
            flex: 1;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    
  .search_view {
    margin-top: 12px;
  }

  .org_member_body {
    margin-top: 18px;

    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}