.mobile_profile_card_personal_ai::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 1px;
  background: linear-gradient(
    180.44deg,
    #ff3e9a 11.34%,
    rgba(216, 62, 255, 0) 70.64%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.mobile_profile_card_personal_ai_other::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 1px;
  background: linear-gradient(
    180.44deg,
    #ff3e9a 11.34%,
    rgba(216, 62, 255, 0) 70.64%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.mobile_profile_card_personal_ai2::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    rgba(99, 99, 99, 0.2) 0%,
    rgba(255, 255, 255, 0) 72.91%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.mobile_profile_card_personal_ai {
  min-width: 350px;
  height: 140px;
  position: relative;
}

.mobile_profile_card_personal_ai_other {
  min-width: 350px;
  height: 80px;
  position: relative;
}

.mobile_profile_card_personal_ai_body {
  background: linear-gradient(
    180deg,
    rgba(255, 62, 154, 0.2) 0%,
    rgba(216, 62, 255, 0) 72.91%
  );
  border-radius: 24px;
  padding: 12px 16px;

  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  .top_info_view {
    gap: 12px;

    .ai_image {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      object-fit: cover;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }

    .user_image_container {
      position: relative;
      width: 64px;
      height: 64px;

      .user_image_content {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .user_image_view {
        position: relative;

        .camera_icon_tag {
          position: absolute;
          right: -3px;
          bottom: -3px;
          border-radius: 50%;
          background-color: #ff3e9a;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 100;

          .camera_icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .ai_name {
      flex: 1;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ai_action_btn {
      width: 40px;
      height: 40px;
      cursor: pointer;

      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .action_view {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 16px;

    .file_btn {
      flex: 1;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .chat_btn {
      flex: 1;
      height: 40px;
      background: #ff3e9a;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }

  .other_chat_btn {
    width: 40px;
    height: 40px;
    background: #ff3e9a;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .bot_icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0px;
    right: -6px;
  }
}
