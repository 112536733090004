@import 'shared/utils/colors.scss';

.success_create_group_modal {
  &_close_icon {
    margin-top: 16px;
    float: right;
  }

  &_icon {
    font-size: 80px;
    text-align: center;
  }

  &_title {
    margin-top: 40px;
    text-align: center;
  }

  &_copy {
    margin-top: 48px;

    &_body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      padding: 24px;
      border: 1px solid $color-border;
      border-radius: 16px;
    }
  }

  &_send {
    margin-top: 48px;

    &_body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      padding: 24px;
      border: 1px solid $color-border;
      border-radius: 16px;

      &_input {
        border: none;
        border-width: 0px;

        &:focus {
          outline-width: 0px;
        }
      }
      &_add {
        width: 70px;
        height: 38px;
      }
    }
  }
  &_otherWayText {
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  &_bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    gap: 16px;
    margin-top: 16px;
    &_left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 20px 16px 16px;
      gap: 16px;
      background: #FFFFFF;
      /* Line/Default */

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      width: 100%;
    }
    &_right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 20px 16px 16px;
      gap: 16px;
      background: #FFFFFF;
      /* Line/Default */

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      width: 100%;
    }
  }
}
