.mobile_prfoile_common_interest {
  display: flex;
  flex-direction: column;

  gap: 6px;

  .sub_title {
    margin-left: 16px;
  }

  .topic_list {
    display: flex;
    align-items: center;

    overflow-x: auto;
    gap: 16px;
    padding: 0px 16px;

    .mobile_topic_item {
      gap: 8px;

      .topic_name_view {
        padding: 2px 6px;
        border-radius: 4px;

        .topic_name {
          white-space: nowrap;
        }
      }
    }
  }

  .sub_topic_list {
    display: flex;
    align-items: center;

    overflow-x: auto;
    gap: 16px;
    padding: 0px 16px;

    .sub_topic_card {
      padding: 8px 8px;
      white-space: nowrap;
      margin: 8px 0px;
      border-radius: 8px;

      background: #ffffff;
      box-shadow: 4px 2px 10px rgba(0, 0, 0, 0.1);
    }

    .trash_btn {
      width: 24px;
      height: 24px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
