.mobile_card_my_personal_ai_review::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 24px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    #ff3e9a 11.34%,
    rgba(216, 62, 255, 0) 70.64%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.mobile_card_my_personal_ai_review {
  min-width: 100%;
  height: 130px;
  position: relative;
  overflow: hidden;

  .bg_container {
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: 100%;
    border-radius: 24px;
    background-image: linear-gradient(to bottom, #fff, transparent);
  }
}

.mobile_card_personal_ai_body {
  background: transparent;
  border-radius: 24px;
  padding: 12px 16px;

  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  .top_info_view {
    gap: 12px;

    .ai_image {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      object-fit: cover;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }

    .ai_name {
      flex: 1;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ai_action_btn {
      width: 40px;
      height: 40px;
      cursor: pointer;

      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .action_view {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 16px;

    .file_btn {
      flex: 1;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .chat_btn {
      flex: 1;
      height: 40px;
      background: #ff3e9a;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }

  .other_chat_btn {
    width: 40px;
    height: 40px;
    background: #ff3e9a;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .bot_icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0px;
    right: -6px;
  }
}
