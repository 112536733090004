@import 'shared/utils/colors.scss';

.home_my_draft {
  width: 100%;
  // height: calc(100% - 150px);

  .encrypt_view {
    margin-top: 32px;
    margin-left: 32px;
    margin-right: 32px;
    height: 45px;
    border-radius: 45px;
    background-color: rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .top_left {
      flex: 1;
      color: black;

      .top_sub_tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        .toggle_mobile_menu {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 10px;
          gap: 8px;

          width: 36px;
          height: 36px;

          /* Line/Default */
          margin-right: 12px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 24px;
        }
        &_item {
          width: 48px;
          height: 48px;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &_item_mobile {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .top_right {
      display: flex;
      align-items: center;

      .total_char {
        color: black;
      }

      .total_char_value {
        margin-left: 16px;
      }
    }
  }
  .top_mobile {
    display: flex;
    align-items: center;
    margin-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    .top_left {
      flex: 1;
      color: black;

      .top_sub_tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        .toggle_mobile_menu {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 10px;
          gap: 8px;

          width: 36px;
          height: 36px;

          /* Line/Default */
          margin-right: 12px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 24px;
        }
        &_item {
          width: 48px;
          height: 48px;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &_item_mobile {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .top_right {
      display: flex;
      align-items: center;

      .total_char {
        color: black;
      }

      .total_char_value {
        margin-left: 16px;
      }
    }
  }
  .mobile_tab_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    margin-top: 18px;
    .mobile_tab_item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 20px 12px 12px;
      gap: 8px;

      width: 100%;

      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
    }
  }
  .draft_view {
    margin-top: 24px;
    border-radius: 24px;
    background-color: $color-bg-black;
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
    cursor: default;
  }
  .draft_content_view {
    height: 600px;
    overflow: hidden;

    .answers {
      overflow-y: auto;
      margin-top: 32px;
    }
  }

  .draft_answer_input_view {
    display: flex;
    align-items: flex-end;
    padding-top: 24px;

    .answer_box_input {
      border: none;
      border-width: 0px;
      background-color: transparent;
      caret-color: $color-main;
      width: calc(100% - 500px);
      margin-right: 8px;
      height: 40px;
      resize: none;
    }

    .answer_box_input:focus {
      outline-width: 0px;
    }

    .upload_file_btn {
      min-width: 160px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba($color: #ffffff, $alpha: 0.2);

      .upload_file_btn_txt {
        color: white;
        margin-left: 8px;
      }
    }

    .record_audio_btn {
      width: 190px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 40px;
      margin-left: 8px;

      .record_audio_btn_txt {
        color: white;
        margin-left: 12px;
      }
    }
  }
  .draft_answer_input_view_mobile {
    display: flex;
    align-items: flex-end;
    padding-top: 24px;
    flex-direction: column;
    .answer_box_input {
      border: none;
      border-width: 0px;
      background-color: transparent;
      caret-color: $color-main;
      width: 100%;
      margin-right: 8px;
      height: 80px;
      resize: none;
    }

    .answer_box_input:focus {
      outline-width: 0px;
    }

    .upload_file_btn {
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      border: 1px solid rgba($color: #ffffff, $alpha: 0.2);

      .upload_file_btn_txt {
        color: white;
        margin-left: 8px;
      }
    }

    .record_audio_btn {
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 40px;
      margin-left: 8px;

      .record_audio_btn_txt {
        color: white;
        margin-left: 12px;
      }
    }
    .submit_btn_mobile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 4px;
      width: 167px;
      height: 40px;
      background: #ff3e9a;
      backdrop-filter: blur(14.5px);
      font-weight: 600;
      border-radius: 48px;
    }
  }
  .question_create {
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 4px 12px 0px #00000026;
    padding: 32px;
    margin-top: 40px;
    margin-left: 32px;
    margin-right: 32px;
    padding-bottom: 40px;

    .reference_box {
      width: 100%;
      padding: 24px 24px 32px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0px 20px 20px 20px;
      margin-top: 16px;

      .reference_text {
        color: #000000;
        opacity: 0.8;
        margin-top: 24px;
        margin-bottom: 8px;
      }

      .reference_user {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 4px;

        .on_label {
          color: #000000;
          opacity: 0.4;
        }
      }
    }
    &_body {
      display: flex;
      flex-direction: column;
      margin-top: 32px;

      .question_box_input {
        flex: 1;
        border: none;
        border-width: 0px;
      }

      .question_box_input:focus {
        outline-width: 0px;
      }
    }
  }
  .question_create_mobile {
    background-color: white;
    padding: 32px;
    margin-top: 40px;
    padding-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .reference_box {
      width: 100%;
      padding: 24px 24px 32px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0px 20px 20px 20px;
      margin-top: 16px;

      .reference_text {
        color: #000000;
        opacity: 0.8;
        margin-top: 24px;
        margin-bottom: 8px;
      }

      .reference_user {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 4px;

        .on_label {
          color: #000000;
          opacity: 0.4;
        }
      }
    }
    &_body {
      display: flex;
      flex-direction: column;
      margin-top: 32px;

      .question_box_input {
        flex: 1;
        border: none;
        border-width: 0px;
      }

      .question_box_input:focus {
        outline-width: 0px;
      }
    }
  }
  .post_question_btn {
    width: 180px;
    height: 44px;
    border-radius: 44px;
    background-color: $color-main;
    display: flex;
    align-items: center;
    justify-content: center;

    &_find_btn {
      display: flex;
      align-items: center;
    }
  }
  .post_question_btn_mobile {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    background-color: $color-main;
    display: flex;
    align-items: center;
    justify-content: center;

    &_find_btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &_bottom {
    margin-top: 32px;

    .add_reference_btn {
      width: 152px;
      height: 48px;
      border-radius: 32px;
      border: 1px solid $color-border;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
    }
  }

  .answer_input_view {
    display: flex;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;

    .answer_box_input {
      flex: 1;
      border: none;
      border-width: 0px;
      background-color: transparent;
      caret-color: $color-main;
    }

    .answer_box_input:focus {
      outline-width: 0px;
    }

    .upload_file_btn {
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      border: 1px solid rgba($color: #ffffff, $alpha: 0.2);

      .upload_file_btn_txt {
        color: white;
        margin-left: 8px;
      }
    }

    .record_audio_btn {
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 40px;
      margin-left: 8px;

      .record_audio_btn_txt {
        color: white;
        margin-left: 12px;
      }
    }
  }

  .draft_filter_tags_view {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .tags_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 40px;
      margin-right: 8px;

      .tags_btn_txt {
        color: #ffffff;
        margin-left: 8px;
      }
    }
  }

  .drop-area {
    background: #000000;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.7;
    display: flex;
    align-items: center;
    color: white;
    z-index: 1000;
  }

  .add_dump_type_btn {
    height: 40px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
  }

  .infinite-scroll-component {
    padding-left: 32px;
  }
}

.drag_mask_view {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;
}
