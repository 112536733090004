.mobile_multi_chat {
  height: calc(100% - 120px);

  display: flex;
  flex-direction: column;

  .multi_message_body {
    flex: 1;
    overflow-y: auto;
    padding: 0px 16px;
    margin-top: 16px;

    display: flex;
    flex-direction: column-reverse;
  }

  .multi_message_input {
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    margin: 16px;

    .multip_mesasge_chat_box_input {
      flex: 1;
      border: none;
      border-width: 0px;
      height: 30px;
      margin-left: 8px;
      background-color: white;
    }

    .multip_mesasge_chat_box_input:focus {
      outline-width: 0px;
    }
  }

  .multi_message_share_view {
    margin-top: 16px;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px -10px 50px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .ended_chat {
    text-align: center;
    padding: 16px 8px;
    gap: 8px;

    background: #f3f4f5;
    border-radius: 24px;
    margin: 16px;
  }

  .status_container {
    width: fit-content;
    max-width: 300px;
    margin-top: 16px;

    display: flex;
    flex-direction: column;

    .loading_bar {
      margin-left: 16px;
      margin-top: 8px;
    }

    .browsing_status {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 16px;

      padding: 12px;
      gap: 8px;
      margin-top: 16px;
      margin-bottom: 8px;

      .expand_btn {
        margin-left: 16px;
      }
    }

    .analysing_status {
      width: 100%;
      padding: 8px 16px;
      border-radius: 8px;
      box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
      margin-bottom: 8px;
      background-color: rgba(0, 0, 0, 0.05);

      .analysing_status_txt {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: calc(100%);
      }
    }
  }

  .trascript_btn {
    width: 32px;
    height: 32px;
    background-color: 32px;
    border: 1px solid #ff3e9a;
    border-radius: 32px;
    margin-right: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
