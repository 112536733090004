.mobile_personal_type_first {
  width: 100%;

  border-radius: 24px;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .action_view {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 16px;

    .file_btn {
      flex: 1;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .chat_btn {
      flex: 1;
      height: 40px;
      background: linear-gradient(
        180deg,
        #0f0f0f 0%,
        rgba(15, 15, 15, 0.8) 100%
      );
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
}
