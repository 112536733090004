.org_trending_question {
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: 64px;

  .infinite-scroll-component {
    padding: 0px 16px;
    padding-top: 8px;
  }

  .empty_trending_questions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    gap: 16px;
    height: 567px;

    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    .empty_trending_questions_body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px 0px;
      gap: 16px;
      text-align: center;
      width: 286px;
      height: 255px;

      background: #ffffff;
      border-radius: 16px;
      .empty_trending_questions_body_message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;
      }
      .create_new_post {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
        gap: 12px;
        height: 42px;

        background: #ff3e9a;
        border: 10px solid rgba(255, 62, 154, 0.1);
        backdrop-filter: blur(14.5px);

        border-radius: 32px;
      }
    }
  }
}
