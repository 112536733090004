.mobile_bot_sub_topics {
  width: calc(100vw - 100px);
  height: 76px;

  position: absolute;
  bottom: 12px;
  left: 100px;

  .sub_topics_list {
    display: flex;
    overflow-x: auto;
    gap: 16px;
    padding-left: 8px;
    padding-bottom: 16px;
    padding-right: 16px;
    margin-top: 8px;
  }

  .default_item {
    height: 65px;
    padding: 0px 8px;

    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 56px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .default_item_txt {
    margin: 0px 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #0000007a;
    text-align: center;
  }

  .selected_item {
    height: 65px;
    padding: 0px 8px;

    background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 56px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .selected_item_txt {
    margin: 0px 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: white;
    text-align: center;
  }
}
