.point_history {
  padding: 38px 24px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  margin-top: 48px;

  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .calendar_view {
      padding: 12px 16px;
      border-radius: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
