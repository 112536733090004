@import 'shared/utils/colors.scss';

.group_detail {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 32px;

  .encrypt_view {
    margin-top: 32px;
    margin-right: 32px;
    margin-left: 32px;
    height: 45px;
    border-radius: 45px;
    background-color: rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AI_chat_button {
    position: fixed;
    right: 32px;
    bottom: 32px;
    width: auto;
    .ask_btn {
      display: flex;
      align-items: center;
      height: 46px;
      border-radius: 46px;
      background-color: $color-main;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 12px;
      padding-right: 24px;
      cursor: pointer;

      .ask_btn_txt {
        color: white;
        margin-left: 12px;
      }
    }
  }
  .group_detail_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 32px;
    padding-right: 32px;
    margin-left: 32px;

    &_image {
      width: 68px;
      height: 68px;
      margin-left: 24px;
    }

    .header_info {
      flex: 1;
      margin-left: 24px;
    }

    .member_view {
      padding-left: 12px;
      padding-right: 12px;
      height: 68px;
      border-radius: 16px;
      border: 1px solid $color-border;
      display: flex;
      align-items: center;
      justify-items: center;
      margin-right: 16px;
      position: relative;

      &_icon {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f3f4f5;
        border-radius: 8px;
      }

      &_info {
        margin-left: 8px;

        &_title {
          color: rgba($color: #000000, $alpha: 0.4);
        }
      }
    }

    .member_code {
      padding-left: 12px;
      padding-right: 12px;
      height: 68px;
      border-radius: 16px;
      border: 1px solid $color-border;
      display: flex;
      align-items: center;
      justify-items: center;
      background-color: #f3f4f580;

      &_info {
        &_title {
          color: rgba($color: #000000, $alpha: 0.4);
        }
      }

      &_icon {
        margin-left: 16px;
      }
    }
  }

  .group_detail_nav_bar {
    margin-top: 42px;
  }
}

.group_not_auth {
  height: calc(100% - 200px);
  background: #ffffff;
  margin: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .request_join {
    width: 200px;
    height: 45px;
    border-radius: 45px;
    background-color: #ff3e9a;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    .request_txt {
      color: white;
      margin-left: 12px;
    }
  }

  .card_group_invitation_bottom {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    .dismiss_btn {
      flex: 1;
      height: 38px;
      border-radius: 38px;
      border: 1px solid $color-border;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }

    .request_btn {
      flex: 1;
      height: 38px;
      border-radius: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-main;
    }
  }
}

.pendingCount {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3e9a;

  position: absolute;
  top: -10px;
  left: -10px;
}
