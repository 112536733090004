@import 'shared/utils/colors.scss';

.modal_create_group {
  margin-top: 16px;

  .modal_create_group_header {
    display: flex;
    align-items: center;

    .modal_back_btn {
      cursor: pointer;
    }

    .title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
    }
  }

  .modal_create_group_body {
    margin-top: 48px;
    
    .statement_text {
        max-height: 285px;
        overflow-y: auto;
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
    }
    .statement_bottome {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 16px;
        margin-top: 24px;
        &_user {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 4px;
            font-style :'font-normal';
            &_name {
                font-weight: 500;
            }
        }
        &_btns {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 16px 8px 8px;
            gap: 7px;
            height: 37px;

            /* Line/Default */

            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 32px;
            &_share {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 7px;
                opacity: 0.3;
                /* Line/Default */
                
            } 
        }

    }
  }
}