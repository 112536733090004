@import 'shared/utils/colors.scss';

.modal_event_select_org {
  margin-top: 16px;

  .modal_event_select_org_header {
    display: flex;
    align-items: center;
    gap: 20px;
    .modal_back_btn {
      cursor: pointer;
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 24px;
    }
  }

  .modal_event_select_org_body {
    margin-top: 0px !important;

    .org_list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 0px;
      width: 100%;
      overflow-y: auto;
      max-height: 400px;
      .card_org {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 0px;
        gap: 12px;

        height: 72px;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .org_box_icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .org_info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          width: 100%;
          .first_line {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0px;
            width: 100%;
          }
          .second_line {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            gap: 8px;
            width: 100%;
            opacity: 0.4;
            // .members_count {

            // }
            .vip_members_count {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 0px;
              gap: 8px;

              width: 326px;
              height: 18px;

              border-radius: 16px;
              .vip_avatars {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;

                width: 40px;
                height: 16px;
              }
            }
          }
        }
        .org_select {
          .select_btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6px 14px;
            gap: 8px;

            /* Main/Primary */

            background: #ff3e9a;
            border-radius: 48px;
          }
          .selected_btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 12px;
            gap: 8px;
            height: 38px;
            opacity: 0.3;
            /* Background/White */
            background: #ffffff;
            /* Line/Default */
          }
        }
      }
    }

    .btn_create {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      .cancel_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 16px;
        height: 45px;
        width: 100%;
        background: #ffffff;
        /* Line/Default */

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 48px;
      }
      .cancel_btn:hover {
        background: #f9f9f9;
      }
    }
  }

  .action_view {
    padding-top: 32px;

    display: flex;
    align-items: center;

    .cancel_btn {
      flex: 1;
      height: 45px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 48px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .publish_btn {
      flex: 1;
      height: 45px;
      border-radius: 48px;
      background-color: #ff3e9a;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
