.modal_custom_body {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba($color: #000000, $alpha: 0.1);
}
.modal_select_ai_type {
  width: 350px;
  position: absolute;
  top: 294px;
  right: 141px;
  background: #ffffff;
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 16px;
  z-index: 1;

  &_header {
    display: flex;
    align-items: center;
  }

  &_body {
    margin-top: 24px;

    &_item {
      display: flex;
      align-items: center;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    }

    .ai_type_icon {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 768px) {
  .modal_select_ai_type {
    top: 30%;
    right: 5%;
    width: 90%;
    // bottom: 0;
  }
}
