.mobile_question_asked {
  margin: 0px 16px;

  .action_icon {
    width: 32px;
    height: 32px;
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid rgba($color: #000000, $alpha: 0.1);
  }

  .ai_info_view {
    gap: 8px;
  }

  .ai_image {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: contain;
  }

  .answer_txt {
    margin: 16px 0px;

    min-height: 40px;
  }

  .edit_answer_btn {
    flex: 1;
    height: 34px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 34px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .post_answer_btn {
    flex: 1;
    height: 34px;
    border-radius: 34px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    color: 'white';
  }

  .answer_edit_view {
    height: fit-content;
    border: none;
  }
  .answer_edit_view:focus {
    outline: none;
  }
}
