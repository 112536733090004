.mobile_define_prompt {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: white;
  padding: 32px 16px;
  gap: 24px;

  .header_view {
    padding-right: 24px;
  }

  .desc_view {
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 16px;

    .desc_icon {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      font-size: 48px;
    }
  }

  .input_view {
    flex: 1;

    display: flex;
    flex-direction: column;

    .ai_image {
      width: 20px;
      height: 20px;
      border-radius: 24px;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }

    .chat_box_input {
      flex: 1;
      border: none;
      border-width: 0px;
      background-color: #f7fafc;

      padding: 8px;
      border-radius: 8px;
      margin-top: 8px;
    }

    .chat_box_input:focus {
      outline-width: 0px;
    }
  }

  .action_view {
    margin-bottom: 24px;
  }
}
