.card_other_user_answer {
  margin-top: 24px;
  .answer_box_bottom {
    .answer_box_bottom_left {
      display: flex;
      align-items: center;
      margin-top: 16px;
  
      .bookmark_btn_icon_only {
        width: 45px;
        height: 45px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 45px;
        margin-right: 16px;
      }
  
      .agree_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 20px 8px 12px;
        gap: 8px;
        background: rgba(255, 255, 255, 0.3);
        border: 1px solid rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(17px);
        border-radius: 32px;
        margin-right: 12px;
  
        .btn_number {
          color: #000000;
          opacity: 0.4;
          backdrop-filter: blur(14px);
        }
      }
      .agree_btn_highlighted {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 20px 8px 12px;
        gap: 8px;
        background: rgba(255, 255, 255, 0.3);
        border: 1px solid #ff3e9a;
        -webkit-backdrop-filter: blur(17px);
        backdrop-filter: blur(17px);
        border-radius: 32px;
        margin-right: 12px;
      }
    }
  }

}
