@import 'shared/utils/colors.scss';
.summarize_modal_custom_body {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
}

.dlg_summarize {
  position: absolute;
  width: 235px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px 16px 0px 16px;
  padding: 16px;
  z-index: 1;
  z-index: 1000;

  .item_container {
    display: flex;
    align-items: center;
    padding: 12px 0px;

    .item_text {
      flex: 1;
      margin-left: 12px;
    }
  }

  .item_divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .input_pages {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .input_page {
      flex: 1;
      height: 30px;
      border-radius: 30px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .input_body {
      width: 85px;
      text-align: center;
    }
  }
}
