.document_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: 'hidden';
  background-color: #f3f4f5;

  .document_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background-color: white;
    padding: 0px 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .document_body {
    padding: 32px;
    height: calc(100% - 100px);
  }

  .go-to-box {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2px 24px;
      gap: 8px;
      width: 62px;
      height: 33px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 40px;
      margin-left: 8px;
      text-align: center;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    button {
      display: flex;
      align-items: center;
      padding: 2px 16px;
      gap: 8px;
      width: 55px;
      height: 33px;
      background: #ff3e9a;
      border-radius: 40px;
      color: #fff;
      margin-left: 8px;
      border: none;
    }
  }

  .document_control {
    padding: 12px 32px;

    .scroll_to_question_btn {
      padding: 10px 16px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px -4px 20px -8px rgba(0, 0, 0, 0.1);
      border-radius: 16px 16px 0px 0px;
    }

    .pagenation-section {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding-bottom: 18px;

      button {
        background: transparent;
        border: none;
      }
    }

    .actions {
      .action_item {
        width: 150px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 44px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }
  .react-pdf__Document {
    margin: 18px 25px;
    display: flex;
    gap: 16px;
    overflow-x: scroll;
    overflow-y: hidden;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(216, 219, 223, 0.9);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c3c3c3;
      border-radius: 32px;
      border: 1px none #474747;
    }
  }
  .react-pdf__Page > canvas {
    border-radius: 16px;
  }

  .scroll_to_viewer {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0px auto;
    padding: 10px 16px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px -4px 20px -8px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 16px 16px;
  }

  .document_content_body {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 900px;
    min-height: calc(100%);
    max-height: calc(100%);
    background-color: white;
    border-radius: 24px;
    margin-top: 40px;
    padding: 70px 72px;
  }
}

.rpv-search__highlight {
  background-color: #ffe6f2;
}
