.mobile_feed_sidebar {
  position: absolute;
  height: fit-content;
  top: 0;
  bottom: 0;
  right: 8px;
  margin-top: auto;
  margin-bottom: auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .item_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 56px;
    height: 56px;

    background: #ffffff30;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 56px;

    .icon_container {
      width: 30px;
      height: 30px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
