.mobile_create_poll {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f3f4f5;

  display: flex;
  flex-direction: column;

  .poll_header {
    padding: 16px;

    .poll_header_title {
      flex: 1;
      text-align: center;
      margin-right: 32px;
    }
  }

  .poll_body {
    height: calc(100% - 60px);
    background: #ffffff;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 20px 0px;

    display: flex;
    flex-direction: column;

    .poll_input {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 16px;
      padding: 0px 16px;

      .title_input {
        width: 100%;
        border: none;
        border-width: 0px;
        margin-top: 16px;
        background-color: transparent;
        text-align: center;
      }
      .title_input:focus {
        outline-width: 0px;
        background-color: transparent;
      }

      .option_list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 32px;

        .card_option {
          background: #ffffff;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 32px;

          padding: 6px 12px;

          .mark_view {
            width: 24px;
            height: 24px;

            background: #f3f4f5;
            border-radius: 32px;

            display: flex;
            align-items: center;
            justify-content: center;
          }

          .card_option_text {
            width: calc(100% - 50px);
            margin-left: 8px;
            margin-top: 4px;

            .option_title_input {
              width: 100%;
              border: none;
              border-width: 0px;
              overflow-y: hidden;
              resize: none;
            }
            .option_title_input:focus {
              outline-width: 0px;
              resize: none;
            }
          }
        }

        .add_option {
          width: fit-content;
          padding: 8px 12px;
          border: 1px dashed rgba(0, 0, 0, 0.2);
          border-radius: 32px;
          gap: 8px;

          .plus_btn {
            width: 24px;
            height: 24px;
            border-radius: 24px;
            background-color: #ff3e9a;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .poll_action {
      width: 100%;
      gap: 8px;
      padding: 0px 16px;

      .create_btn {
        flex: 1;
        height: 34px;
        border-radius: 34px;
        background-color: #ff3e9a;

        display: flex;
        align-items: center;
        justify-content: center;

        .create_btn_txt {
          color: white;
        }
      }

      .creator_image {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        object-fit: cover;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .selected_creator_image {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        object-fit: contain;

        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #ff3e9a;
      }
    }
  }
}
