@import 'shared/utils/colors.scss';

.mobile_waiting_list {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px 16px;
  overflow: hidden;

  .wait_home {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 46px;
    padding: 46px 0px;
  }

  .wait_txt {
    text-align: center;
    white-space: nowrap;
  }

  .register_input {
    width: 100%;
    margin-top: 12px;
    padding: 16px;
    border: 1px solid $color-border;
    border-radius: 16px;

    .your_email {
      color: rgba($color: $color-black, $alpha: 0.4);
    }
    .email_input {
      width: 100%;
      border: none;
      border-width: 0px;
    }
    .email_input:focus {
      outline-width: 0px;
    }
  }

  .home_back_btn {
    position: absolute;
    top: -50px;
    left: 0px;
  }
  .send_email_btn {
    width: 100%;
  }

  .wait_success {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 46px;
    padding: 46px 0px;

    .wait_tip {
      height: 64px;
      background: rgba(243, 244, 245, 0.5);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 24px;
      padding: 4px 16px;

      text-align: center;
    }
  }
}
