@import 'shared/utils/colors.scss';

.primary_btn_container {
  width: 100%;
  height: 45px;
  border-radius: 22.5px;
  background-color: $color-main;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.primary_btn_container_disabled {
  width: 100%;
  height: 45px;
  border-radius: 22.5px;
  background-color: $color-pink;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary_btn_container:hover {
  background-color: $color-main-hightlight;
}

.label {
  color: $color-white;
}
