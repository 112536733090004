.mobile_card_article {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 0px 24px;
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  position: relative;

  .user_avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .did_know_body {
    height: calc(100%);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .feed_main_content {
      max-height: 100%;
      overflow-y: auto;
      padding-bottom: 100px;
    }

    .did_know_image {
      width: 100%;
      aspect-ratio: 319 / 205;
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        #d9d9d9;
      border-radius: 16px;

      margin-bottom: 16px;
    }

    .article_title {
      font-weight: 700;
      font-family: sohne, 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 32px;
      letter-spacing: -0.014em;
      line-height: 38px;
    }

    .artile_txt {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.003em;

      margin-top: 20px;
      padding-bottom: 24px;
    }
  }

  .did_know_bottom {
    position: absolute;
    left: 8px;
    bottom: 0px;
    width: calc(100% - 16px);
    height: 32px;
    border-radius: 24px;
    background: linear-gradient(
      180deg,
      #ffffff 20%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);

    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .main_info_container {
    width: calc(100% - 50px);

    .right_container {
      width: calc(100% - 50px);
      margin-left: 8px;

      .article_title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .article_link {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        opacity: 0.6;
      }
    }
  }

  .article_divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 16px;
  }

  .top_shadow {
    position: absolute;
    left: 0px;
    top: 0px;
    width: calc(100% - 16px);
    height: 62px;
    background: linear-gradient(
      180deg,
      #ffffff 10%,
      rgba(255, 255, 255, 0) 70%
    );
    z-index: 1;
  }
}
