@import 'shared/utils/colors.scss';

.profile_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: 'hidden';

  .profile {
    width: 800px;
    margin: 0px auto 0px;
    overflow-y: auto;
    max-height: calc(100% - 100px);
    .user_info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 24px;
      margin-top: 32px;
      .camera_icon_tag {
        position: absolute;
        right: 0px;
        bottom: 4px;
        border-radius: 50%;
        background-color: #ff3e9a;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .camera_icon {
          width: 40px;
          height: 40px;
        }
      }
      .user_info_detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;

        .user_info_follow {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 21px;
          border-radius: 16px;

          .user_info_follower {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 4px;
          }

          .user_info_following {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 4px;
          }
        }

        .description {
          color: #000000;
          opacity: 0.4;
        }
      }
    }

    .user_evaluation {
      display: flex;
      justify-content: space-between;
      margin-top: 48px;
      padding: 0px 8px;

      .user_evaluation_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px 12px 12px;
        background: #ffffff;
        box-shadow: 0px 4px 12px 0px #00000026;
        border-radius: 16px;
        width: 24%;

        .user_evaluation_box_label {
          color: #000000;
          opacity: 0.6;
          backdrop-filter: blur(14px);
          margin-left: 8px;
        }

        .user_evaluation_box_number {
          color: #000000;
          opacity: 0.8;
        }
      }
    }

    .render_block {
      width: 100%;
      margin-top: 48px;

      .block_top {
        padding: 0px 16px;
      }

      .infinite-scroll-component {
        padding: 16px;
      }

      .join_new_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px 8px 8px;
        gap: 8px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        .plus_icon {
          color: $color-main;
        }
      }

      .fold_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 48px;
        height: 48px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        margin-left: 8px;
      }

      .edit_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px 8px 8px;
        gap: 8px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;
      }

      .content {
        display: flex;
        width: 100%;
      }
    }

    .user_name {
      margin-left: 8px;
    }

    .empty_user_activity_ai {
      padding: 24px 32px;
      background: #ffffff;
      box-shadow: 0px 4px 12px 0px #00000026;
      border-radius: 16px;

      margin: 0px 16px;
      margin-top: 16px;
    }

    .empty_status {
      padding: 24px 32px;
      background: #ffffff;
      box-shadow: 0px 4px 12px 0px #00000026;
      border-radius: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px 16px;
      margin-top: 16px;

      &_icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          180deg,
          rgba(255, 229, 242, 0.2) 0%,
          rgba(255, 229, 242, 0.9) 100%
        );
        border-radius: 8px;
      }

      &_info {
        flex: 1;
        margin-left: 24px;
      }
    }
  }

  .sub_input {
    width: 100%;
    flex: 1;
    border: none;
    border-width: 0px;
    padding: 4px 12px;
    border-radius: 12px;
  }

  .sub_input:focus {
    outline-width: 0px;
  }

  .edit_done_btn {
    width: 80px;
    height: 40px;
    background-color: #ff3e9a;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    color: white;
  }

  .follow_btn {
    width: 100px;
    height: 40px;
    background-color: #ff3e9a;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    color: white;
  }

  .unfollow_btn {
    width: 120px;
    height: 40px;
    border: 1px solid $color-border;
    background-color: white;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    color: black;
  }

  .requested_btn {
    width: 140px;
    height: 40px;
    background-color: $color-pink;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    color: black;
  }
}
