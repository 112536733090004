.card_all_shared_link {
  width: 100%;
  margin-bottom: 32px;

  .card_all_top_view {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 24px 24px 24px 0px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    position: relative;
    overflow: hidden;

    .top_image {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .mark_view {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }

  .link_txt {
    width: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 8px;
  }
}
