.mobile_user_bot_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-bottom: 32px;

  .modal_top_view {
    width: 100%;
    height: fit-content;

    position: relative;

    .bg_image {
      width: 100%;
      aspect-ratio: 1 / 0.8;
      object-fit: fill;
    }
  }

  .user_info {
    padding: 16px;

    .user_name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }

    .view_profile {
      background: linear-gradient(to right, #ff3e9a 0.59%, #d83eff 30%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .loading_container {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bot_list {
    width: 100%;

    .bot_content {
      width: 100%;
      max-height: 300px;
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 8px;
    }
  }

  .vibe_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .low_color {
      color: #f1483f;
    }

    .med_color {
      color: #ffb258;
    }

    .high_color {
      color: #08b883;
    }

    .low_bar_color {
      background-color: #f1483f;
    }

    .med_bar_color {
      background-color: #ffb258;
    }

    .high_bar_color {
      background-color: #08b883;
    }

    .progress_bar {
      width: 60px;
      height: 4px;
      border-radius: 4px;
      background-color: #f3f4f5;
      position: relative;

      .actived_progress {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 3px;
      }
    }
  }
}
