.mobile_card_header_chat_user::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 1px;
  background: linear-gradient(
    180.44deg,
    #ff3e9a 11.34%,
    rgba(216, 62, 255, 0) 60.64%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.mobile_card_header_chat_user {
  height: 56px;
  padding: 8px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;

  position: relative;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
  border-radius: 56px;
  margin-top: 16px;
  margin-right: 8px;
  margin-bottom: 32px;

  .item_image {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }

  .item_info {
    max-width: 120px;
    margin-left: 8px;
    display: flex;
    flex-direction: column;

    .item_name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .item_sub_info {
      white-space: nowrap;
      margin-top: 4px;
      color: rgba($color: #000000, $alpha: 0.4);
    }
  }
}
