.mobile_promot_conversation_bot {
  position: relative;
  padding-top: 16px;

  .content_list {
    width: 100%;
    overflow-x: auto;
    margin-top: 4px;
    padding-left: 16px;
    padding-right: 16px;

    gap: 8px;
  }

  .plus_btn {
    min-width: 50px;
    height: 64px;
    border-radius: 16px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mute_message {
    width: calc(100% - 32px);
    height: 32px;
    border-radius: 32px;
    background-color: #d6d6d6;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    position: absolute;
    bottom: -48px;
    left: 50%;
    transform: translateX(-50%);
  }
}
