.group_attached_file {
  width: 100%;
  overflow-x: auto;
  margin-top: 24px;
  padding-bottom: 24px;

  &_title {
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .top_tab {
    padding: 0px 8px;
  }

  &_body {
    width: 100%;
    height: 290px;
  }
  &_body_mobile {
    width: 100%;
    margin-top: 24px;
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
  }
  &_empty_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    gap: 12px;
    margin: 0px 32px;
    margin-top: 24px;
    height: 300px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }
  .attached_file_tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    .attached_file_tab_selected {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 32px;
      gap: 12px;
      background: #ffffff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 32px;
      cursor: pointer;
    }
    .attached_file_tab {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 24px;
      gap: 12px;
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
      border-radius: 32px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .react-horizontal-scrolling-menu--wrapper {
    height: 310px;
  }
  .react-horizontal-scrolling-menu--inner-wrapper {
    display: block;
    position: relative;
    .react-horizontal-scrolling-menu--arrow-left {
      display: block;
      position: relative;
      top: 53px;
      left: 32px;
      width: fit-content;
      z-index: 124;
      .interest_filter_action {
        width: fit-content;
        box-shadow: 0px 4px 20px rgba(67, 134, 178, 0.3);
        border-radius: 40px;
      }
    }
    .react-horizontal-scrolling-menu--arrow-right {
      display: block;
      position: relative;
      float: right;
      right: 32px;
      bottom: 235px;
      z-index: 124;

      .interest_filter_action {
        box-shadow: 0px 4px 20px rgba(67, 134, 178, 0.3);
        border-radius: 40px;
      }
    }
    .react-horizontal-scrolling-menu--scroll-container {
      top: -100px;
      padding: 8px 0px;
      padding-left: 32px;
    }
  }

  .attach_actions {
    gap: 8px;

    .action_item {
      width: 140px;
      height: 40px;
      background-color: white;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &_label {
        margin-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .group_attached_file_title {
      padding: 0px 16px;
      gap: 12px;
    }
  }

  .file_uploaded_label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3.2px 6.4px 3.2px 4.8px;
    gap: 2.4px;
    margin-right: 4px;
    background: #08b883;
    border-radius: 25.6px;
  }
  .audio_recorded_label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3.2px 6.4px 3.2px 4.8px;
    gap: 2.4px;
    margin-right: 4px;
    background: #9214f5;
    border-radius: 25.6px;
  }
  .written_text_label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 4px 6px;
    gap: 3px;
    width: fit-content;
    height: fit-content;
    background: #ff6651;
    border-radius: 32px;
  }
  .from_link_label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 4px 6px;
    gap: 3px;
    width: fit-content;
    background: #2f9bff;
    border-radius: 32px;
    height: fit-content;
  }
}

.drag_mask_view {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;
}
