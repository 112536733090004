.modal_custom_body {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba($color: #000000, $alpha: 0.1);
}
.modal_add_tag {
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_left {
      display: flex;
      align-items: center;
      gap: 22px;
    }
    &_right {
      color: #ff3e9a;
    }
  }

  &_body {
    margin-top: 24px;
    &_nav {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      justify-content: space-around;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &_item_selected {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 24px;
        gap: 8px;
        /* Main/Primary */

        border-bottom: 2px solid #ff3e9a;
      }
      &_item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 24px;
        gap: 8px;
        opacity: 0.4;
        cursor: pointer;
      }
    }
    &_new_tag_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
      width: 100%;
      margin-top: 24px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 40px;
      cursor: pointer;
    }
  }
}
