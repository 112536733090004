.events {
  height: 100%;
  padding: 46px 80px;

  display: flex;
  flex-direction: column;
  gap: 40px;

  .search_view {
    .filter_btn {
      width: 130px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      margin-left: 8px;
    }
  }

  &_body {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 20px;
  }
}
