.conversation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: 'hidden' !important;
  background-color: white;

  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-left: 24px;
    margin-right: 24px;

    &_left {
      display: flex;
      flex-direction: row;
      align-items: center;

      &_back_icon {
        padding-right: 10px;
      }

      &_title {
        margin-left: 16px;
      }
    }

    &_right {
      width: 175px;
      height: 53px;
      border-radius: 53px;
      border: 1px solid #ff3e9a;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .start_new {
        margin-left: 16px;
        color: #ff3e9a;
      }
    }

    .btn_find_creator {
      width: 175px;
      height: 53px;
      border-radius: 53px;
      border: 1px solid #ff3e9a;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-right: 16px;

      .btn_txt {
        color: #ff3e9a;
      }
    }
  }

  &_body {
    height: calc(100% - 150px);
    background-color: #f7fafc;
    margin-top: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    &_chat_history {
      width: 500px;
      height: 100%;
      overflow: hidden;
      border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
    }

    &_chat_body {
      width: calc(100% - 500px);
      height: 100%;
      overflow: hidden;
      margin-left: 32px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}
