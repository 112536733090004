.mobile_multichat_action_modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 8px;
  padding: 8px 16px;
  padding-bottom: 16px;

  .header_view {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .action_container {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;

    .action_item {
      width: 100%;
      height: 50px;
      border-radius: 24px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);

      padding: 0px 20px;
      gap: 12px;
    }
  }
}
