.main_card_flip_answer {
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 16px;

  .mark {
    background-color: transparent;
    .light_view {
      width: 32px;
      height: 32px;
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flip_answer_body {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;

    .answer_main_body {
      max-height: 100%;
      overflow-y: auto;
      padding-bottom: 50px;
    }

    .question_txt {
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
  .flip_answer_bottom {
    display: flex;
    align-items: end;
    justify-content: center;
    padding-bottom: 16px;

    background: linear-gradient(0deg, #ffffff 60%, rgba(255, 255, 255, 0) 100%);

    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100px;

    .flip_btn {
      width: 65px;
      height: 36px;

      color: rgba(0, 0, 0, 0.6);
      background: #ffffff;
      /* Main/Primary */

      border: 1px solid rgba(0, 0, 0, 0.6);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
