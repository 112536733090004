.mobile_create_bot {
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: #f3f4f5;

  display: flex;
  flex-direction: column;

  .bot_bg_container {
    position: absolute;
    width: 100%;
    height: 100%;

    .bot_bg_image {
      width: 100%;
      height: 100%;
    }
  }

  .bot_create_body {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    .bot_header {
      padding: 32px 16px;
      margin-right: 24px;

      .bot_name {
        flex: 1;
        text-align: center;
      }
    }
  }
}
