.main_card_flash {
  width: 400px;
  height: 380px;

  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.05);
  border-radius: 18px;

  margin: 8px;

  .ref_container {
    width: 100%;
    background: rgba(243, 244, 245, 0.5);
    border-radius: 40px;

    padding: 8px 12px;
  }

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flip_action_conatiner {
    justify-content: center;
  }

  .right_action_view {
    position: absolute;
    height: fit-content;
    top: 0;
    bottom: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    background-color: white;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
    padding: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    .action_item {
      margin: 4px 0px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .vote_divider {
      margin-top: 16px;
      height: 1px;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }

  .react-card-flip {
    height: 100%;
  }
}
