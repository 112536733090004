.modal_mobile_bot_common_interest {
  width: 100%;
  padding: 20px 0px;

  .topic_title {
    margin-left: 16px;
  }

  .main_topic_list {
    width: 100%;
    display: flex;
    position: relative;

    .selected_main_topic {
      margin-left: 16px;
      margin-right: 8px;
    }

    .normal_main_topics {
      flex: 1;
      overflow-x: auto;
      display: flex;
      gap: 16px;
      padding-left: 8px;
      padding-right: 16px;
    }
  }

  .sub_users {
    width: 100%;
    overflow-x: auto;
    gap: 16px;
    padding: 0px 16px;
    margin: 24px 0px;

    .sub_user_image {
      width: 64px;
      height: 64px;
      border-radius: 64px;
      object-fit: cover;
    }
  }
}
