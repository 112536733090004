.mobile_modal_post_question {
  padding: 16px;

  .header_view {
    margin-top: 16px;

    .question_title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
    }
  }

  .mobile_modal_post_question_body {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .top_action_view {
      gap: 16px;

      align-items: center;
      justify-content: center;

      .default_btn {
        padding: 8px;
        gap: 8px;
        border-radius: 24px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }

      .selected_btn {
        padding: 8px;
        gap: 8px;
        border-radius: 24px;
        border: 1px solid #ff3e9a;
      }

      .user_img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        object-fit: contain;
      }
    }

    .textarea_question {
      border: none;
    }
    .textarea_question:focus {
      outline: none;
    }

    .add_answer_bottom_bar {
      padding-top: 16px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
