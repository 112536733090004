.mobile_join_org {
  gap: 10px;

  .react-horizontal-scrolling-menu--scroll-container  {
    padding-left: 16px;
  }

  .react-horizontal-scrolling-menu--item  {
    padding: 16px 0px;
  }

  .react-horizontal-scrolling-menu--scroll-container  {
    margin-top: -8px;
  }
}
