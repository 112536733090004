.card_chat {
  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;

    &_image {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      // background-color: rgba($color: #000000, $alpha: 0.05);

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .creator_image {
      width: 24px;
      height: 24px;
      border-radius: 24px;
    }

    &_name {
      margin-left: 8px;
    }
  }

  &_message {
    margin-top: 8px;
    border-radius: 0px 16px 16px 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 12px;
    width: fit-content;
    max-width: 50%;
  }

  &_own_message {
    margin-top: 8px;
    border-radius: 16px 0px 16px 16px;
    padding: 12px;
    width: fit-content;
    background-color: #f3f4f5;
    max-width: 50%;
  }
}
.send_to_org {
  color: #FF3E9A;
}