.card_profile_org {
  min-width: 230px;
  height: 72px;
  border-radius: 16px;
  background-color: white;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  margin-right: 16px;
  box-shadow: 0px 4px 12px 0px #00000026;

  .org_image {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #d9d9d9;
  }

  .org_info {
    width: calc(100% - 56px);
    margin-left: 8px;

    .org_name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .org_followers {
      display: flex;
      margin-top: 3px;

      .org_follower_txt {
        margin-left: 4px;
        color: rgba($color: #000000, $alpha: 0.8);
      }
    }
  }
}
