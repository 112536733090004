.mobile_card_bookmark {
  height: 100%;

  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding-top: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;
  overflow: hidden;

  .card_container {
    width: 100%;
    flex: 1;
    margin-bottom: 8px;
    padding: 0px 24px;

    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile_question_detail_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    .answer_list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      padding-bottom: 100px;

      .other_answer_title {
        margin-top: 16px;
      }
    }

    .bookmark_question_title {
      font-size: 36px;
      line-height: 45px;
      text-align: center;
    }

    .sub_item {
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      padding: 12px 16px;
      display: flex;
      flex-direction: row;
      gap: 6px;
      margin-left: 6px;
    }

    .detail_btn {
      width: 32px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      margin-left: 6px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .vertical_divider {
      width: 1px;
      height: 24px;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .normal_question_image {
      width: 100%;
      aspect-ratio: 319 / 205;
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        #d9d9d9;
      border-radius: 16px;
      padding: 16px;

      position: relative;

      .user_image {
        width: 32px;
        height: 32px;

        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        object-fit: cover;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .user_plus {
        width: 32px;
        height: 32px;

        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        margin-left: 6px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .user_point {
        width: 80px;
        height: 32px;

        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        gap: 4px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .top_answer_txt {
      font-size: 22px;
    }
  }

  .did_know_bottom {
    position: absolute;
    left: 8px;
    bottom: 0px;
    width: calc(100% - 16px);
    height: 62px;
    border-radius: 24px;
    background: linear-gradient(
      180deg,
      #ffffff 20%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);

    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .top_shadow {
    position: absolute;
    left: 0px;
    top: 0px;
    width: calc(100% - 16px);
    height: 62px;
    border-radius: 24px;
    background: linear-gradient(
      180deg,
      #ffffff 30%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
