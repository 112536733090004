.add_answer_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  border-radius: 24px;
  margin-top: 64px;
  margin-bottom: 64px;

  .answer_text_area {
    width: 100%;
    height: 190px;
    border: none;
    border-width: 0px;
    margin-bottom: 32px;
  }
  .answer_text_area:focus {
    outline: none;
  }
  .select_templates {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 54px;
    margin-bottom: 32px;
    .template_sentence_bar {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px;
      gap: 12px;
      height: 54px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 40px;
      .template_sentence {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
      }
      .use_btn {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        color: #ff3e9a;
      }
    }
  }
  .add_answer_detail_box {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-top: 32px;
    padding: 24px;
    margin-bottom: 32px;
    .add_answer_bottom_bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      gap: 8px;
      height: 48px;
      .add_answer_bottom_bar_icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 16px;
        height: 44px;
        .icon_circle {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12px;
          gap: 8px;
          width: 44px;
          height: 44px;
          /* Line/Default */
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 32px;
        }
        .upload_file_btn {
          height: 48px;
          border-radius: 48px;
          display: flex;
          align-items: center;
          padding-left: 12px;
          padding-right: 12px;
          border: 1px solid rgba(0, 0, 0, 0.1);

          .upload_file_btn_txt {
            margin-left: 8px;
          }
        }
      }
      .add_answer_bottom_bar_btns {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 7px;
        height: 48px;
        .add_answer_bottom_bar_referneces {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;
          height: 48px;
          .user_star_btn {
            height: 48px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;
            gap: 8px;
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 32px;
            margin-left: 8px;
            cursor: pointer;
            .user_avatar {
              width: 24px;
              height: 24px;
              border-radius: 30px;
            }
          }
          .user_star_btn_selected {
            height: 48px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;
            gap: 8px;
            background: #ffffff;
            border-radius: 32px;
            margin-left: 8px;
            border: 1px solid #ff3e9a;
            cursor: pointer;
            .user_avatar {
              width: 24px;
              height: 24px;
              border-radius: 30px;
            }
          }
        }
        .add_answer_btn {
          display: flex;
          width: 120px;
          height: 48px !important;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 12px 16px 12px 12px;
          gap: 8px;
          background: #ff3e9a;
          border: 1px solid #ff3e9a;
          border-radius: 32px;
          color: #ffffff;
          margin-left: 12px;
        }
      }
    }
  }
}
