@import '~bootstrap/scss/bootstrap.scss';

@mixin fontFace($family, $src, $style: normal, $weight: 400) {
  @font-face {
    font-family: $family;
    src: url('#{$src}.ttf') format('truetype');
    // src: url('#{$src}.eot'); // IE9 compat
    // src: url('#{$src}.eot?#iefix') format('embedded-opentype'), // IE8 and below
    // 	url('#{$src}.woff') format('woff'), // standards
    // 	url('#{$src}.ttf') format('truetype'), // Safari, Android, iOS
    // 	url('#{$src}.svg##{$family}') format('svg'); // legacy iOS

    font-style: $style;
    font-weight: $weight;
  }
}

@include fontFace(
  'Popin-Bold',
  './assets/fonts/Popin/Poppins-SemiBold',
  normal,
  600
);

@include fontFace(
  'Popin-Medium',
  './assets/fonts/Popin/Poppins-Medium',
  normal,
  500
);

@include fontFace(
  'Popin-Regular',
  './assets/fonts/Popin/Poppins-Regular',
  normal,
  400
);

@include fontFace(
  'Agharti-Bold',
  './assets/fonts/Agharti/Agharti-condensedblack',
  normal,
  600
);

@include fontFace(
  'SerifPro-Regular',
  './assets/fonts/SerifPro/SourceSerifPro-Regular',
  normal,
  400
);

body {
  background-color: #f7fafc;
  zoom: 80%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
img {
  object-fit: cover;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba($color: #000000, $alpha: 0.2);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba($color: #000000, $alpha: 0.2);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba($color: #000000, $alpha: 0.2);
}

.light::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba($color: #ffffff, $alpha: 0.2);
  opacity: 1; /* Firefox */
}

.light:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba($color: #ffffff, $alpha: 0.2);
}

.light::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba($color: #ffffff, $alpha: 0.2);
}

::-moz-selection {
  /* Code for Firefox */
  background: #ffe6f2;
}

::selection {
  background: #ffe6f2;
}

.font-bold {
  font-family: 'Popin-Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Popin-Bold', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}
.font-medium {
  font-family: 'Popin-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Popin-Medium', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}
.font-regular {
  font-family: 'Popin-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Popin-Regular', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

.app-font-bold {
  font-family: 'Agharti-Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Agharti-Bold', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

.article-font-regular {
  font-family: 'SerifPro-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'SerifPro-Regular', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.clickable {
  cursor: pointer;
}

.align-center {
  display: flex;
  justify-content: center;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.align-vertical-center {
  display: flex;
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.font8 {
  font-size: 8px;
}

.font10 {
  font-size: 10px;
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}
.font22 {
  font-size: 22px;
}

.font23 {
  font-size: 23px;
}
.font24 {
  font-size: 24px;
}
.font26 {
  font-size: 26px;
}
.font28 {
  font-size: 28px;
}
.font30 {
  font-size: 30px;
}
.font32 {
  font-size: 32px;
}

.font36 {
  font-size: 36px;
}

.font40 {
  font-size: 40px;
}
.font42 {
  font-size: 42px;
}
.font44 {
  font-size: 44px;
}
.font56 {
  font-size: 56px;
}

.font72 {
  font-size: 72px;
}

.text_no_wrap {
  white-space: nowrap;
}

.row_space_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.row_align_items {
  display: flex;
  align-items: center;
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
  font-family: 'Popin-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Popin-Regular', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  margin-bottom: 0px !important;
}

.rotate {
  animation: rotation infinite 5s linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.border_less_input {
  border: none;
}
.border_less_input:focus {
  outline: none;
}

::-webkit-scrollbar {
  display: none;
}
// ::-webkit-scrollbar {
//   width: 20px;
// }
// ::-webkit-scrollbar-track {
//   background-color: transparent;
// }
// ::-webkit-scrollbar-thumb {
//   background-color: #d6dee1;
//   border-radius: 20px;
//   border: 6px solid transparent;
//   background-clip: content-box;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background-color: #a8bbbf;
// }

.reference_name {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  max-width: 100px;
  margin-left: 8px;
}

.pac-container {
  z-index: 999999 !important;
}

.pac-target-input {
  border: none;
  border-width: 0px;
  width: 100%;
  background-color: transparent;
}

.pac-target-input:focus {
  outline-width: 0px;
  background-color: transparent;
}

.pac-logo::after {
  display: none;
}

.active_nav {
  visibility: visible !important;
}

.hidden_nav {
  visibility: hidden !important;
  transition: all 0.2s !important;
  transform: translateY(100%) !important;
}

.normal_mobile_body {
  height: calc(100% - 80px) !important;
}

.expanded_mobile_body {
  height: calc(100%) !important;
}
