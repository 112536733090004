.mobile_org_joined {
  margin-top: 0px;

  .mobile_joined_org_body {
    margin-top: 16px;
  }


  .mobile_joined_empty_body {
    margin-top: 60px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .react-horizontal-scrolling-menu--scroll-container  {
    padding-left: 16px;
  }
  
}
