.mobile_card_personal_ai_file {
  padding: 16px;
  background: #ffffff;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;

  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  gap: 16px;

  .user_image {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .trash_btn {
    width: 36px;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 36px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file_title {
    width: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
