.mobile_chat_add_bot_modal {
  height: 100%;
  overflow: hidden;
}

.bot_modal_body {
  height: 100% !important;
  border-radius: 0px;
}

.custom_add_bot_modal {
  z-index: 1000 !important;
}
