.org_detail {
  height: 100%;
  padding: 46px 80px;

  .selected_org_top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    gap: 32px;
    margin-bottom: 32px;

    &_info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
      gap: 32px;
      align-items: center;
      &_text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        // gap: 8px;
      }
      &_img {
        width: 84px;
        height: 84px;
        border-radius: 50%;
      }
    }
    &_members {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 24px;
      gap: 16px;
      height: 60px;
      background: #ffffff;
      justify-content: center;
      /* Line/Default */
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 56px;
    }
  }

  .selected_unanswer_btn {
    min-width: 60px;
    height: 60px;

    /* Main/Secondary */

    background: #ffe6f2;
    /* Main/Primary */

    border: 1px solid #ff3e9a;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 28px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  .default_unanswer_btn {
    min-width: 60px;
    height: 60px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 28px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  .filter_btn {
    height: 60px;
    padding: 0px 24px;

    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 28px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  .org_reference {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 36px;

    &_left {
      flex: 1;
      height: 60px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px #00000026;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding: 0px 20px;
    }

    &_right {
      width: 300px;
      height: 60px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px #00000026;
      border-radius: 16px;
      margin-left: 12px;
      display: flex;
      align-items: center;
      padding: 0px 20px;
    }

    .org_answer_reference_list {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: auto;
      margin-left: 8px;
    }
  }

  .not_belong {
    height: calc(100% - 200px);
    background: #ffffff;
    margin: 32px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .request_join {
      width: 200px;
      height: 45px;
      border-radius: 45px;
      background-color: #ff3e9a;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;

      .request_txt {
        color: white;
        margin-left: 12px;
      }
    }
  }
}
