.org_member {
  height: 100%;
  padding: 46px 80px;

  .pending_view {
    padding: 8px 24px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 24px;

    .pending_mark {
      width: 24px;
      height: 24px;
      color: white;
      background-color: #ff3e9a;
      border-radius: 30px;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
    }
  }
}
