.mobile_chat_top_part {
  .chat_tab {
    margin-top: 12px;
    padding: 0px 16px;

    .default_btn {
      padding: 12px 16px;
      height: 24px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($color: #000000, $alpha: 0.3);
    }

    .selected_btn {
      padding: 12px 16px;
      height: 24px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background-color: #ff3e9a;
    }
  }

  .mobile_chat_header_body {
    width: 100%;
    overflow-x: auto;
    padding-left: 16px;
    padding-right: 16px;

    position: relative;
  }

  .ai_avatar {
    width: 40px;
    height: 40px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
