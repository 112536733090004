@import 'shared/utils/colors.scss';

.dlg_list_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 0px;
  border-bottom: 1.15031px solid rgba(0, 0, 0, 0.1);

  .add_btn {
    background: $color-main;
    border-radius: 28px;
    color: white;
    width: 28px;
    height: 28px;
    text-align: center;
  }

  .ref_name {
    text-overflow: ellipsis;
    // overflow: hidden;
    // display: -webkit-box !important;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    // white-space: nowrap;
  }
}

.added_item {
  background: rgba(255, 230, 242, 0.4);

  .check_icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4.66666px;
    gap: 9.33px;
    width: 28px;
    height: 28px;
    border-radius: 18.6666px;
  }
}
