.all_shared_flash_container {
  width: 100%;
  padding: 40px;

  .ref_container {
    width: 100%;
    background: rgba(243, 244, 245, 0.5);
    border-radius: 40px;

    padding: 8px 12px;
  }

  .flip_action_conatiner {
    justify-content: center;
  }

  .shared_link {
    width: 100%;
    padding: 16px 32px;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    margin-bottom: 32px;

    display: flex;
    align-items: center;
    overflow-x: auto;

    .intro_view {
      min-width: 220px;
      max-width: 220px;
      margin-top: 28px;

      .mark_view {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background-color: #ff6058;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .react-horizontal-scrolling-menu--wrapper {
    width: 100%;
    height: 350px;
  }

  .react-horizontal-scrolling-menu--inner-wrapper {
    display: block;
    position: relative;
    .react-horizontal-scrolling-menu--arrow-left {
      display: block;
      position: relative;
      top: 70px;
      width: fit-content;
      z-index: 124;
      .interest_filter_action {
        width: fit-content;
        box-shadow: 0px 4px 20px rgba(67, 134, 178, 0.3);
        border-radius: 40px;
      }
    }
    .react-horizontal-scrolling-menu--arrow-right {
      display: block;
      position: relative;
      float: right;
      bottom: 283px;
      z-index: 124;
      .interest_filter_action {
        box-shadow: 0px 4px 20px rgba(67, 134, 178, 0.3);
        border-radius: 40px;
      }
    }
    .react-horizontal-scrolling-menu--scroll-container {
      top: -100px;
    }
  }
}
