.card_preview {
  width: 100%;
  background-color: white;
  border-radius: 32px;
  padding: 32px;
  padding-left: 0px;
  margin-top: 24px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);

  .bot_mark {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 500;

    .robot_icon {
      position: absolute;
      top: -10px;
      left: 5px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .share_view {
    width: calc(100% - 24px);
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-left: 32px;

    .link_view {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background-color: rgba($color: #000000, $alpha: 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .link_url {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgba($color: #000000, $alpha: 0.5);
      margin-left: 6px;
    }

    .link_share_btn {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background-color: #ff3e9a;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .remove_btn {
      width: 32px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .title {
    width: calc(100%);
    overflow: hidden;
    white-space: nowrap;

    margin-left: 32px;
  }

  .image_view {
    height: 300px;
    border-radius: 48px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    margin-top: 24px;
    margin-left: 32px;

    img {
      border-radius: 48px;
      height: 300px;
      width: 100%;
    }
  }

  .setting_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;

    .react-horizontal-scrolling-menu--inner-wrapper {
      width: calc(100% + 30px);
    }

    .react-horizontal-scrolling-menu--scroll-container {
      padding-left: 32px;
    }

    &_item {
      padding: 10px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }

    &_divider {
      min-width: 1px;
      height: 30px;
      background-color: rgba($color: #000000, $alpha: 0.1);
      margin-right: 8px;
    }
  }

  .prev_tag_view {
    margin-top: 16px;
    padding-left: 32px;
    max-width: calc(100% - 32px);

    .react-horizontal-scrolling-menu--wrapper {
      width: calc(100% - 36px);
    }

    .react-horizontal-scrolling-menu--scroll-container {
      padding-right: 100px;
    }
  }

  .edit_tag_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }

  .add_tab_btn {
    min-width: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px 6px 12px;
    gap: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    justify-content: center;
  }

  .privacy_btn {
    height: 40px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    gap: 8px;
    padding: 0px 8px;
    margin-right: 8px;
  }
}
