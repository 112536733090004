@import 'shared/utils/colors.scss';

.my_question {
  padding-top: 32px;

  .question_create {
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    padding: 32px;

    .question_box_input {
      width: 100%;
      border: none;
      border-width: 0px;
      margin-top: 32px;
    }

    .question_box_input:focus {
      outline-width: 0px;
    }

    .create_bottom {
      display: flex;
      align-items: center;
      margin-top: 32px;

      .post_question_btn {
        width: 160px;
        height: 44px;
        border-radius: 44px;
        background-color: $color-main;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .post_question_char {
        flex: 1;
        margin-left: 32px;
        color: rgba(0, 0, 0, 0.4);
      }

      .clear_btn {
        width: 100px;
        height: 44px;
        border-radius: 44px;
        border: 1px solid $color-border;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .question_tab {
    display: flex;
    margin-top: 40px;
  }

  &_answers {
    margin-top: 24px;
  }
}
