.org_home {
  .org_home_body {
    padding: 46px 80px;

    .org_reference {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 36px;

      &_left {
        flex: 1;
        height: 60px;
        background-color: white;
        box-shadow: 0px 4px 12px 0px #00000026;
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding: 0px 20px;
      }

      &_right {
        width: 300px;
        height: 60px;
        background-color: white;
        box-shadow: 0px 4px 12px 0px #00000026;
        border-radius: 16px;
        margin-left: 12px;
        display: flex;
        align-items: center;
        padding: 0px 20px;
      }

      .org_answer_reference_list {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: auto;
        margin-left: 8px;
      }
    }

    .selected_unanswer_btn {
      min-width: 60px;
      height: 60px;

      /* Main/Secondary */

      background: #ffe6f2;
      /* Main/Primary */

      border: 1px solid #ff3e9a;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 28px;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
    }

    .default_unanswer_btn {
      min-width: 60px;
      height: 60px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 28px;

      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
    }

    .filter_btn {
      height: 60px;
      padding: 0px 24px;

      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 28px;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
    }
  }
}
