.mobile_explore_people {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  gap: 16px;

  .expolore_top_view {
    .top_left_view {
      margin-left: 16px;
    }

    .top_right_view {
      width: 48px;
      height: 48px;

      background: #f8f9f9;
      border-radius: 48px;
      margin-right: 16px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .exlore_body {
    width: 100%;
    padding: 0px 16px;
    overflow-x: auto;

    display: flex;
    align-items: center;
    gap: 28px;
  }

  .empty_body {
    padding: 0px 16px;
    gap: 16px; // text-align: center;

    .share_text {
      color: #ff3e9a;
    }
  }
}
