.mobile_event_hosted {
  margin: 16px 0px;

  &_body {
    width: 100%;
    height: 100px;

    .create_btn {
      width: 130px;
      height: 90px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      margin-right: 8px;

      &_txt {
        color: rgba(0, 0, 0, 0.6);
        margin-top: 6px;
      }
    }
  }

  .react-horizontal-scrolling-menu--scroll-container {
    margin-right: -30px;
    padding-top: 4px;
    padding-bottom: 8px;
    padding-left: 16px;

    .react-horizontal-scrolling-menu--item {
      margin: 8px 0px;
    }
  }

  .event_empty_view {
    height: 120px;
    margin-left: 16px;
    margin-top: 16px;
    width: calc(100% - 32px);

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    padding: 0px 16px;
  }

  .create_event_btn_txt {
    color: #ff3e9a;
  }

  .create_event_btn {
    min-width: 60px;
    height: 30px;
    border-radius: 30px;
    color: white;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
