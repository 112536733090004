@import 'shared/utils/colors.scss';

.document_ask_question {
  margin-top: 56px;
  background-color: white;
  padding: 32px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .post_question_btn {
    width: 180px;
    height: 44px;
    border-radius: 44px;
    background-color: $color-main;
    display: flex;
    align-items: center;
    justify-content: center;

    &_find_btn {
      display: flex;
      align-items: center;
    }
  }

  &_body {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    .question_box_input {
      flex: 1;
      border: none;
      border-width: 0px;
    }

    .question_box_input:focus {
      outline-width: 0px;
    }
  }

  .answers {
    overflow-y: auto;
    margin-top: 32px;
    padding-right: 16px;
  }

  .reference_box {
    width: 100%;
    padding: 24px 24px 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px 20px 20px 20px;
    margin-top: 16px;

    .reference_text {
      color: #000000;
      opacity: 0.8;
      margin-top: 24px;
      margin-bottom: 8px;
    }

    .reference_user {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;

      .on_label {
        color: #000000;
        opacity: 0.4;
      }
    }
  }

  .post_question {
    margin-top: 24px;

    .creator_image {
      width: 24px;
      height: 24px;
      border-radius: 24px;
    }
    .user_name {
      margin-left: 8px;
      margin-right: 8px;
    }
    .selected_btn {
      height: 45px;
      border-radius: 45px;
      padding: 0px 16px;
      display: flex;
      align-items: center;
      border: 1px solid #ff3e9a;
    }

    .default_btn {
      height: 45px;
      border-radius: 45px;
      padding: 0px 16px;
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .post_btn {
      width: 180px;
      height: 45px;
      border-radius: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .add_regenerate_btn {
    width: 162px;
    height: 48px;
    border-radius: 32px;
    border: 1px solid $color-border;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  .edit_answer_btn {
    width: 163px;
    height: 48px;
    border-radius: 32px;
    border: 1px solid $color-border;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  .book_mark_btn {
    width: 160px;
    height: 48px;
    border-radius: 32px;
    background-color: $color-main;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .un_book_mark_btn {
    width: 193px;
    height: 48px;
    border-radius: 32px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit_answer {
    margin-top: 24px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    padding: 24px;

    .answer_box_input {
      margin-top: 32px;
      margin-bottom: 32px;
      flex: 1;
      border: none;
      border-width: 0px;
    }

    .answer_box_input:focus {
      outline-width: 0px;
    }

    .cancel_btn {
      width: 100px;
      height: 44px;
      border-radius: 44px;
      border: 1px solid $color-border;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .save_answer_btn {
      width: 144px;
      height: 48px;
      border-radius: 32px;
      background-color: $color-main;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
