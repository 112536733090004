.mobile_group_members {
  margin-top: 32px;
  padding: 0px 16px;

  .search_view {
    margin-top: 12px;
  }

  &_body {
    margin-top: 12px;
  }
}
