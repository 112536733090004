.card_shared_link {
  min-width: 200px;
  max-width: 200px;
  margin-left: 16px;

  .top_view {
    height: 173px;
    border-radius: 24px 24px 24px 0px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    position: relative;
    overflow: hidden;
    padding: 0px !important;

    .top_image {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .mark_view {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }

  .link_title {
    width: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .link_txt {
    width: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 8px;
    margin: 6px 0px;
  }
}
