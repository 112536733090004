.join_request_sent_modal {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .check_btn {
    width: 100%;
    height: 45px;
    border-radius: 45px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    margin-top: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
