@import 'shared/utils/colors.scss';

.modal_create_group {
  margin-top: 16px;

  .modal_create_group_header {
    display: flex;
    align-items: center;

    .modal_back_btn {
      cursor: pointer;
    }

    .title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
    }
  }

  .modal_create_group_body {
    margin-top: 40px;

    .sub_title {
      font-size: 10px;
      color: rgba($color: #000000, $alpha: 0.4);
    }

    .sub_input {
      width: 100%;
      border: none;
      border-width: 0px;
      margin-top: 8px;
    }

    .sub_input:focus {
      outline-width: 0px;
    }

    .group_name {
      border: 1px solid $color-border;
      border-radius: 16px;
      padding: 16px;
    }

    .group_desc {
      border: 1px solid $color-border;
      border-radius: 16px;
      padding: 16px;
      margin-top: 16px;
    }

    .btn_create {
      margin-top: 56px;
    }
  }
}

.interest_filter_row {
  margin-top: 32px;

  .interest_filter_action {
    display: flex;
    align-items: center;
  }

  .interest_filter_item_default {
    padding: 16px;
  }

  .interest_filter_item_selected {
    padding: 16px;
    background-color: $color-pink;
    border: 1px solid #ff3e9a !important;
    border-radius: 100px;
  }
}
