@import 'shared/utils/colors.scss';

.card_org_member {
  width: 100%;
  aspect-ratio: 1/1;

  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  gap: 16px;
  margin-bottom: 16px;

  .user_image {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    background-color: rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }

  .user_info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .chat_btn {
    width: 40px;
    height: 40px;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .follow_btn {
    width: 180px;
    height: 40px;

    background: #ff3e9a;
    border-radius: 32px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  .following_btn {
    width: 180px;
    height: 40px;

    /* Line/Default */

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  .requested_btn {
    width: 180px;
    height: 40px;

    background-color: $color-pink;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    color: black;
  }
}
