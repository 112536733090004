.link_preview {
  padding: 32px 0px;
  background-color: #f3f4f5;
  border-radius: 40px;
  margin-top: 40px;
  margin-left: 32px;
  margin-right: 32px;
  position: relative;

  .received_btn {
    width: 48px;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 80px;
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .selected_received_btn {
    width: 48px;
    height: 48px;
    background: #ffe6f2;
    border: 1px solid #ff3e9a;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 80px;
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .received_count_not {
    position: absolute;
    width: 24px;
    height: 24px;
    right: -6px;
    top: -4px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    background: #ff3e9a;
    border: 1px solid #ffffff;
    border-radius: 32px;
  }

  &_tag {
    margin-top: 40px;
  }

  .empty_container {
    padding: 0px 32px;
    .empty_body {
      height: 300px;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .bottom_container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100%);
    height: 150px;
    background: linear-gradient(
      180deg,
      #f7f8f9 34.56%,
      rgba(247, 248, 249, 0) 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;

    .load_more_btn {
      width: 340px;
      height: 60px;
      background: #ffffff;
      box-shadow: 0px 28px 24px -16px rgba(0, 0, 0, 0.1);
      border-radius: 56px;
      transform: matrix(1, 0, 0, -1, 0, 0);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
