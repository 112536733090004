.mobile_similar_question {
  margin-top: 44px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  .mobile_question_box_not_found {
    margin: 0px 16px;
    padding: 24px 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #ffffff;
    border-radius: 24px;

    .speech_balloon {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      display: flex;
      align-items: center;

      color: #000000;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .not_found_title {
      margin-top: 16px;
    }
    .not_found_description {
      margin-top: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      width: 23%;
      color: #000000;
      margin-bottom: 24px;
    }
  }
}
