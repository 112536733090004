.record_modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .refresh_btn {
    width: 48px;
    height: 48px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .record_btn {
    width: 72px;
    height: 72px;
    border-radius: 72px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .done_btn {
    width: 48px;
    height: 48px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 48px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
