.add_answer_right_panel_tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 32px;
    .add_answer_right_panel_tab_item {
        color: #000000;
        opacity: 0.3;
    }
    .add_answer_right_panel_tab_item_selected {
        color: #000000;
    }
}