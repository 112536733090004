.org_asked {
  overflow-y: auto;

  .org_navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 16px;

    .org_navigation_item_selected {
      color: #000000;
    }

    .org_navigation_item {
      color: #000000;
      opacity: 0.3;
    }
  }
}
