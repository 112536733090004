.mobile_promot_card_bot {
  height: 64px;
  background: linear-gradient(180deg, #ffeff7 0%, #ffdaec 100%);

  border-radius: 16px;
  padding: 8px;

  .bot_image {
    min-width: 45px;
    height: 45px;
    border-radius: 45px;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }

  .personal_ai_txt {
    background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    white-space: nowrap;
  }

  .bot_body {
    width: calc(100% - 130px);
    margin-left: 8px;

    .personal_ai_name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .bot_action_btn {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
