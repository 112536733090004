.profile_social {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  margin: 0px 16px;
  box-shadow: 0px 4px 12px 0px #00000026;

  &_body {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    .social_item {
      background-color: white;
      border-radius: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 16px;
    }

    .social_item_name {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 130px;
      white-space: nowrap;
      display: inline-block;
    }

    .social_item_icon {
      margin-right: 16px;
    }

    .social_item_divider {
      height: 32px;
      width: 1px;
      background-color: rgba($color: #000000, $alpha: 0.1);
      margin-right: 16px;
    }
  }

  &_connect {
    color: #ff3e9a;
  }
}
