.question_detail_right_panel {
    padding: 45px 80px;
    .detail_right_panel_tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 32px;
        margin-bottom: 32px;
        .detail_right_panel_tab_item {
            color: #000000;
            opacity: 0.3;
        }
        .detail_right_panel_tab_item_selected {
            color: #000000;
        }
    }
    .show_more_answer_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 40px;
        gap: 4px;
        height: 46px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
    }
    .add_answer_bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 36px 0px;
        gap: 24px;
        height: 22px;
        width: 100%;
        .left_side {
            display: flex;
            justify-content: space-between;
            .question_info {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 16px;
                height: 22px;
                .snack {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 8px;
                    height: 22px;
                }
            }
            .question_points {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 2px;
                height: 20px;
                margin-left: 36px;
                border-radius: 32px;
            }
        }
        .right_side {
            display: flex;
            justify-content: space-between;
            .question_date {
                display: flex;
                align-items: center;
                text-align: right;
                margin-right: 36px;
                color: #000000;
            }
            .add_answer_btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 16px;
                gap: 8px;
                height: 41px;
                background: #FF3E9A;
                border: 1px solid #FF3E9A;
                border-radius: 32px;
                color: #FFFFFF;
            }
        }
    }
    .line {
        opacity: 0.1;
        border: 1px solid #000000;
        width: 100%;
        margin: 24px 0px;
    }
}