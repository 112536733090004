@import 'shared/utils/colors.scss';

.date_bookmark {
  display: flex;
}
.question_bookmark {
  margin-top: 32px;
  width: 100%;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 4px 12px 0px #00000026;
  padding: 24px;
  display: flex;
  flex-direction: column;
  .reference_box {
    width: 100%;
    padding: 24px 24px 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px 20px 20px 20px;
    margin-top: 16px;

    .reference_text {
      color: #000000;
      opacity: 0.8;
      margin-top: 24px;
      margin-bottom: 8px;
    }

    .reference_user {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;

      .on_label {
        color: #000000;
        opacity: 0.4;
      }
    }
  }
  .question_bookmark_top {
    display: flex;
    justify-content: space-between;

    .book_marked_icon {
      width: 48px;
      height: 48px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_title {
      max-height: 80px;
      overflow-y: auto;
      margin-right: 16px;
    }
  }
  .publish_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px 10px 12px;
    gap: 8px;
    background: #ff3e9a;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
  }
  .answer_text {
    margin-top: 20px;
  }

  .answer_ai {
    flex: 1;
    overflow-y: auto;
  }
  .bottom_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .share_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;
      gap: 7px;
      width: 37px;
      height: 37px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }
  }
}

.row_reference {
  width: 100%;
  display: flex;
  align-items: center;

  &_item {
    max-width: 145px;
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 8px;
  }
}

.edit_answer {
  display: flex;
  flex-direction: column;
  flex: 1;

  .answer_box_input {
    width: 100%;
    height: calc(100% - 40px);
    border: none;
    border-width: 0px;
    margin-top: 32px;
    margin-bottom: 32px;
    line-height: 24px;
  }

  .answer_box_input:focus {
    outline-width: 0px;
    flex: 1;
  }

  .cancel_btn {
    width: 100px;
    height: 44px;
    border-radius: 44px;
    border: 1px solid $color-border;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .save_answer_btn {
    width: 144px;
    height: 48px;
    border-radius: 32px;
    background-color: $color-main;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
