.mobile_share_chat_modal {
  padding: 8px 16px;
  padding-bottom: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .list_body {
    width: 100%;
    margin-top: 32px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    .item_container {
      width: 100%;
      height: 56px;
      border-radius: 24px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);

      padding: 0px 20px;
      gap: 12px;
    }
  }

  .shared_as_article {
    width: 100%;
    gap: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 24px;

    .success_mark {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      background-color: #ff3e9a;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text_view {
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: 12px;
    }
  }
}
