@import 'shared/utils/colors.scss';
.find_new_creator {
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 0px 122px;
  overflow-y: auto;
  background-color: #f7fafc;
  min-height: calc(100% + 100px);
  padding-top: 100px;

  &_top_bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    width: 100%;
    &_left {
      display: flex;
      flex-direction: row;
      gap: 24px;
      align-items: center;
      &_back_icon {
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        /* Line/Default */
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
      }
    }
    &_right {
    }
  }
  &_top_week {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 48px;
    &_monthly_top_creators {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      width: 70%;
      height: 395px;
      &_title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }
      &_body {
        width: 100%;
        .react-horizontal-scrolling-menu--inner-wrapper {
          display: block;
          position: relative;
          .react-horizontal-scrolling-menu--arrow-left {
            display: block;
            position: relative;
            top: 115px;
            width: fit-content;
            z-index: 124;
            .interest_filter_action {
              width: fit-content;
              box-shadow: 0px 4px 20px rgba(67, 134, 178, 0.3);
              border-radius: 40px;
            }
          }
          .react-horizontal-scrolling-menu--arrow-right {
            display: block;
            position: relative;
            float: right;
            bottom: 397px;
            z-index: 124;
            .interest_filter_action {
              box-shadow: 0px 4px 20px rgba(67, 134, 178, 0.3);
              border-radius: 40px;
            }
          }
          .react-horizontal-scrolling-menu--scroll-container {
            top: -117px;
          }
        }
      }
    }
    &_leaderboard {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      width: 30%;
      &_title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }
      &_body {
        display: flex;
        flex-direction: column;
        padding: 24px;
        background: #ffffff;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
        border-radius: 24px;
        width: 100%;
        height: 395px;
        overflow-y: auto;
      }
    }
  }
  &_trending_new_creators {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    &_title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
    }
  }
}
