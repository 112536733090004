@import 'shared/utils/colors.scss';

.reference_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  // margin-top: 18px;
}

.reference_action_btn_tt {
  width: 32px;
  height: 32px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-border;
  margin-right: 8px;
  padding: 12px;
}

.reference_item_tt {
  height: 32px;
  border: 1px solid $color-border;
  border-radius: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 8px;
}

.reference_item_selected_tt {
  // max-width: 170px;
  height: 32px;
  border: 1px solid rgb(255, 62, 154);
  border-radius: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 8px;
}

.reference_name {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  max-width: 100px;
  margin-left: 8px;
}
