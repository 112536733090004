.mobile_feed_full_view_modal {
  height: 100vh;
  overflow: hidden;
  background-color: white;

  display: flex;
  flex-direction: column;

  position: relative;

  .header_view {
    padding: 0px 16px;
    margin-top: 16px;
    margin-right: 4px;
    gap: 16px;

    align-items: end;
    justify-content: end;
  }

  .feed_content_body {
    height: calc(100%);

    position: relative;
    display: flex;
    flex-direction: column;

    zoom: 80%;

    .user_info {
      width: 100%;
      padding-top: 8px;
      gap: 8px;

      .user_img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        object-fit: cover;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .iframe_body {
    height: 100%;
    margin-top: 16px;
    position: relative;
    display: flex;
    flex-direction: column;

    .iframe_content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .preview_left {
    position: absolute;
    left: 8px;
    top: 50%;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    transform: translateY(-50%);

    // background-color: white;
    // box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .preview_right {
    position: absolute;
    right: 8px;
    top: 50%;
    width: 100px;
    height: 100px;
    transform: translateY(-50%);

    // background-color: white;
    // box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile_feed_card_chat,
  .mobile_card_bookmark,
  .mobile_card_article,
  .mobile_card_poll,
  .poll_graph,
  .mobile_card_file {
    box-shadow: none !important;
    border-radius: 0px !important;
  }

  .mobile_card_poll {
    height: calc(100% + 16px);
    margin-top: -16px;
  }

  .side_bar {
    position: absolute;
    height: fit-content;
    top: 0;
    bottom: 0;
    right: 8px;
    margin-top: auto;
    margin-bottom: auto;

    display: flex;
    flex-direction: column;
    gap: 8px;

    .item_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 48px;
      height: 48px;

      background: #ffffff30;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 48px;

      .icon_container {
        width: 30px;
        height: 30px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.preview_modal_body {
  height: 100% !important;
  border-radius: 0px;
}

.custom_link_preview_modal {
  z-index: 1000 !important;
}
