.shared_meme_container {
  width: 100%;

  .shared_meme {
    width: 100%;
    padding: 32px;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    margin-bottom: 32px;

    display: flex;
    align-items: center;
    overflow-x: auto;

    .intro_view {
      min-width: 200px;
      max-width: 200px;

      .mark_view {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background-color: #ff6058;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .react-horizontal-scrolling-menu--wrapper {
    width: calc(100% - 200px);
    height: 330px;
  }

  .react-horizontal-scrolling-menu--inner-wrapper {
    display: block;
    position: relative;

    .react-horizontal-scrolling-menu--arrow-left {
      display: block;
      position: relative;
      top: 80px;
      width: fit-content;
      z-index: 124;
      .interest_filter_action {
        width: fit-content;
        box-shadow: 0px 4px 20px rgba(67, 134, 178, 0.3);
        border-radius: 40px;
      }
    }
    .react-horizontal-scrolling-menu--arrow-right {
      display: block;
      position: relative;
      float: right;
      bottom: 330px;
      z-index: 124;
      .interest_filter_action {
        box-shadow: 0px 4px 20px rgba(67, 134, 178, 0.3);
        border-radius: 40px;
      }
    }
    .react-horizontal-scrolling-menu--scroll-container {
      top: -100px;
    }
  }
}
