@import 'shared/utils/colors.scss';

.card_asked_question {
  margin-top: 24px;
  padding-bottom: 24px;
  border: 1.25px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 32px;

  .send_star_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 8px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    .send_star_btn_label {
      color: #000000;
      margin-left: 10px;
    }
  }

  .question_date {
    display: flex;
    align-items: center;
    text-align: right;
    margin: 0px 36px;
    color: rgba(0, 0, 0, 0.6);
  }

  .other_user_answers {
    margin-top: 24px;

    .show_more_answer {
      color: rgba(0, 0, 0, 0.6);
    }

    .add_your_answer {
      color: $color-main;
    }
  }

  .creator_image {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }
  .user_name {
    margin-left: 8px;
    margin-right: 8px;
  }
  .card_add_your_answer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    border: 1.25px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .user_info_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 8px;
    }
    .user_answer_body {
      width: 100%;
      display: flex;
      flex-direction: column;
      .question_box_input {
        flex: 1;
        border: none;
        border-width: 0px;
      }

      .question_box_input:focus {
        outline-width: 0px;
      }
    }
    .user_answer_bottom_bar {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 10px;
      .cancel_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 20px;
        gap: 5px;
        /* Line/Default */
        border: 1.25px solid rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(18.125px);
        height: 34px;
        width: 120px;
        border-radius: 60px;
      }
      .post_answer_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 20px;
        gap: 5px;
        background: #ff3e9a;
        backdrop-filter: blur(18.125px);
        height: 34px;
        width: 120px;
        border-radius: 60px;
      }
    }
  }
}
