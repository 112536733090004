.mobile_personal_type_get_start {
  width: 100%;
  border-radius: 24px;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  .card_number {
    width: 100%;
    color: rgba($color: #0f0f0f, $alpha: 0.25);
  }

  .ai_name {
    text-align: center;
  }

  .ai_desc {
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
  }

  .get_start_btn {
    width: 100%;
    height: 40px;
    background: linear-gradient(180deg, #0f0f0f 0%, rgba(15, 15, 15, 0.8) 100%);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    margin-top: 16px;
  }
}
