.question_title {
  flex: 1 1;
  text-align: start;
  margin-left: 20px;
}
.modal_send_invite_body {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .react-horizontal-scrolling-menu--wrapper {
    flex-direction: row;

    .react-horizontal-scrolling-menu--header {
      width: 0px;
    }

    .react-horizontal-scrolling-menu--footer {
      width: 0px;
    }
  }

  .send_invite_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    .send_add_body_input_bar {
      display: inline;
      width: 100%;
    }
    .send_invite_body_selected_users {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      overflow-y: auto;
      height: 328px;
      .card_user {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0px;
        gap: 12px;
        width: 100%;

        background: #ffffff;
        border-radius: 16px;
        .card_user_avatar {
          .user_image {
            border-radius: 50%;
          }
        }
        .card_user_text {
          width: 100%;
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .user_email {
            opacity: 0.4;
            display: flex;
            justify-content: flex-start;
          }
          .user_name {
            display: flex;
            justify-content: flex-start;
          }
        }
        .card_user_cancel {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px;
          gap: 8px;

          width: 36px;
          height: 36px;

          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 48px;
        }
      }
    }
  }
  .send_invite_bottom_bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .selected_user_bar {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      overflow: auto;
      white-space: nowrap;

      .selected_user {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px 8px 8px;
        gap: 8px;
        margin-right: 8px;
        /* Line/Default */

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        .user_image {
          border-radius: 50%;
        }
      }
    }

    .send_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 20px;
      gap: 8px;
      width: 100%;
      /* Main/Primary */

      background: #ff3e9a;
      border-radius: 48px;
    }
  }
}
