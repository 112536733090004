.main_card_flip_question {
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  position: relative;

  .bg_image {
    position: absolute;
    top: 50px;
    right: 0px;
  }

  .mark {
    background-color: transparent;
    .light_view {
      width: 32px;
      height: 32px;
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flip_question_body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;

    .question_main_body {
      max-height: 180px;
      overflow-y: auto;
    }

    .question_txt {
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }

  .flip_question_bottom {
    z-index: 900;
    .flip_btn {
      width: 65px;
      height: 36px;

      color: #ff3e9a;
      background: #ffffff;
      /* Main/Primary */

      border: 1px solid #ff3e9a;
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
