.mobile_chat_intro_modal {
  padding: 8px 16px;
  padding-bottom: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .header_view {
    width: 100%;
    margin-top: 16px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    margin-top: 16px;
  }

  .chat_intro_body {
    width: 100%;
    margin-top: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .chat_ai_image {
      width: 88px;
      height: 88px;
      border-radius: 88px;
      background-color: rgba($color: #000000, $alpha: 0.05);
    }

    .personal_ai_txt {
      background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .creator_image {
      width: 16px;
      height: 16px;
      border-radius: 16px;
      background-color: rgba($color: #000000, $alpha: 0.05);
    }

    .sub_txt {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 16px;
    }

    .chat_intro_desc {
      opacity: 0.6;
      line-height: 20px;

      margin: 0px 16px;
    }

    .chat_intro_btn {
      width: 100%;
      height: 45px;

      background: linear-gradient(
        to top right,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 1)
      );
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 35px;
      font-size: 16px;
      cursor: pointer;
      gap: 8px;
      margin-top: 24px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .loading_container {
    width: 100%;
    height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .audio_play_btn {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 4px;
    right: 4px;
  }
}
