.document_question {
  height: calc(100% - 100px);
  padding: 69px 69px;
  background: linear-gradient(180deg, #ffffff 0%, #f3f4f5 100%);

  .question_body {
    min-height: calc(100% - 200px);
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 24px;
    margin-top: 56px;

    .bookmark_navbar {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 8px;
      gap: 16px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
      border-radius: 80px;
      .bookmark_navbar_item_selected {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px;
        gap: 4px;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        /* Main/Primary */

        background: #ff3e9a;
        border-radius: 40px;
      }
      .bookmark_navbar_item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px;
        gap: 8px;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        opacity: 0.4;
      }
    }
  }

  &_empty_view {
    width: fit-content;
    padding: 24px;
    border-radius: 16px;
    margin: auto;
    margin-top: 150px;
  }
}
