.modal_connect_social {
  &_header {
    display: flex;
    align-items: center;
    margin: 16px 0px;

    .modal_back_btn {
      cursor: pointer;
    }

    .title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
      white-space: nowrap;
    }
  }

  &_body {
    margin-top: 32px;
    max-height: 300px;
    overflow-y: auto;
  }

  &_sub {
    &_header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .sub_header_mark {
        width: 7px;
        height: 7px;
        margin: 0px 8px;
        border-radius: 3px;
      }
    }
  }
}
