.mobile_card_my_personal_ai {
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  padding: 12px 16px;
  margin: 0px 16px;

  .top_info_view {
    gap: 12px;

    .ai_image {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      object-fit: cover;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }

    .user_image_container {
      position: relative;
      width: 64px;
      height: 64px;

      .user_image_content {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .user_image_view {
        position: relative;

        .camera_icon_tag {
          position: absolute;
          right: -3px;
          bottom: -3px;
          border-radius: 50%;
          background-color: #ff3e9a;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 100;

          .camera_icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .ai_name {
      flex: 1;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ai_action_btn {
      width: 40px;
      height: 40px;

      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .action_view {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 8px;

    .file_btn {
      flex: 1;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .chat_btn {
      flex: 1;
      height: 40px;
      background: linear-gradient(
        180deg,
        #0f0f0f 0%,
        rgba(15, 15, 15, 0.8) 100%
      );
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
}
