.mobile_card_poll {
  height: calc(100% + 16px);

  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px;

  display: flex;
  flex-direction: column;
  position: relative;

  .poll_header {
    padding: 0px 16px;
    margin-top: 20px;
  }

  .user_avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .poll_graph {
    min-width: 70px;
    height: calc(100%);
    border-top-left-radius: 24px;
    background-color: #f3f4f580;
    z-index: 2;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    .empty_poll_graph {
      display: flex;
      flex-direction: column;
    }

    .real_poll_graph {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .poll_main_body {
    padding: 16px 24px;

    display: flex;
    flex-direction: column;

    gap: 20px;

    .selected_choice {
      width: fit-content;
      height: 45px;
      border-radius: 45px;

      background: #ff3e9a;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      padding: 0px 12px;

      .selected_choice_mark {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background-color: #f3f4f5;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #ff3e9a;
      }

      .selected_choice_title {
        margin-left: 8px;
        color: white;
      }
    }

    .default_choice {
      width: fit-content;
      height: 45px;
      border-radius: 45px;

      background: white;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      padding: 0px 12px;

      .default_choice_mark {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background-color: #f3f4f5;

        display: flex;
        align-items: center;
        justify-content: center;

        color: rgba(0, 0, 0, 0.4);
      }

      .default_choice_title {
        margin-left: 8px;
        color: black;
      }
    }
  }

  .mark_view {
    width: 24px;
    height: 24px;

    background: #f3f4f5;
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
