.mobile_media_body {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.1);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .card_media_view {
    width: 100%;
    position: relative;
  }

  .play_btn {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(22px);
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .remove_btn {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: white;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 16px;
    right: 16px;
  }
}
