@import 'shared/utils/colors.scss';

.success_join_group_modal {
  .success_title {
    text-align: center;
    margin-top: 32px;
  }
  .success_group_detail {
    margin: 32px auto;
    border: 1px solid $color-border;
    padding: 24px;
    border-radius: 16px;

    .success_group_desc {
      color: rgba($color: #000000, $alpha: 0.6);
      margin-top: 8px;
      text-align: center;
    }

    .success_group_member {
      display: flex;
      align-items: center;
    }
  }

  .success_group_action {
    .success_group_action_skip {
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: 12px;
      .skip_txt {
        color: rgba($color: #000000, $alpha: 0.6);
        text-align: center;
      }
    }
  }
}
