.mobile_card_prompt {
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  border-radius: 16px;
  padding: 16px;
  gap: 8px;

  .action_btn {
    min-width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    .default_mark {
      width: 24px;
      height: 24px;

      /* Main/Gray Shade */

      background: #f3f4f5;
      /* Line/Default */

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }
  }
}
