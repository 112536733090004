.mobile_event_home {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .back_btn {
    padding-left: 16px;
    padding-bottom: 16px;
  }

  .search_view {
    padding: 0px 16px;
  }

  .events_body {
    margin-top: 24px;
    gap: 16px;
  }
}
