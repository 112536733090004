@import 'shared/utils/colors.scss';

.modal_share_statement {
  margin-top: 16px;

  .modal_share_statement_header {
    display: flex;
    align-items: center;

    .modal_back_btn {
      cursor: pointer;
    }

    .title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
    }
  }

  .modal_share_statement_body {
    margin-top: 30px;

    .statement_card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 32px;
      gap: 12px;
      isolation: isolate;
      background: #ffffff;
      box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &_tip {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 16px 8px 8px;
          gap: 7px;

          width: 158px;
          height: 48px;

          background: rgba(255, 230, 242, 0.5);
          border-radius: 32px;
          &_lamp {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;
            gap: 8px;
            justify-content: center;
            width: 32px;
            height: 32px;

            background: #ffffff;
            border-radius: 32px;
          }
          &_did_you_know {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */

            display: flex;
            align-items: center;

            /* Main/Primary */

            color: #ff3e9a;
          }
        }
      }
      &_body {
        font-family: 'Poppins';
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
      }
      &_bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 16px;
        width: 100%;

        &_user {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 4px;
          font-style: 'font-normal';
          &_name {
            font-weight: 500;
          }
        }
        &_btns {
          width: 60px;
          height: 60px;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: center;

          &_QR {
            position: relative;
          }
          /* Line/Default */
          &_scan_me {
            position: absolute;
            width: 40px;
            height: 18px;
            border-radius: 12px;
            border: 3px solid white;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: $color-main;
          }
        }
      }
    }
    .statement_link_bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 24px;
      width: 100%;
      height: 70px;
      margin-top: 48px;
      &_items {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 10px;

        width: 51px;
        height: 70px;
        &_item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 12px;
          gap: 8px;
          justify-content: center;
          width: 48px;
          height: 48px;

          /* Main/Primary */

          // background: #FF3E9A;
          border-radius: 16px;
        }
        .download_itm {
          background: #ff3e9a;
        }
        .Whatsapp_itm {
          background: #08b883;
        }
        .Telegram_itm {
          background: #80bbf2;
        }
        .Instagram_itm {
          background: #9214f5;
        }
        .Twitter_itm {
          background: #2d9cdb;
        }
        .Tiktok_itm {
          background: #333333;
        }
      }
    }
  }
}
