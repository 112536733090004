.mobile_prompts {
  display: flex;
  flex-direction: column;
  background-color: white;

  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;

  padding: 0px 16px;
  padding-top: 24px;
  overflow: hidden;

  .bot_image {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }

  .personal_txt {
    background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .content_container {
    height: calc(100% - 150px);
    margin-top: 32px;

    display: flex;
    flex-direction: column;
  }

  .content_list {
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 8px;

    margin-top: 16px;
  }

  .bottom_view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    padding-top: 12px;

    .action_btn {
      flex: 1;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-radius: 40px;
      gap: 6px;
    }
  }

  .done_btn {
    padding: 6px 16px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 40px;
  }
}
