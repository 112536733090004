.question_filter_action_modal {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;

  .question_filter_action_modal_body {
    position: absolute;
    width: 200px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 12px 76px rgba(132, 151, 164, 0.4);
    border-radius: 24px 0px 24px 24px;
    padding: 12px;

    .action_item {
      padding: 12px 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .action_icon_view {
        width: 32px;
        height: 32px;

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .action_name {
        width: 200px;
        margin-left: 8px;
      }
    }
  }
}
