.mobile_profile_personal_ai {
  .profile_org_body {
    padding: 16px;
  }

  .ai_list {
    display: flex;
    align-items: center;
    gap: 12px;

    overflow-x: auto;
    margin-bottom: 16px;
    padding-left: 16px;
  }

  .join_new {
    color: #ff3e9a;
  }
}
