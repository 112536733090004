@import 'shared/utils/colors.scss';

.group_right {
  height: 100%;

  .group_left_header {
    display: flex;
    justify-content: space-between;

    .btn_create_group {
      width: 160px;
      height: 46px;
      border-radius: 46px;
      background-color: $color-main;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 8px;

      .btn_create_group_txt {
        color: white;
      }
    }
  }

  .group_left_body {
    width: calc(100% + 32px);
    height: calc(100% - 50px);
    overflow: auto;
    margin-top: 18px;
    margin-left: -16px;

    .infinite-scroll-component {
      padding: 6px 16px;
    }

    .no_group {
      border-radius: 16px;
      background-color: white;
      box-shadow: 0px 4px 12px 0px #00000026;
      padding: 220px 32px;
      margin: 8px 16px;

      .hug_icon {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 56px;
        line-height: 84px;
        text-align: center;
        color: #000000;
      }

      .text_group_yet {
        font-weight: 600;
        line-height: 27px;
        text-align: center;
        color: #000000;
      }

      .text_create_group {
        text-align: center;
        color: #000000;
      }

      .btn_create_group {
        width: 145px;
        height: 43px;
        border-radius: 46px;
        background-color: $color-main;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 16px;

        .btn_create_group_txt {
          color: white;
        }
      }
    }
  }
}
