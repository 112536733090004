.chat_history {
  flex-grow: 1;
  margin: 24px;
  height: 100%;

  &_title {
    color: rgba($color: #000000, $alpha: 0.3);
    margin-top: 60px;
    margin-bottom: 32px;
  }

  &_body {
    height: calc(100% - 100px);
    overflow-y: auto;

    &_list {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
