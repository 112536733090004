.mobile_card_meme {
  height: calc(100% - 32px);

  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 20px 16px;
  padding-bottom: 0px;

  margin: 24px 16px;

  display: flex;
  flex-direction: column;
  position: relative;

  .meme_top_view {
    .user_point {
      width: 80px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      gap: 4px;
      margin-right: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .user_avatar {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      object-fit: cover;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .detail_btn {
      width: 32px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .meme_body {
    flex: 1;
    max-height: calc(100% - 170px);
    border-radius: 16px;
    margin-top: 16px;

    .meme_image {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: contain;
    }
  }

  .meme_bottom {
    position: absolute;
    left: 8px;
    bottom: 0px;
    width: calc(100% - 16px);
    border-radius: 24px;
    background-color: white;
    transform: matrix(1, 0, 0, -1, 0, 0);
    padding: 20px 16px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    .link_container {
      transform: matrix(1, 0, 0, -1, 0, 0);

      padding: 8px 10px;
      background: rgba(243, 244, 245, 0.5);
      border-radius: 40px;
      gap: 8px;
    }

    .vote_container {
      gap: 8px;
      transform: matrix(1, 0, 0, -1, 0, 0);

      .vote_item {
        flex: 1;

        padding: 12px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        gap: 8px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .selected_vote_item {
        flex: 1;
        padding: 12px;
        background: #ffffff;
        border: 1px solid #ff3e9a;
        border-radius: 32px;
        gap: 8px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
