.mobile_prompt_action_modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 8px;
  padding: 8px 16px;
  padding-bottom: 16px;

  .header_view {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;

    .prompt_title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
    }

    .prompt_content {
      background-color: #f3f4f5;
      padding: 12px;
      border-radius: 16px;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: rgba($color: #000000, $alpha: 0.1);

      margin: 16px 0px;
    }
  }

  .action_view {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .action_btn {
      width: 100%;
      height: 40px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-radius: 16px;
      padding: 8px;

      .left_view {
        gap: 8px;
      }
    }

    .vertical_divider {
      width: 1px;
      height: 20px;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }
}
