.add_link_modal {
  .link_container {
    margin-top: 25px;

    padding: 12px;
    border: 1px solid #ff3e9a;
    border-radius: 16px;

    .title {
      color: #ff3e9a;
    }

    .add_link_input_container {
      width: 100%;
      margin-top: 8px;

      .sub_input {
        width: 100%;
        flex: 1;
        border: none;
        border-width: 0px;
      }

      .sub_input:focus {
        outline-width: 0px;
      }
    }
  }
}
