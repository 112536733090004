.mobile_user_info {
  padding-bottom: 16px;
  background-color: white;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .user_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;

    .user_image_container {
      position: relative;
      width: 80px;
      height: 80px;

      .user_image_content {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .user_image_view {
        position: relative;

        .camera_icon_tag {
          position: absolute;
          right: -3px;
          bottom: -3px;
          border-radius: 50%;
          background-color: #ff3e9a;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 100;

          .camera_icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .user_info_detail {
      width: calc(100% - 96px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 16px;

      .user_info_detail_top {
        width: 100%;
        gap: 8px;

        .user_name {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .edit_btn {
          width: 36px;
          height: 36px;
          background: #ffffff;
          border: 2px solid #ffffff;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
          border-radius: 12px;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .qr_code_view {
          height: 36px;
          padding: 0px 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 12px;

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 4px;
        }
      }

      .user_info_follow {
        width: 100%;
        margin-top: 8px;

        .follow_item {
          flex: 1;

          .follow_top {
            display: flex;
            flex-direction: row;
            gap: 4px;

            .follow_value {
              opacity: 0.6;
            }
          }

          .follow_txt {
            opacity: 0.3;
          }
        }
      }
    }
  }

  .description {
    color: #000000;
    opacity: 0.4;
    padding: 0px 16px;
  }
}
