.mobile_share_question_modal {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #b3e6b2 0%, #e6c8b2 100%);
  display: flex;
  flex-direction: column;

  .share_header {
    width: 100%;
    padding: 16px;

    .header_title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
    }
  }

  .share_body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .share_main_body {
      width: calc(100% - 32px);
      background: #ffffff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      padding: 18px;

      .qr_view {
        width: 44px;
        height: 44px;
        position: relative;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 12px;

        .scan_me {
          position: absolute;
          bottom: -5px;
          left: 5px;
          width: fit-content;
          background: #ff3e9a;
          border: 2.06647px solid #ffffff;
          border-radius: 16.5318px;
          padding: 2px 4px;
          color: white;
          font-size: 5px;
          text-align: center;
        }
      }

      .question {
        margin-top: 4px;
      }

      .question_answer {
        text-overflow: ellipsis;
        overflow: hidden;
        // Addition lines for 2 line or multiline ellipsis
        display: -webkit-box !important;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
  }

  .share_bottom {
    height: 120px;
    background-color: white;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 24px;

    .statement_link_bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 24px;
      width: 100%;
      height: 70px;
      margin-top: 48px;
      &_items {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 10px;

        width: 51px;
        height: 70px;
        &_item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 12px;
          gap: 8px;
          justify-content: center;
          width: 48px;
          height: 48px;

          /* Main/Primary */

          // background: #FF3E9A;
          border-radius: 16px;
        }
        .download_itm {
          background: #ff3e9a;
        }
        .Whatsapp_itm {
          background: #08b883;
        }
        .Telegram_itm {
          background: #80bbf2;
        }
        .Instagram_itm {
          background: #9214f5;
        }
        .Twitter_itm {
          background: #2d9cdb;
        }
        .Tiktok_itm {
          background: #333333;
        }
      }
    }
  }
}
