.card_file_ai_answer {
  margin-top: 24px;
  border: 1.25px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 15px;

  .answer {
    margin: 10px 0px;
  }

  .show-more-less-clickable {
    color: #ff3e9a;
    text-decoration: none;
  }
}
