.mobile_profile_follow_modal {
  background-color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 16px;
  padding-top: 16px;

  .header_view {
    gap: 16px;
    padding: 0px 16px;
  }

  .tab_header {
    .tab_item {
      flex: 1;
      padding-bottom: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .search_view {
    padding: 0px 16px;
  }

  .content_view {
    flex: 1;
    overflow-y: auto;
    padding: 0px 16px;
    padding-bottom: 16px;
  }
}

.mobile_profile_follow_modal_body {
  height: 100% !important;
  border-radius: 0px;
}

.custom_mobile_profile_follow_modal_body_modal {
  z-index: 1000 !important;
}
