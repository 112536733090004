@import 'shared/utils/colors.scss';
.mobile_login_qr {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  display: flex;
  flex-direction: column;

  .top_bar_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
    .top_bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .body_wrapper {
    flex: 1;
    padding-bottom: 200px;

    .camera_view {
      width: 100%;
      height: 100%;

      video {
        object-fit: cover;
      }
    }
  }
  .bottom_bar_wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    .bottom_bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 24px;
      gap: 16px;

      background: #ffffff;
      border-radius: 32px 32px 0px 0px;
      .bottom_bar_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
      }
    }
  }

  .loading_bar {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba($color: #000000, $alpha: 0.4);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 200px;
  }

  .you_can {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    &_divider {
      width: 80px;
      height: 1px;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }

  .open_image {
    width: 100%;
    height: 45px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 45px;
    gap: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mobile_request_camera_btn {
  height: 45px;
  border-radius: 45px;
  color: white;
  background-color: #ff3e9a;
  border: none;
  outline: none;
  padding: 0px 16px;
}

.html5-qrcode-element {
  height: 45px;
  border-radius: 45px;
  color: white;
  background-color: #ff3e9a;
  border: none;
  outline: none;
  padding: 0px 16px;
}

#html5-qrcode-button-camera-start {
  margin-top: 16px;
}

#html5-qrcode-anchor-scan-type-change {
  display: none !important;
}
