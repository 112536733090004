.mobile_content_add_modal {
  width: 100%;
  height: 100%;
  z-index: 100000;

  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  padding-right: 12px;
  padding-bottom: 90px;

  .modal_close_btn {
    width: 64px;
    height: 64px;
    background: white;
    box-shadow: 0px 4px 28px 8px rgba(163, 0, 78, 0.2);
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 16px;

    .content_item {
      background-color: white;
      padding: 0px 12px;
      height: 40px;
      border-radius: 50px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }
  }
}
