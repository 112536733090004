@import 'shared/utils/colors.scss';

.modal_create_group {
  margin-top: 16px;

  .modal_create_group_header {
    display: flex;
    align-items: center;

    .modal_back_btn {
      cursor: pointer;
    }

    .title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
    }
  }

  .modal_create_group_body {
    margin-top: 30px;

    .sub_title {
      font-size: 10px;
      color: rgba($color: #000000, $alpha: 0.4);
    }

    .sub_input {
      width: 100%;
      border: none;
      border-width: 0px;
      margin-top: 8px;
    }

    .sub_input:focus {
      outline-width: 0px;
    }

    .group_name {
      border: 1px solid $color-border;
      border-radius: 16px;
      padding: 16px;
    }

    .group_desc {
      border: 1px solid $color-border;
      border-radius: 16px;
      padding: 16px;
      margin-top: 16px;
    }

    .btn_create {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        .cancel_btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            gap: 16px;
            height: 45px;
            width: 100%;
            background: #FFFFFF;
            /* Line/Default */

            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 48px;
        }
        .cancel_btn:hover {
            background: #f9f9f9;

        }
    }
  }
}

.interest_filter_row {
  margin-top: 32px;

  .interest_filter_action {
    display: flex;
    align-items: center;
  }

  .interest_filter_item_default {
    padding: 16px;
  }

  .interest_filter_item_selected {
    padding: 16px;
    border: 1px solid $color-border;
    border-radius: 100px;
  }
}
