.personal_ai_files {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .ai_file_header {
    padding: 24px 16px;

    .ai_file_my_txt {
      background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .ai_action_btn {
      width: 40px;
      height: 40px;

      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ai_file_body {
    height: 100%;
    overflow-y: auto;
    padding: 0px 16px;

    display: flex;
    flex-direction: column;
  }

  .empty_creator {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
