.event_hosted {
  width: calc(100% + 32px);
  margin-left: -16px;

  &_body {
    width: 100%;
    height: 100px;

    .create_btn {
      width: 130px;
      height: 90px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      margin-right: 8px;

      &_txt {
        color: rgba(0, 0, 0, 0.6);
        margin-top: 6px;
      }
    }
  }

  .react-horizontal-scrolling-menu--scroll-container {
    padding: 16px;

    .react-horizontal-scrolling-menu--item {
      margin: 8px 0px;
    }
  }

  .event_empty_view {
    width: 100%;
    height: 120px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    margin-top: 16px;
    padding: 0px 64px;

    .create_event_btn {
      width: 180px;
      height: 44px;
      border-radius: 44px;
      background-color: #ff3e9a;
      color: white;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
