.card_point_history {
  padding: 20px 0px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &_icon {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    background-color: #08b8831a;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_info {
    margin-left: 16px;
    flex: 1;
  }
}
