@import 'shared/utils/colors.scss';

.mobile_card_join_org {
  width: 130px;
  height: 153px;

  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  padding: 12px 16px;
  margin: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .org_image {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }

  .org_join_btn {
    width: 100%;
    height: 30px;
    border-radius: 30px;
    background-color: #ff3e9a;
    color: white;
    margin-top: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .org_following_btn {
    width: 100%;
    height: 30px;

    /* Line/Default */

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    margin-top: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  .org_requested_btn {
    width: 100%;
    height: 30px;

    background-color: $color-pink;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    color: black;
    margin-top: 6px;
  }
}
