.mobile_card_flip_answer {
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding: 32px;

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flip_answer_body {
    height: calc(100% - 50px);
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 16px;

    .feed_main_content {
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      padding-bottom: 40px;
    }

    .flip_answer_image {
      width: 100%;
      aspect-ratio: 319 / 205;
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        #d9d9d9;
      border-radius: 16px;
    }

    .question_txt {
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.003em;
    }
  }

  .flip_answer_bottom {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background: linear-gradient(
      180deg,
      #ffffff 70%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
    transform: matrix(1, 0, 0, -1, 0, 0);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px 16px;
    padding-bottom: 70px;
    border-radius: 16px;

    .flip_action_conatiner {
      width: 100%;
      gap: 8px;
      transform: matrix(1, 0, 0, -1, 0, 0);
      justify-content: center;

      position: absolute;
      top: 16px;

      .flip_btn {
        min-width: 80px;
        height: 36px;

        color: rgba(0, 0, 0, 0.5);
        background: #ffffff;
        /* Main/Primary */

        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 32px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ref_text {
        margin-left: 6px;
        width: calc(100% - 60px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
