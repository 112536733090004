.answer_filter_bar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  height: 56px;
  margin-top: 24px;
  .filter_bar_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px 16px 16px;
    gap: 12px;
    height: 56px;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
    border-radius: 32px;
    opacity: 0.6;
    cursor: pointer;
  }
  .filter_bar_item_selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px 16px 16px;
    gap: 12px;
    height: 56px;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
    border-radius: 32px;
    opacity: 0.6;
    cursor: pointer;
    background: #ffffff;
    /* Line/Default */
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    opacity: 1;
  }
}

.empty_view {
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
}
