.home_card_attached_file {
  width: 550px;
  height: 230px;
  min-width: 550px;
  border-radius: 16px;
  background: linear-gradient(
      0deg,
      rgba(255, 230, 242, 0.1),
      rgba(255, 230, 242, 0.1)
    ),
    #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-right: 16px;
  padding: 16px 24px;
  margin-bottom: 8px;

  &_body {
    display: flex;
    align-items: center;

    &_image {
      width: 70px;
      height: 70px;
      border: 1px solid rgba($color: #000000, $alpha: 0.05);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .new_mark {
        position: absolute;
        top: -4px;
        left: -4px;
        padding: 4px 10px;
        background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
        border: 2px solid #ffffff;
        border-radius: 32px;
        color: white;
      }

      .audio_play_icon_view {
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px;
        background: rgba(102, 103, 104, 0.6);
        border-radius: 32px;
      }
    }
    &_info {
      margin-left: 16px;
      width: calc(100% - 130px);

      &_title {
        color: black;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: calc(100%);
      }

      &_bottom {
        display: flex;
        align-items: center;
        margin-top: 4px;

        .failed_mark {
          background: #ffc859;
          border-radius: 32px;
          font-size: 8px;
          padding: 3.2px 6.4px 3.2px 4.8px;
          margin-right: 4px;
        }

        &_size {
          color: rgba($color: #000000, $alpha: 0.6);
        }

        &_date {
          color: rgba($color: #000000, $alpha: 0.6);
          margin-left: 4px;
        }

        &_download {
          display: flex;
          align-items: center;
          margin-left: 16px;
        }
      }
    }

    &_icon {
      width: 40px;
      height: 40px;
      border: 1px solid rgba($color: #000000, $alpha: 0.05);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_divider {
    width: 100%;
    height: 1px;
    background-color: rgba($color: #000000, $alpha: 0.05);
    margin: 10px 0px;
  }

  &_bottom {
    display: flex;
    align-items: center;
    overflow-x: auto;
    margin-top: 16px !important;
  }

  .status_view {
    background-color: rgba($color: #f3f4f5, $alpha: 0.5);
    width: 100%;
    padding: 12px;
    border-radius: 8px;

    .status_label {
      margin-left: 8px;
      margin-top: 3px;
    }
  }

  .send_btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff3e9a;
    border-radius: 32px;
  }

  .privacy_btn {
    height: 40px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    gap: 8px;
    padding: 0px 8px;
    margin-right: 8px;
  }

  .retry_btn {
    width: 64px;
    height: 40px;
    border-radius: 40px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
  }

  .failed_view {
    background-color: white;
    border-radius: 24px;
    padding: 8px 16px;

    margin-top: 32px;
  }

  .answer_view {
    background: linear-gradient(
      90deg,
      rgba(255, 62, 154, 0.05) 0.59%,
      rgba(216, 62, 255, 0.05) 100.59%
    );
    border-radius: 8px;

    padding: 8px 12px;
    margin: 16px 0px;
  }
}
@media only screen and (max-width: 768px) {
  .card_attached_file {
    width: 100vw;
    box-shadow: none;
    border: 0.8px solid rgba(0, 0, 0, 0.1);
    border-radius: 12.8px;
  }
}
.home_card_attached_file_bottom_add_new_tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px 6px 12px;
  gap: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  justify-content: center;
}
.home_card_attached_file_bottom_edit_tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.file_uploading_status {
  border: 1px solid #2f9bff80;
  background-color: rgba(47, 155, 255, 0.1);
}

.file_uploading_status_txt {
  color: #2f9bff;
}

.file_processing_status {
  border: 1px solid rgba(255, 193, 48, 0.5);
  background-color: rgba(255, 193, 48, 0.1);
}

.file_processing_status_txt {
  color: #ffc130;
}
