@import 'shared/utils/colors.scss';

.modal_create_group {
  margin-top: 16px;

  .modal_create_group_header {
    display: flex;
    align-items: center;

    .modal_back_btn {
      cursor: pointer;
    }

    .title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
    }
  }

  .modal_create_group_body {
    margin-top: 30px;
    
    .org_list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 0px;
        width: 100%;
        overflow-y: auto;
        max-height: 400px;
        .card_org {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 0px;
            gap: 12px;

            height: 72px;

            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .org_box_icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
            .org_info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                width: 100%;
                .first_line {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0px;
                    width: 100%;
                }
                .second_line {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0px;
                    gap: 8px;
                    width: 100%;
                    opacity: 0.4;
                    // .members_count {

                    // }
                    .vip_members_count {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 0px;
                        gap: 8px;

                        width: 326px;
                        height: 18px;

                        border-radius: 16px;
                        .vip_avatars {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 0px;

                            width: 40px;
                            height: 16px;
                        }
                    }
                }
            }
            .org_select {
                .select_btn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 6px 14px;
                    gap: 8px;

                        /* Main/Primary */

                    background: #FF3E9A;
                    border-radius: 48px;
                }
                .selected_btn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 12px;
                    gap: 8px;
                    height: 38px;
                    opacity: 0.3;
                    /* Background/White */
                    background: #FFFFFF;
                    /* Line/Default */

                }
            }
        }
    }

    .btn_create {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        .cancel_btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            gap: 16px;
            height: 45px;
            width: 100%;
            background: #FFFFFF;
            /* Line/Default */

            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 48px;
        }
        .cancel_btn:hover {
            background: #f9f9f9;

        }
    }
  }
}