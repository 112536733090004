.mobile_invite_body {
  flex: 1;

  .invite_body_tab {
    display: flex;
    align-items: center;
    height: 36px;
    border-bottom: 0.5px solid rgba($color: #000000, $alpha: 0.4);
    padding: 0px 16px;

    .selected_tab_item {
      flex: 1;
      height: 100%;
      border-bottom: 1px solid rgba($color: #ff3e9a, $alpha: 1);
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .default_tab_item {
      flex: 1;
      height: 100%;
      color: rgba($color: #000000, $alpha: 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
