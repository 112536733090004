.mobile_community_card_searched_user {
  gap: 16px;
  padding: 16px 0px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

  .user_image {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    object-fit: cover;
  }

  .user_name {
    flex: 1;

    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }

  .follower_info {
    gap: 4px;
  }

  .follow_btn {
    width: 80px;
    height: 30px;

    background: #ff3e9a;
    border-radius: 32px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .following_btn {
    width: 80px;
    height: 30px;

    gap: 2px;
    /* Line/Default */

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .requested_btn {
    width: 80px;
    height: 30px;
    background: linear-gradient(180deg, #ffeff7 0%, #ffdaec 100%);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    gap: 2px;
  }
}
