.mobile_search_creator {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  padding: 16px;
  overflow-y: auto;

  .empty_creator {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .top_creator_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    justify-content: space-between;

    .top_creator_filter {
      margin-left: 8px;

      border-bottom: 1px solid #ff3e9a;
    }
  }

  .search_top_view {
    width: 100%;
    padding-right: 24px;

    .title {
      text-align: center;
      flex: 1;
    }
  }
  .search_view {
    margin-top: 16px;
  }

  .top_creator_tab {
    margin-top: 20px;

    display: flex;
    flex-direction: row;

    .default_btn {
      flex: 1;
      padding: 12px 16px;
      height: 34px;
      border-radius: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($color: #000000, $alpha: 0.3);
      white-space: nowrap;
    }

    .selected_btn {
      flex: 1;
      padding: 12px 16px;
      height: 34px;
      border-radius: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background-color: #ff3e9a;
      white-space: nowrap;
    }
  }

  .top_creators_body {
    flex: 1;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
