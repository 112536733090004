.invite_using_email {
  flex: 1;
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  .friends_list {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    overflow: hidden;

    .friend_card {
      height: 37px;
      border-radius: 37px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      align-items: center;
      padding: 8px;
      gap: 8px;
    }
  }

  .add_new_email {
    margin-top: 32px;

    .new_email_input_container {
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 14px 16px;
      margin-top: 12px;

      .sub_input {
        flex: 1;
        border: none;
        border-width: 0px;
      }

      .sub_input:focus {
        outline-width: 0px;
      }

      .plus_btn {
        width: 36px;
        height: 36px;
        border-radius: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .added_emails {
    flex: 1;
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    .email_list {
      height: 150px;
      overflow-y: auto;
    }

    .card_added_email {
      background: #ffffff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 16px 0px;

      .avatar_container {
        width: 36px;
        height: 36px;
        border-radius: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .divider {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin: 16px 0px;
    }
  }

  .bottom {
    .desc_container {
      padding: 8px 24px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
