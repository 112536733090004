@keyframes blink {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
.type-writer::after {
  content: '|';
  animation: blink 1s infinite;
}
