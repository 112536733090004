.card_trending_creators {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
  border-radius: 24px;
  justify-content: space-between;
  width: 100%;
  .card_trending_creators_inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;
    width: 100%;
    cursor: pointer;
    .card_trending_creators_user_avatar {
      img {
        border-radius: 24px;
      }
    }
    .card_trending_creators_user_info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      gap: 12px;
      .card_trending_creators_user_info_satisfied {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 3px;
        opacity: 0.6;
      }
      .card_trending_creators_user_info_bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 9px;
        .card_trending_creators_user_info_bottom_icons {
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 9px 12px;
          gap: 12px;
          height: 39px;
          background: #ffffff;
          border-radius: 60px;
          border: 1.5px solid rgba(0, 0, 0, 0.1);
        }
        .card_trending_creators_user_info_bottom_inner {
          display: flex;
          align-items: center;
          gap: 12px;
          .card_trending_creators_user_info_bottom_inner_user_avatars {
            display: flex;
            img {
              margin: 0px -6px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  .card_trending_creators_chat_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px 12px 12px;
    gap: 12px;

    width: 113px;
    height: 51px;

    /* Line/Default */

    border: 1.5px solid rgba(0, 0, 0, 0.1);
    border-radius: 60px;
  }
}
