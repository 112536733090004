@import '~react-image-gallery/styles/scss/image-gallery.scss';

.mobile_card_file {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 20px 16px;
  padding-bottom: 0px;

  display: flex;
  flex-direction: column;

  position: relative;

  .user_avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .did_know_body {
    height: calc(100%);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 0px;
    padding-bottom: 0px;

    .feed_main_content {
      max-height: 100%;
      overflow-y: auto;

      .article_title {
        font-weight: 700;
        font-family: sohne, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 32px;
        letter-spacing: -0.014em;
        line-height: 38px;
        text-align: center;
      }

      .artile_txt {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.003em;

        margin-top: 12px;
        padding-bottom: 150px;
      }
    }

    .did_know_image {
      width: 100%;
      aspect-ratio: 319 / 205;
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        #d9d9d9;
      border-radius: 16px;

      margin-bottom: 16px;
    }
  }

  .did_know_bottom {
    position: absolute;
    left: 8px;
    bottom: 0px;
    width: calc(100% - 16px);
    height: 32px;
    border-radius: 24px;
    background: linear-gradient(
      180deg,
      #ffffff 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);

    display: flex;
    flex-direction: column;
    gap: 12px;

    .link_container {
      transform: matrix(1, 0, 0, -1, 0, 0);

      padding: 8px 10px;
      background: rgba(243, 244, 245, 0.5);
      border-radius: 40px;
      gap: 8px;
    }

    .vote_container {
      gap: 8px;
      transform: matrix(1, 0, 0, -1, 0, 0);

      .vote_item {
        flex: 1;
        padding: 12px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        gap: 8px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .selected_vote_item {
        flex: 1;
        padding: 12px;
        background: #ffffff;
        border: 1px solid #ff3e9a;
        border-radius: 32px;
        gap: 8px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .main_info_container {
    width: calc(100% - 50px);

    .right_container {
      width: calc(100% - 50px);
      margin-left: 8px;

      .article_title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .article_link {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        opacity: 0.6;
      }
    }
  }

  .article_divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 16px;
  }

  .image_container {
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    margin-bottom: 16px;
    padding: 8px;

    .image-gallery-image {
      height: 300px !important;
    }
  }
}
