@import 'shared/utils/colors.scss';

.modal_pending_member {
  margin-top: 16px;

  .top_divide_line {
    width: 100%;
    height: 0;
    opacity: 0.1;
    border: 1px solid #000000;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .member_list {
    width: 100%;
    height: 200px;
    overflow-y: auto;
  }

  .remove_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 36px;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 48px;
  }

  .check_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 36px;
    height: 36px;
    background: $color-main;
    border-radius: 48px;
    margin-left: 8px;
  }
}
