@import 'shared/utils/colors.scss';

.mobile_profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;

  .profile_header {
    margin-top: 16px;
    margin-left: 16px;
  }

  .profile_body {
    flex: 1;
    display: flex;
    flex-direction: column;

    .render_block {
      width: 100%;

      .join_new_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px 8px 8px;
        gap: 8px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        .plus_icon {
          color: $color-main;
        }
      }

      .fold_btn {
        width: 90px;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;
      }

      .edit_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px 8px 8px;
        gap: 8px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;
      }

      .content {
        display: flex;
        width: 100%;
      }

      .profile_section_scroll {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: auto;
        padding: 8px 0px;
      }

      .plus_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50px;
        height: 50px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        margin: 0px 16px;
      }
    }
  }

  .profile_section_divider {
    width: 100%;
    height: 8px;
    background-color: #f3f4f5;
  }

  .edit_text {
    color: #ff3e9a;
    margin-left: 4px;
  }

  .follow_btn {
    width: 80px;
    height: 36px;
    background-color: #ff3e9a;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    color: white;
  }

  .unfollow_btn {
    width: 100px;
    height: 36px;
    border: 1px solid $color-border;
    background-color: white;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    color: black;
  }

  .requested_btn {
    width: 120px;
    height: 36px;
    background-color: $color-pink;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    color: black;
  }
}
