.card_joined_org {
  width: 186px;
  height: 56px;

  /* Line/Default */

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 8px;

  .group_image {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .group_name {
    max-width: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
