.invite_friend_modal {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;

  .invite_tab {
    height: 34px;
    display: flex;
    align-items: center;
    margin-top: 32px;

    .default_tab {
      flex: 1;
      height: 100%;
      border-bottom: 2px solid rgba($color: #000000, $alpha: 0.2);
      color: rgba($color: #000000, $alpha: 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .selected_tab {
      flex: 1;
      height: 100%;
      border-bottom: 2px solid rgba($color: #ff3e9a, $alpha: 1);
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .friends_know {
    margin-top: 32px;
  }
}
