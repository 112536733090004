.mobile_invite_using_qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;

  .qr_title {
    flex: 1;
    text-align: center;
  }

  .main_body {
    flex: 1;
    width: 300px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 16px;
    margin-top: 32px;
    background-color: white;

    .qr_image_body {
      background-color: white;
      padding: 16px;
      border-radius: 16px;
    }

    .image_view {
      width: 100%;
      aspect-ratio: 1/1;
      background: linear-gradient(223.69deg, #ebd947 -9.61%, #a7872a 117.25%);
      border-radius: 26.1114px;
      margin-top: 16px;
      overflow: hidden;
    }
  }

  .bottom {
    width: 100%;

    .divider {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin: 16px 0px;
    }

    .desc_container {
      padding: 8px 24px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .statement_link_bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 100%;
    height: 70px;
    margin-top: 48px;
    &_items {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 10px;

      width: 51px;
      height: 70px;
      &_item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px;
        gap: 8px;
        justify-content: center;
        min-width: 48px;
        min-height: 48px;

        /* Main/Primary */

        // background: #FF3E9A;
        border-radius: 16px;
      }
      .download_itm {
        background: #ff3e9a;
      }
      .Whatsapp_itm {
        background: #08b883;
      }
      .Telegram_itm {
        background: #80bbf2;
      }
      .Instagram_itm {
        background: #9214f5;
      }
      .Twitter_itm {
        background: #2d9cdb;
      }
      .Tiktok_itm {
        background: #333333;
      }
    }
  }
}
