.card_statement_home {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 32px 32px 0px;
  isolation: isolate;
  height: 210px;
  gap: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  border-radius: 24px;
  margin-bottom: 24px;
  overflow-x: hidden;

  &_no {
    opacity: 0.05;
    margin-left: -24px;
  }
  &_body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    overflow-y: auto;
    &_referneces {
      display: flex;
      gap: 8px;
    }

    .book_marked_icon {
      width: 48px;
      height: 48px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
