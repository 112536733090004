.card_all_shared_doc_container {
  width: 100%;
  position: relative;
  margin-right: 16px;
  padding-top: 6px;

  .card_all_shared_doc_org {
    width: 100%;
    aspect-ratio: 200/256;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    margin: 16px;

    .info_body {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .share_doc_body {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .doc_title {
      height: 35px;
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }

  .sub_effect_body2 {
    width: 100px;
    height: calc(100% - 40px);
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    position: absolute;
    top: 28px;
    right: -22px;
    z-index: 9;
  }
}
