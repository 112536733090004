.mobile_invite_friend {
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;

  .invite_header {
    padding: 20px;
  }
}
