@import 'shared/utils/colors.scss';

.mobile_card_creator {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  overflow: hidden;

  .card_creator_image {
    position: relative;

    img {
      width: 120px;
      height: 120px;
      border-radius: 12px;
    }

    .social_icons_bar {
      position: absolute;
      background: #ffffff;
      border-radius: 12px 0px 0px 12px;
      height: 74px;
      width: 22px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 4px;
      gap: 8px;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
    }
  }

  .ai_info {
    width: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;

    .creator_image {
      width: 16px;
      height: 16px;
      border-radius: 16px;
      background-color: rgba($color: #000000, $alpha: 0.05);
    }

    .top_creator_name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .desc_txt {
      flex: 1;
      width: 100%;
      color: rgba($color: #000000, $alpha: 0.4);
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    .card_creator_bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .follow_btn {
        flex: 1;
        height: 30px;

        background: white;
        border-radius: 32px;
        color: black;

        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 2px;
      }

      .following_btn {
        flex: 1;
        height: 30px;

        gap: 2px;
        /* Line/Default */

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .requested_btn {
        flex: 1;
        height: 30px;
        background: linear-gradient(180deg, #ffeff7 0%, #ffdaec 100%);
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff3e9a;
        gap: 2px;
      }

      .chat_btn {
        flex: 1;
        height: 30px;
        gap: 4px;

        background: linear-gradient(
          180deg,
          #0f0f0f 0%,
          rgba(15, 15, 15, 0.8) 100%
        );
        border-radius: 32px;
        color: white;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .share_btn {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .audio_play_btn {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 4px;
    right: 4px;
  }
}
