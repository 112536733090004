.mobile_card_choose_voice {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 12px;

  gap: 8px;

  .image_container {
    position: relative;

    .bot_image {
      width: 52px;
      height: 52px;
      border-radius: 52px;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }

    .bot_icon {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      background-color: #ff3e9a;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      bottom: 0px;
      right: -6px;
    }
  }

  .main_info {
    flex: 1;
    margin-left: 12px;

    .bot_description {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;

      opacity: 0.8;
    }
  }

  .action_btn {
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    .default_mark {
      min-width: 32px;
      height: 32px;

      /* Main/Gray Shade */

      background: #f3f4f5;
      /* Line/Default */

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }
  }
}
