.org_other_answer_card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  border-radius: 24px;
  margin-bottom: 32px;

  .answer_box_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .answer_box_top_user {
      display: flex;
      align-items: center;
      max-width: 50%;
      white-space: nowrap;
      -webkit-box-orient: vertical;
      overflow: hidden;
      .user_name {
        margin-left: 8px;
        // max-width: 5vw;
        // white-space: nowrap;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // display: -webkit-box;
      }
      .in_label {
        color: #000000;
        opacity: 0.6;
        margin-left: 12px;
        margin-right: 12px;
      }
    }
    .answer_box_top_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .answer_date {
        color: #000000;
        opacity: 0.4;
      }

      .divide_line {
        width: 12px;
        height: 0px;
        opacity: 0.1;
        border: 1px solid #000000;
        transform: rotate(90deg);
        margin-left: 28px;
        margin-right: 28px;
      }
      .answer_top_bar_other_info_pt_btn {
        display: flex;
        flex-direction: row;
        gap: 28px;
        justify-content: space-between;
        .answer_point {
          display: flex;
          align-items: center;
        }
      }
    }
    .send_star_btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 16px 8px 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      gap: 8px;
    }
  }
  .answer_text {
    min-height: 70px;
    border: none;
    border-width: 0px;
    width: 100%;
    cursor: pointer;
    margin-top: 32px;
  }
  .answer_media {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 32px;
    justify-content: space-between;
    width: 100%;
    .answer_media_content {
      display: flex;
      flex-direction: row;
      align-items: center;
      .answer_media_content_txt {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 32px;
      }
    }
    .answer_media_download_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px 8px 12px;
      gap: 8px;

      width: 128px;
      height: 41px;

      /* Main/Primary */

      background: #ff3e9a;
      /* Line/Default */

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      .answer_media_download_btn_txt {
        font-style: normal;
        line-height: 180%;
        /* or 25px */
        color: #ffffff;
        display: flex;
        align-items: center;
      }
    }
  }
  .answer_box_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    // margin-top: 32px;

    .bookmark_btn {
      width: 210px;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }

    .btn_label {
      white-space: nowrap;
    }

    .agree_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 20px;
      gap: 8px;
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(17px);
      border-radius: 32px;

      .btn_number {
        color: #000000;
        opacity: 0.4;
        backdrop-filter: blur(14px);
      }
    }
    .agree_btn_highlighted {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 20px;
      gap: 8px;
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid #ff3e9a;
      -webkit-backdrop-filter: blur(17px);
      backdrop-filter: blur(17px);
      border-radius: 32px;
      margin-right: 12px;
    }
  }
}
