.mobile_home {
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;

  overflow: hidden;

  .mobile_body {
    height: calc(100% - 80px);
  }

  .mobile_tab {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px 16px 0px 0px;
    background-color: white;
    z-index: 1000;

    .tab_item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .tab_item_text {
        text-decoration: none;
        margin-top: 8px;
      }
    }
  }
}
