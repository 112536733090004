.mobile_question_detail_card_answer {
  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 20px 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;

  .top_voted_mark {
    position: absolute;
    top: 0px;
    left: -10px;
    padding: 4px 16px;
    background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
    border-radius: 16px 0px;
    color: #ffffff;
    margin-left: 10px;
  }

  .answer_top_view {
    .user_image {
      width: 32px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      object-fit: cover;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .user_plus {
      width: 32px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      margin-left: 6px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .user_point {
      width: 80px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      gap: 4px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .answer_box_bottom_left {
    display: flex;
    align-items: center;
    gap: 8px;

    .agree_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;

      background: rgba(255, 255, 255, 0.3);
      border: 1px solid rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(17px);
      border-radius: 40px;

      .btn_number {
        color: #000000;
        opacity: 0.4;
        backdrop-filter: blur(14px);
      }
    }

    .agree_btn_highlighted {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px;
      gap: 8px;
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid #ff3e9a;
      -webkit-backdrop-filter: blur(17px);
      backdrop-filter: blur(17px);
      border-radius: 32px;
    }
  }
}
