@import 'shared/utils/colors.scss';

.org_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: 'hidden';
  background-color: linear-gradient(180deg, #ffffff 0%, #f3f4f5 100%);

  .org_body {
    background: #f7fafc;
    height: calc(100% - 100px);
  }
}
