.mobile_feed_filter_modal {
  height: 500px;
  padding: 32px 24px;

  display: flex;
  flex-direction: column;

  .filter_tab {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selected_tab {
      height: 35px;
      border-bottom: 4px solid #ff3e9a;
      color: black;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .default_tab {
      height: 35px;
      border-bottom: 4px solid white;
      color: rgba($color: #000000, $alpha: 0.4);

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .filter_body {
    flex: 1;
    padding-bottom: 16px;
    overflow-y: hidden;
  }

  .filter_bottom {
    gap: 12px;
    padding-top: 16px;

    .cancel_btn {
      flex: 1;
      height: 45px;
      color: black;

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 48px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .apply_btn {
      flex: 1;
      height: 45px;
      color: white;
      background: #ff3e9a;
      border-radius: 48px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
