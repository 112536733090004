.mobile_edit_org_profile {
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;

  .edit_profile_header {
    padding: 32px 16px;
  }

  .edit_profile_body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    gap: 16px;
    padding: 32px 16px;

    .image_section {
      width: fit-content;
      position: relative;

      .camera_icon_tag {
        position: absolute;
        right: 0px;
        bottom: 4px;
        border-radius: 50%;
        background-color: #ff3e9a;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        .camera_icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .input_sections {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .input_section {
        width: 100%;
        padding: 12px;

        display: flex;
        flex-direction: column;
        gap: 8px;

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        .sub_input {
          width: 100%;
          flex: 1;
          border: none;
          border-width: 0px;
        }

        .sub_input:focus {
          outline-width: 0px;
        }

        .sub_title {
          opacity: 0.6;
        }
      }
    }
  }

  .edit_profile_bottom {
    width: 100%;
    height: 65px;
    background: #ffffff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 24px;
  }
}
