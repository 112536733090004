.chat_body {
  width: calc(100% - 32px);
  height: calc(100% - 64px);
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;

  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &_image {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_title {
      flex: 1;
      margin-left: 8px;
    }

    &_normal {
      display: flex;
      align-items: center;
      flex: 1;
    }

    &_drop_header {
      color: rgba(0, 0, 0, 0.3);
    }

    &_end_btn {
      width: 94px;
      height: 53px;
      border-radius: 53px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_body {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column-reverse;
    padding: 16px;
  }

  &_input {
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
    padding: 16px 24px;

    .chat_box_input {
      height: 40px;
      flex: 1;
      border: none;
      border-width: 0px;
    }

    .chat_box_input:focus {
      outline-width: 0px;
    }
  }
  .ended_chat {
    text-align: center;
    padding: 16px 8px;
    gap: 8px;

    // height: 64px;
    /* Main/Gray Shade */

    background: #f3f4f5;
    border-radius: 24px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
  }
}
