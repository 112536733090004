.mobile_my_point {
  background-color: white;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;

  padding: 32px 0px;
  display: flex;
  flex-direction: column;

  .point_available {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    padding: 16px;
    border-radius: 16px;
    background: #ffffff;

    &_icon {
      width: 44px;
      height: 44px;
      border-radius: 16px;
      background-color: #ffe5f280;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .point_divider {
    width: 100%;
    min-height: 8px;
    background-color: #f3f4f5;
  }
}
