@import 'shared/utils/colors.scss';

.content_body {
  width: 550px;
  height: 500px;
  border: 1px solid $color-border;
  border-radius: 40px;
  background-color: $color-white;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .icon {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 32px;
    margin-top: 22px;
    text-align: center;
  }

  .register {
    margin-top: 45px;
  }

  .registerTxt {
    font-size: 18px;
  }

  .register_input {
    margin-top: 12px;
    padding: 16px;
    border: 1px solid $color-border;
    border-radius: 16px;

    .your_email {
      color: rgba($color: $color-black, $alpha: 0.4);
    }
    .email_input {
      width: 100%;
      border: none;
      border-width: 0px;
    }
    .email_input:focus {
      outline-width: 0px;
    }
  }

  .add_referral {
    display: flex;
    margin-top: 6px;
    padding: 16px;
    border: 1px solid $color-border;
    border-radius: 16px;
    align-items: center;
    cursor: pointer;

    .icon {
      display: flex;
      align-items: center;
    }

    .add_referral_qr {
      color: $color-main;
      margin-left: 8px;
    }
  }

  .send_email_btn {
    margin-top: 32px;
  }

  .already_registered {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
    margin-left: 10px;

    .check_referral {
      color: $color-main;
      cursor: pointer;
    }
  }

  .login_password_btn {
    color: $color-main;
    margin: 22px auto 0px;
    text-align: center;
  }

  .have_account {
    position: absolute;
    top: 550px;
  }

  .accept_term {
    margin-top: 32px;
  }
}
