.search_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 16px;
  gap: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

  .search_box_input {
    border: none;
  }
  .search_box_input:focus {
    outline: none;
  }
}
