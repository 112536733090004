.mobile_create_personal_ai_modal {
  padding: 12px 24px;
  padding-bottom: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .top_div {
    width: 60px;
    height: 4px;
    border-radius: 4px;
    background-color: #d9d9d9;
  }

  .camera_icon_tag {
    position: absolute;
    right: -10px;
    bottom: 0px;
    border-radius: 50%;
    background-color: #ff3e9a;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .camera_icon {
      width: 20px;
      height: 20px;
    }
  }

  .personal_name {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .sub_input {
      width: 100%;
      flex: 1;
      border: none;
      border-width: 0px;
      text-align: center;
    }

    .sub_input:focus {
      width: 100%;
      outline-width: 0px;
      text-align: center;
    }
  }

  .horizontal_divider {
    width: 100%;
    height: 1px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    margin-bottom: 8px;
  }
}
