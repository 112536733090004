.answer_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  border-radius: 24px;
  margin-bottom: 32px;

  .answer_box_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .answer_box_top_user {
      display: flex;
      align-items: center;
      .user_name {
        margin-left: 8px;
      }
      .in_label {
        color: #000000;
        opacity: 0.6;
        margin-left: 12px;
        margin-right: 12px;
      }
    }
    .send_star_btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 16px 8px 8px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      gap: 8px;
    }
  }
  .answer_text {
    min-height: 70px;
    border: none;
    border-width: 0px;
    width: 100%;
    cursor: pointer;
    margin-top: 32px;
  }
  .answer_media {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 32px;
    justify-content: space-between;
    width: 100%;
    margin: 32px 0px;

    .answer_media_content {
      display: flex;
      flex-direction: row;
      align-items: center;
      .answer_media_content_txt {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 32px;
      }
    }
    .answer_media_download_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px 8px 12px;
      gap: 8px;

      width: 128px;
      height: 41px;

      /* Main/Primary */

      background: #ff3e9a;
      /* Line/Default */

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      .answer_media_download_btn_txt {
        font-style: normal;
        line-height: 180%;
        /* or 25px */
        color: #ffffff;
        display: flex;
        align-items: center;
      }
    }
  }
  .answer_box_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    // margin-top: 32px;
  }

  .agree_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 20px;
    gap: 8px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(17px);
    border-radius: 32px;

    .btn_number {
      color: #000000;
      opacity: 0.4;
      backdrop-filter: blur(14px);
    }
  }
  .agree_btn_highlighted {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 20px;
    gap: 8px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #ff3e9a;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    border-radius: 32px;
    margin-right: 12px;
  }
}
