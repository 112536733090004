.mobile_community_card_recent_history {
  gap: 16px;

  .user_image {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    object-fit: cover;
  }

  .user_name {
    flex: 1;

    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  }
}
