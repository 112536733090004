.mobile_join_new_group_home {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  padding: 16px;
  display: flex;

  &_body {
    flex: 1;
    overflow: auto;
  }
  .join_new_org_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 32px;
    justify-content: space-between;
    margin-bottom: 32px;
    position: absolute;
    background-color: white;
    width: 100%;

    .header_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-right: 32px;

      .navigation_back_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 12px;
        gap: 8px;

        /* Line/Default */
      }
      .title_text {
        width: 100%;
        text-align: center;
      }
    }
    .header_buttons {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;
      .join_by_email_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px 12px 16px;
        gap: 8px;
        height: 48px;
        color: #ff3e9a;
        /* Main/Primary */

        border: 1px solid #ff3e9a;
        border-radius: 24px;
      }
      .carete_new_email {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px 12px 16px;
        gap: 8px;
        height: 48px;

        /* Main/Primary */
        border-radius: 24px;
      }
    }
  }
  .card_org_panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    margin-top: 32px;
    .card_org_list_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      gap: 32px;
      height: 36px;
      width: 100%;
      .pending_count {
        color: #ff3e9a;
      }
    }
    .card_org_list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;
      width: 100%;

      .card_org {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        align-items: flex-start;
        padding: 20px 0px;
        gap: 24px;

        width: 100%;

        background: #ffffff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .org_box_icon {
          width: 84px;
          height: 84px;
          border-radius: 50%;
        }
        .org_info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;
          width: 100%;
          .first_line {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0px;
            width: 100%;
            .request_join_btn {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 8px 24px;
              gap: 8px;

              width: 140px;
              height: 38px;

              /* Main/Primary */

              background: #ff3e9a;
              border-radius: 48px;
            }
            .joined_btn {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 8px 24px;
              gap: 8px;
              height: 38px;

              /* Background/White */

              background: #ffffff;
              /* Line/Default */
              color: #000000;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 48px;
            }
            .requested_join_btn {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 8px 24px;
              gap: 8px;
              height: 38px;
              /* Background/White */
              background: #ffffff;
              /* Line/Default */
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 48px;
              color: #000000;
            }
          }
          .second_line {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            gap: 8px;
            width: 100%;
            // .members_count {

            // }
            .vip_members_count {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 0px;
              gap: 8px;

              width: 326px;
              height: 18px;

              border-radius: 16px;
              .vip_avatars {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;

                width: 40px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
  .create_new_org_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 32px;
    justify-content: space-between;
    margin-bottom: 32px;
    .back_to_main_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 12px 24px 12px 24px;
      gap: 8px;
      height: 48px;
      background: #ffffff;
      /* Line/Default */
      border-radius: 32px;
    }
  }
  .create_new_org_body {
    padding: 45px 192px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 56px;
    .input_panel {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 40px;
      .building_mark {
        width: 100%;
        height: 58px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 120%;
        /* identical to box height, or 58px */
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        backdrop-filter: blur(14px);
      }
      .header_bar {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 16px;
      }
      .email_input_list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        width: 100%;
        .email_input_bar {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 12px;
          width: 100%;
          .input_bar_order {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px;
            gap: 8px;

            width: 32px;
            height: 32px;
            opacity: 0.3;
            /* Main/Gray Shade */

            background: #f3f4f5;
            border-radius: 24px;
          }
          .input_email {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 16px;
            gap: 16px;
            height: 56px;
            background: #ffffff;
            width: 100%;
            /* Line/Default */
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            .email_input {
              width: 100%;
              border: none;
            }
            .email_input:focus {
              outline: none;
            }
          }
        }
      }
    }
    .bottom_btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 40px;
      gap: 16px;

      width: 100%;
      height: 45px;

      /* Main/Primary */

      background: #ff3e9a;
      border-radius: 48px;
    }
  }
}
