.mobile_card_animated_main_topic {
  min-width: 76px;
  height: 177px;
  background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin: 8px 0px;
  padding: 0px 8px;
  padding-top: 22px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .main_topic_name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 66px;
    color: white;
  }
}
