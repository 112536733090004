.chat_audio_file_player {
  height: 40px;
  position: relative;

  .action_item {
    width: 36px;
    height: 36px;
    background-color: white;
    /* Line/Default */

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 999;
  }

  .animation_view {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
