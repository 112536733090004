.mobile_individual_chat_input {
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 24px;

  .individual_mesasge_chat_box_input {
    flex: 1;
    border: none;
    border-width: 0px;
    height: 30px;
    margin-left: 8px;
    background-color: white;
  }

  .individual_mesasge_chat_box_input:focus {
    outline-width: 0px;
  }

  .trascript_btn {
    width: 32px;
    height: 32px;
    background-color: 32px;
    border: 1px solid #ff3e9a;
    border-radius: 32px;
    margin-right: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
