@import 'shared/utils/colors.scss';

.active_member {
  margin-top: 70px;

  .active_member_top_view {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .active_member_dump_editor {
    height: 120px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    padding: 24px;
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dump_editor_desc {
      display: flex;
      align-items: center;
    }

    .dump_editor_tab {
      height: 50px;
      border: 1px solid $color-border;
      border-radius: 50px;
      padding-left: 8px;
      padding-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      .dump_editor_selected_tab {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: $color-main;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 50px;
      }

      .dump_editor_default_tab {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .active_member_body {
    background-color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin-top: 32px;

    .active_member_body_header {
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 16px;
      padding-bottom: 16px;

      .active_member_body_header_member {
        flex: 1;
      }

      .active_member_body_header_joined_since {
        width: 120px;
        display: flex;
        justify-content: center;
        text-align: center;
      }

      .active_member_body_header_dump_editor {
        width: 150px;
        display: flex;
        justify-content: center;
      }

      .active_member_body_header_action {
        width: 160px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
