.mobile_add_link_modal {
  padding: 16px 24px;

  .link_container {
    margin-top: 25px;

    padding: 12px;
    border: 1px solid #ff3e9a;
    border-radius: 16px;

    .title {
      color: #ff3e9a;
    }

    .add_link_input_container {
      width: 100%;
      margin-top: 8px;

      .sub_input {
        width: 100%;
        flex: 1;
        border: none;
        border-width: 0px;
      }

      .sub_input:focus {
        outline-width: 0px;
      }
    }
  }

  .success_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .success_mark {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      background-color: #ff3e9a;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text_container {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0px 12px;
    }

    .continue_btn {
      width: 100%;
      height: 42px;
      border-radius: 42px;

      color: white;
      background-color: #ff3e9a;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .view_btn {
      width: 100%;
      height: 42px;
      border-radius: 42px;

      color: #ff3e9a;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.1);

      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }
  }
}
