.mobile_prompt_upload_modal {
  padding: 8px 16px;
  padding-bottom: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .header_view {
    width: 100%;
    padding-right: 24px;
    margin-top: 16px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    margin-top: 32px;
  }

  .action_body {
    width: 100%;
    margin-top: 32px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    .action_item {
      height: 56px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-radius: 24px;
      padding: 8px 16px;
      gap: 16px;
    }
  }
}
