.top_recommend_question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 48px;
    gap: 16px;

    width: 679px;
    margin:0px 8px;
    background: #FFFFFF;
    /* Line/Default */

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    &_header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;

        width: 565px;
        height: 100%;
        &_tip {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 16px 8px 8px;
            gap: 7px;

            width: 158px;
            height: 48px;

            background: rgba(255, 230, 242, 0.5);
            border-radius: 32px;
            &_lamp {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 8px;
                gap: 8px;
                justify-content: center;
                width: 32px;
                height: 32px;

                background: #FFFFFF;
                border-radius: 32px;
            }
            &_did_you_know {
                font-size: 14px;
                line-height: 21px;
                /* identical to box height */

                display: flex;
                align-items: center;

                /* Main/Primary */

                color: #FF3E9A;
            }

        }
        &_title {
            font-style: italic;
            // font-weight: 600;
            line-height: 60px;
            display: flex;
            align-items: center;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: pre-wrap;
            // overflow-y: auto;
            height: 130px;
            transition: height 0.1s;
        }

    }
    &_body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 16px;

        width: 565px;
        &_user {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 4px;
            font-style :'font-normal';
            &_name {
                font-weight: 500;
            }
        }
        &_btns {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            &_detail {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 8px 16px;
                gap: 7px;
                font-style: normal;
                font-weight: 500;
                width: 81px;
                height: 37px;
                justify-content: center;
                /* Line/Default */

                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 32px;
            }
            &_share {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px;
                gap: 7px;

                width: 37px;
                height: 37px;

                /* Line/Default */

                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 32px;
                
            } 
        }

    }
}