@import 'shared/utils/colors.scss';

.mobile_login_magic_link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .intro_img {
    width: calc(100% - 80px);
  }

  .login_btn {
    width: calc(100% - 56px);
    min-height: 60px;
    margin: 40px auto;
    background-color: #ff3e9a;
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content_body1 {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0px 36px;
    gap: 22px;

    .icon {
      display: flex;
      justify-content: center;
    }

    .title {
      font-size: 32px;
      margin-top: 22px;
      text-align: center;
    }

    .register {
      margin-top: 45px;
    }

    .registerTxt {
      font-size: 18px;
    }

    .register_input {
      width: 100%;
      margin-top: 12px;
      padding: 16px;
      border: 1px solid $color-border;
      border-radius: 16px;
      background-color: white;

      .your_email {
        color: rgba($color: $color-black, $alpha: 0.4);
      }

      .email_input {
        width: 100%;
        border: none;
        border-width: 0px;
      }
      .email_input:focus {
        outline-width: 0px;
      }
    }

    .add_referral {
      display: flex;
      margin-top: 6px;
      padding: 16px;
      border: 1px solid $color-border;
      border-radius: 16px;
      align-items: center;
      cursor: pointer;

      .icon {
        display: flex;
        align-items: center;
      }

      .add_referral_qr {
        color: $color-main;
        margin-left: 8px;
      }
    }

    .send_email_btn {
      width: 100%;
    }

    .already_registered {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 56px;
      margin-left: 10px;

      .check_referral {
        color: $color-main;
        cursor: pointer;
      }
    }

    .login_password_btn {
      color: $color-main;
      margin: 22px auto 0px;
      text-align: center;
    }

    .have_account {
      position: absolute;
      top: 550px;
    }

    .accept_term {
      margin-top: 32px;
    }
  }

  .resend_container {
    display: flex;
    flex-direction: column;
    gap: 22px;

    padding: 0px 24px;

    .resend_img {
      font-size: 88px;
      text-align: center;
    }

    .resend_title {
      text-align: center;
    }

    .resend_desc {
      text-align: center;
    }
  }
}
