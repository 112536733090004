@import 'shared/utils/colors.scss';

.reference_box {
  width: 100%;
  padding: 24px 24px 32px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px 20px 20px 20px;
  margin-top: 16px;

  .reference_text {
    color: #000000;
    opacity: 0.8;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .reference_user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    .on_label {
      color: #000000;
      opacity: 0.4;
    }
  }

  .row_divide_line {
    width: 100%;
    height: 1px;
    background-color: $color-border;
  }
}
