.shared_all_meme {
  padding: 46px 80px;

  .shared_all_meme_body {
    margin-top: 32px;
  }

  .full_image_viewer {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
