@import 'shared/utils/colors.scss';
.group_body {


    .AI_chat_button {
      position: fixed;
      right: 32px;
      bottom: 32px;
      width: auto;

      .ask_btn {
        border: 10px solid rgba(255, 62, 154, 0.1);
        backdrop-filter: blur(14.5px);
        border-radius: 32px;
        display: flex;
        align-items: center;
        height: 46px;
        border-radius: 46px;
        background-color: $color-main;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px 12px 16px;
        cursor: pointer;
        .ask_btn_txt {
          color: white;
          margin-left: 12px;
        }
    }
  }
  }