.mobile_individual_chat_body {
  height: calc(100% - 32px);
  margin-top: 8px;

  display: flex;
  flex-direction: column;

  .individual_message_body {
    flex: 1;
    overflow-y: auto;
    margin-top: 16px;
    padding: 0px 16px;

    display: flex;
    flex-direction: column-reverse;
  }

  .individual_message_share_view {
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px -10px 50px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .ended_chat_view {
    text-align: center;
    padding: 16px 8px;
    gap: 8px;

    background: #f3f4f5;
    border-radius: 24px;
    margin: 16px;
    margin-bottom: 24px;
  }

  .summary_card {
    width: 100%;
    background: #f3f4f5;
    padding: 12px;
    border-radius: 12px;
    margin-top: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .summary_message {
      width: 100%;
      padding: 8px;
      background-color: white;
      margin-top: 8px;
      margin-bottom: 8px;
      text-align: center;
      line-height: 25.2px;

      border-radius: 8px;
    }
  }

  .loading_bar {
    margin-left: 16px;
  }

  .browsing_status {
    width: fit-content;
    background: #ffffff;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    padding: 12px;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 16px;

    .expand_btn {
      margin-left: 16px;
    }
  }
}
