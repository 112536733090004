.share_point_right {
  flex: 1;
  margin-left: 72px;

  .selected_tab {
    background-color: white;
    border-radius: 16px;
    padding: 16px;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  }

  .default_tab {
    padding: 16px;
    background-color: transparent;
  }

  .send_more_btn {
    padding: 10px 24px;
    background-color: #ff3e9a;
    border-radius: 50px;

    .send_more_title {
      color: white;
      margin-left: 8px;
    }
  }
}
