.community_home {
  flex: 1;
  display: flex;
  flex-direction: column;

  .search_view {
    margin-top: 20px;
    padding: 0px 16px;
  }

  .community_home_body {
    display: flex;
    flex-direction: column;

    margin-top: 32px;
  }

  .loading_container {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.community_home_loading {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}
