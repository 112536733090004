.card_profile_group {
  width: 230px;
  height: 72px;
  border-radius: 16px;
  background-color: white;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  margin-right: 16px;
  box-shadow: 0px 4px 12px 0px #00000026;

  .org_image {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #ffffff;
  }

  .org_info {
    margin-left: 8px;

    .org_name {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: pre-wrap;
    }
    .org_followers {
      display: flex;
      margin-top: 3px;

      .org_follower_txt {
        margin-left: 4px;
        color: rgba($color: #000000, $alpha: 0.8);
      }
    }
  }
}
