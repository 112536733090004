@import 'shared/utils/colors.scss';

.card_active_member {
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-border;

  .card_active_member_info {
    flex: 1;
    margin-left: 12px;

    .card_active_member_info_top {
      display: flex;
      align-items: center;

      .active_member_admin {
        width: 90px;
        height: 24px;
        background: linear-gradient(90deg, #ff3e9a 0.59%, #d83eff 100.59%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        margin-left: 8px;
      }
    }
  }

  .card_active_member_joined_since {
    width: 120px;
    text-align: center;
  }

  .card_active_member_dump_editor {
    width: 150px;
    display: flex;
    justify-content: center;
  }

  .card_active_member_action {
    display: flex;
    align-items: center;

    .card_active_member_action_profile {
      width: 110px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-border;
      border-radius: 50px;
    }
  }
}
