@import 'shared/utils/colors.scss';

.card_topic {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  height: 60px;
  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px #00000026;
  margin-bottom: 4px;

  .org_box_icon {
    margin-right: 8px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
  }

  .org_box_label {
    color: #000000;
    backdrop-filter: blur(14px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
