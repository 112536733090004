.mobile_card_add_bot_selected {
  width: 52px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  position: relative;

  .bot_image {
    width: 52px;
    height: 52px;
    border-radius: 52px;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }

  .ai_name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    text-align: center;
  }

  .remove_icon {
    position: absolute;
    top: 0px;
    right: -6px;

    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
