@import 'shared/utils/colors.scss';

.group_member {
  padding-top: 48px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 48px;

  .group_member_header {
    display: flex;
    align-items: center;

    .header_info {
      flex: 1;
      margin-left: 24px;

      .pending_member_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 16px 16px 24px;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
        border-radius: 24px;

        .pending_member_count {
          background: $color-main;
          border-radius: 40px;
          width: 24px;
          height: 24px;
          text-align: center;
          color: #FFFFFF;
          margin-left: 24px;
        }
      }
    }
  }

  .group_member_body {
    margin-top: 40px;
  }
}
