.document_file_ai_view {
  margin: 24px 32px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 24px;

  .action_btn {
    width: 110px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 10px;
    border-radius: 50px;
  }

  .answer {
    margin: 10px 0px;
  }
}
