.mobile_join_card_org {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  gap: 24px;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .org_box_icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .org_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    .first_line {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px;
      width: 100%;
    }
    .second_line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      gap: 8px;
      width: 100%;
      // .members_count {

      // }
      .vip_members_count {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;

        width: 326px;
        height: 18px;

        border-radius: 16px;
        .vip_avatars {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;

          width: 40px;
          height: 16px;
        }
      }
    }
  }

  .request_join_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 8px;

    // width: 218px;
    // height: 38px;

    /* Main/Primary */

    background: #ff3e9a;
    border-radius: 48px;
  }
  .joined_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 8px;
    height: 38px;

    /* Background/White */

    background: #ffffff;
    /* Line/Default */
    color: #000000;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 48px;
  }
  .requested_join_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 8px;
    height: 38px;
    /* Background/White */
    background: #ffffff;
    /* Line/Default */
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 48px;
    color: #000000;
  }
}
