.mobile_bot_upload_audio_status {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 24px;

  .creation_body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-bottom: 40px;
  }

  .top_view_container {
    .round_out {
      padding: 16px;
      border-radius: 100%;
      background-color: rgba($color: white, $alpha: 0.5);

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid rgba($color: #ff3e9a, $alpha: 0.05);
    }

    .round_center {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background-color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid rgba(255, 255, 255, 0.36);
      filter: drop-shadow(0px 0px 16px rgba(236, 131, 98, 0.08));
    }

    .in_progress {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .percent_txt {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .user_image {
    position: absolute;
    width: 80%;
    height: 80%;
    border-radius: 100%;
    object-fit: cover;
    background-color: white;
  }
}
