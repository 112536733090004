.share_point_left {
  width: 550px;

  .link_view {
    width: 100%;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 16px;
    margin-top: 32px;

    .link_txt {
      width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .invite_view {
    gap: 8px;
    margin-top: 32px;

    .item_view {
      flex: 1;
      padding: 16px;
      border-radius: 16px;
      background-color: white;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

      .item_title {
        color: rgba(0, 0, 0, 0.4);
        margin-bottom: 12px;
      }
    }
  }

  .earn_point_view {
    margin-top: 8px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 28px 16px;
  }
}
