.mobile_bot_create_review {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 24px;

  .creation_body {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-bottom: 40px;

    .select_desc {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .direction_icon {
        margin-right: 60px;
      }

      .select_txt {
        color: #ff3e9a;
        text-align: center;
        margin-top: 4px;
      }
    }
  }
}
