.card_event_hosted {
  width: 300px;
  height: 90px;

  background: #ffffff;
  box-shadow: 0px 4px 12px 0px #00000026;
  border-radius: 16px;
  margin-right: 8px;

  display: flex;
  justify-content: center;

  .card_mark {
    width: 4px;
    height: 20px;
    background-color: #ff3e9a;
    margin-right: 12px;
  }

  .card_title {
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
