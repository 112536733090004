@import 'shared/utils/colors.scss';

.home_bookmark {
  min-height: 100vh;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .bookmark_navbar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px;
    gap: 16px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 80px;
    margin-left: 32px;

    .bookmark_navbar_item_selected {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px;
      gap: 4px;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
      /* Main/Primary */

      background: #ff3e9a;
      border-radius: 40px;
    }
    .bookmark_navbar_item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px;
      gap: 8px;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
      opacity: 0.4;
    }
  }

  .home_bookmark_body {
    // padding-bottom: 64px;

    &_empty_view {
      width: fit-content;
      background-color: white;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
      padding: 24px;
      border-radius: 16px;
      margin: auto;
      margin-top: 150px;
    }
  }

  .infinite-scroll-component {
    padding-right: 32px;
    padding-top: 8px;
  }
}
@media only screen and (max-width: 768px) {
  .home_bookmark {
    padding: 0px 16px;
  }
}
