.modal_social_item {
  padding: 16px;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-top: 16px;

  .social_content {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: calc(100% - 20px);
  }

  .social_content_divider {
    height: 32px;
    width: 1px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    margin: 0px 16px;
  }

  .social_content_name {
    width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
