@import 'shared/utils/colors.scss';
.mobile_card_community_member {
  width: 100%;
  height: 183px;

  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  margin-bottom: 16px;
  padding: 16px;

  .user_image {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    background-color: rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }

  .low_color {
    color: #f1483f;
  }

  .med_color {
    color: #ffb258;
  }

  .high_color {
    color: #08b883;
  }

  .low_bar_color {
    background-color: #f1483f;
  }

  .med_bar_color {
    background-color: #ffb258;
  }

  .high_bar_color {
    background-color: #08b883;
  }

  .progress_bar {
    width: 60px;
    height: 4px;
    border-radius: 4px;
    background-color: #f3f4f5;
    position: relative;

    .actived_progress {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }
  }

  .chat_btn {
    min-width: 30px;
    height: 30px;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .follow_btn {
    width: calc(100%);
    height: 30px;

    background: #ff3e9a;
    border-radius: 32px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .following_btn {
    width: calc(100%);
    height: 30px;

    /* Line/Default */

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .requested_btn {
    width: calc(100%);
    height: 30px;

    background-color: $color-pink;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
}
