.card_person_ai {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

  &_image {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_info {
    flex: 1;
    margin-left: 12px;
  }

  .select_btn {
    width: 70px;
    height: 30px;
    border-radius: 30px;
    background-color: #ff3e9a;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .selected_btn {
    width: 70px;
    height: 30px;
    color: rgba($color: #000000, $alpha: 0.3);
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
