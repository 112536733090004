.group_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: linear-gradient(180deg, #ffffff 0%, #f3f4f5 100%);

  .group_body {
    padding: 32px;
    padding-bottom: 0px;
    height: calc(100% - 100px);
  }
}
