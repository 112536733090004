.mobile_feed_question_add_answer_modal_body {
  height: 100% !important;
  border-radius: 0px;
}

.mobile_feed_question_add_answer_modal {
  height: 100%;
  overflow: hidden;
  background-color: #f3f4f5;

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding-bottom: 24px;

  .modal_header {
    margin: 0px 16px;
    margin-top: 24px;

    .topic_list {
      width: calc(100% - 40px);
      display: flex;
      flex-direction: row;
      align-items: center;

      overflow-x: auto;
      gap: 8px;
      margin-left: 8px;

      .default_topic_item {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        color: rgba(0, 0, 0, 0.6);
      }
    }

    .item_btn {
      width: 32px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .question_view {
    margin: 0px 16px;
    text-align: center;
    line-height: 45px;
  }

  .modal_action_view {
    gap: 8px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .item_view {
      padding: 12px 16px;
      height: 33px;
      gap: 2px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .action_item {
      width: 32px;
      height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .add_answer_container {
    flex: 1;
    background: #ffffff;
    border-radius: 24px;
    margin: 0px 16px;

    padding: 20px;
    background: #ffffff;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 24px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    .answer_text_area {
      flex: 1;
      width: 100%;
      border: none;
      border-width: 0px;
    }

    .answer_text_area:focus {
      outline: none;
    }

    .add_answer_bottom_bar_btns {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;

      .add_answer_bottom_bar_referneces {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;

        .user_star_btn {
          width: 33px;
          height: 33px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 32px;

          .user_avatar {
            width: 30px;
            height: 30px;
            border-radius: 32px;
          }
        }
        .user_star_btn_selected {
          width: 33px;
          height: 33px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          border-radius: 32px;
          border: 2px solid #ff3e9a;

          .user_avatar {
            width: 30px;
            height: 30px;
            border-radius: 30px;
          }
        }
      }
      .add_answer_btn {
        display: flex;
        flex: 1;
        height: 36px !important;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #ff3e9a;
        border: 1px solid #ff3e9a;
        border-radius: 32px;
        color: #ffffff;
      }
    }
  }

  .trascript_btn {
    width: 32px;
    height: 32px;
    background-color: 32px;
    border: 1px solid #ff3e9a;
    border-radius: 32px;
    margin-right: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
