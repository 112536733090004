.mobile_ask_question {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  padding-top: 32px;

  .add_question_btn {
    width: 100px;
    height: 30px;
    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    background-color: #ff3e9a;
  }

  .ask_question_btn {
    width: 70px;
    height: 30px;
    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    background-color: #ff3e9a;
  }

  .ask_bar {
    margin-top: 16px;
    padding: 0px 16px;
  }

  .asked_question_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px;
    gap: 12px;
    background-color: white;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  .mobile_question_body {
    flex: 1;
    overflow-y: auto;

    padding: 16px 0px;
  }

  .mobile_could_not_found {
    padding: 12px 16px;
    background: #ffffff;
    box-shadow: 0px 8px 44px -4px rgba(0, 0, 0, 0.35);
    border-radius: 24px;
    gap: 8px;

    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  .loading_bar_container {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
