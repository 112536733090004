.mobile_event_detail {
  display: flex;
  flex-direction: column;
  background-color: #f3f4f5;

  width: 100%;
  height: 100%;

  .loading_container {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f5;

    position: absolute;
    width: 100%;
    height: 100%;
  }

  .image_view {
    width: 100%;
    height: 50%;
    position: absolute;
    border-radius: 32px;
    z-index: 1;
    object-fit: cover;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal_header {
    border-radius: 32px;
    padding: 16px;
    z-index: 1000;

    .camera_btn {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background-color: white;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile_event_detail_body {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;

    position: absolute;

    z-index: 1000;

    .top_body {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;

      .top_info {
        flex: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;
      }
    }

    .event_detail_users {
      width: fit-content;
      padding: 12px;
      margin: 0px 16px;
      border-radius: 50px;
      background-color: white;
      margin-top: 16px;

      .event_user_image {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        object-fit: cover;
        background-color: rgba($color: #000000, $alpha: 0.2);
      }
    }
  }

  .mobile_event_sub_info {
    flex: 1;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    padding: 32px 24px;
    margin-top: -32px;

    gap: 16px;

    display: flex;
    flex-direction: column;
  }

  .claim_btn {
    width: 130px;
    height: 30px;
    border-radius: 38px;
    background-color: #ff3e9a;
    color: white;
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .claimed_button {
    width: 90px;
    height: 30px;
    border-radius: 38px;
    color: black;
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #ffe5f2;
  }

  .content_divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
