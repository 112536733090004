.mobile_choose_voice {
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .choose_vocie_title {
    text-align: center;
    margin-top: -24px;
  }

  .search_view {
    width: 100%;
    margin-top: 16px;
    padding: 0px 16px;
  }

  .bot_list {
    flex: 1;
    width: 100%;
    padding: 0px 16px;
    margin-top: 10px;
    margin-bottom: 180px;
    padding-bottom: 20px;

    overflow-y: auto;

    .bot_list_body {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .bottom_container {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    padding: 16px 16px;
    background-color: white;

    .skip_btn {
      width: 100%;
      margin-top: 20px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .skip_btn_txt {
        text-align: center;
      }
    }
  }
}
