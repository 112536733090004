.mobile_profile_social {
  .profile_social_body {
    padding: 0px 16px;
  }

  .mobile_profile_social_container {
    background-color: white;
    border-radius: 16px;
    padding: 24px 0px;

    &_body {
      flex: 1;
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: auto;

      margin-top: 16px;
      padding: 0px 16px;
      padding-bottom: 2px;

      .social_item {
        background-color: white;
        border-radius: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 8px;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        padding: 8px 16px;
        gap: 16px;
      }

      .social_item_name {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 130px;
        white-space: nowrap;
        display: inline-block;
      }

      .social_item_icon {
        margin-right: 16px;
      }

      .social_item_divider {
        height: 32px;
        width: 1px;
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
    }

    &_connect {
      color: #ff3e9a;
    }

    .loading_container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }
  }
}
