.mobile_promot_conversation {
  display: flex;
  flex-direction: column;
  background-color: white;

  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;

  padding: 24px 0px;
  padding-bottom: 0px;
  overflow: hidden;

  .end_btn {
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 20px;
    padding: 2px 10px;
  }

  .share_title {
    flex: 1;
    text-align: center;
    margin-right: 32px;
  }
}
