@import 'shared/utils/colors.scss';

.modal_create_group {
  margin-top: 16px;

  .modal_create_group_header {
    display: flex;
    align-items: center;

    .modal_back_btn {
      cursor: pointer;
    }

    .title {
      flex: 1;
      text-align: center;
      margin-right: 24px;
    }
  }

  .modal_create_group_body {
    margin-top: 30px;
    .card_share_question_org {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      margin-top: 16px;
      margin-bottom: 16px;
      .reference_bar {
        display: flex;
        gap: 8px;
        width: 100%;

        .row_align_items {
          gap: 8px;
          width: 100%;
        }
      }
      .card_share_question_org_user {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 8px;
        &_image {
          justify-content: center;
          display: flex;
          width: 20px;
          height: 20px;
        }
      }
      .text_answer {
        max-height: 256px;
        overflow-y: auto;
      }
      .card_share_question_org_question_text_area {
        border: none;
        width: 100%;
        max-height: 350px;
        overflow-y: auto;
      }
      .card_share_question_org_question_text_area:focus {
        outline: none;
      }
      .save_answer_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 60px;
        gap: 8px;
        background: #ff3e9a;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        color: #ffffff;
      }
      .cancel_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 48px;
        gap: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 32px;
      }
      .edit_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 32px;
        width: 32px;
        height: 32px;
        // margin-top: -20px;
      }
    }
    .share_with_answer_switch {
      box-sizing: border-box;
      /* Auto layout */

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0px 16px 16px;
      gap: 16px;
      height: 56px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      &_left {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 18.92px;
        &_icon {
          opacity: 0.3;
        }
      }
    }
    .sub_title {
      font-size: 10px;
      color: rgba($color: #000000, $alpha: 0.4);
    }

    .sub_input {
      width: 100%;
      border: none;
      border-width: 0px;
      margin-top: 8px;
    }

    .sub_input:focus {
      outline-width: 0px;
    }

    .group_name {
      border: 1px solid $color-border;
      border-radius: 16px;
      padding: 16px;
    }

    .group_desc {
      border: 1px solid $color-border;
      border-radius: 16px;
      padding: 16px;
      margin-top: 16px;
    }

    .btn_create {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      .cancel_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 16px;
        height: 45px;
        width: 100%;
        background: #ffffff;
        /* Line/Default */

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 48px;
      }
      .cancel_btn:hover {
        background: #f9f9f9;
      }
    }
  }
}
