.mobile_card_flip_question {
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 32px;

  position: relative;
  overflow: hidden;

  .mobile_card_flip_question_main {
    flex: 1;
    overflow-y: auto;

    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .detail_btn {
    width: 32px;
    height: 32px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flip_question_body {
    height: calc(100%);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .feed_main_content {
      max-height: 100%;
      overflow-y: auto;
      padding-bottom: 10px;
    }

    .flip_question_image {
      width: 100%;
      aspect-ratio: 319 / 205;
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        #d9d9d9;
      border-radius: 16px;
    }

    .question_txt {
      font-size: 32px;
    }
  }

  .flip_question_bottom {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background: linear-gradient(
      180deg,
      #ffffff 70%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
    transform: matrix(1, 0, 0, -1, 0, 0);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px 16px;
    padding-bottom: 70px;
    border-radius: 16px;

    .flip_action_conatiner {
      width: 100%;
      gap: 8px;
      transform: matrix(1, 0, 0, -1, 0, 0);
      justify-content: center;

      position: absolute;
      top: 16px;

      .flip_btn {
        min-width: 80px;
        height: 36px;

        color: #ff3e9a;
        background: #ffffff;
        /* Main/Primary */

        border: 1px solid #ff3e9a;
        border-radius: 32px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
