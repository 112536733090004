.mobile_login_qr_modal {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_header {
    display: flex;
    align-items: center;
  }

  &_image {
    width: 330px;
    height: 330px;
    border-radius: 32px;
    border: 3px solid rgba($color: #ff3e9a, $alpha: 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_close {
    padding: 12px 48px;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 38px;
  }
}
