@import 'shared/utils/colors.scss';
.reference_modal_custom_body {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
}

.dlg_add_reference {
  position: absolute;
  width: 400px;
  background: #ffffff;
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 16px;
  z-index: 1;

  .dlg_tab {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;

    .dlg_tab_creator {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      border-bottom: 1px solid $color-main;
    }

    .dlg_tab_document {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      border-bottom: 1px solid $color-main;
    }
  }

  .dlg_list {
    height: 200px;
    overflow-y: auto;
    margin-top: 12px;
    margin-bottom: 16px;
  }

  .dlg_list_creator {
    height: 200px;
    overflow-y: auto;
    margin-top: 12px;
    margin-bottom: 16px;
  }

  .dlg_list_document {
    height: 200px;
    overflow-y: auto;
    margin-top: 12px;
    margin-bottom: 16px;
  }

  .done_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9.20245px 13.8037px;
    gap: 9.2px;
    background: $color-main;
    border: 1.15031px solid $color-main;
    border-radius: 36.8098px;
    color: #ffffff;
    margin-top: 12px;
  }
}
