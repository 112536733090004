.card_shared_doc_container {
  position: relative;
  margin-right: 16px;
  padding-top: 6px;
  display: flex;
  flex-direction: column;

  .card_shared_doc_org {
    min-width: 200px;
    max-width: 200px;
    height: 260px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    margin: 16px;

    .top_icon_body {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info_body {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .doc_title {
      height: 35px;
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }

  .sub_effect_body1 {
    width: 100px;
    height: 240px;
    background-color: rgba($color: #ffffff, $alpha: 0.4);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: absolute;
    top: 28px;
    right: 2px;
    z-index: 9;
  }
}
