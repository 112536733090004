@import 'shared/utils/colors.scss';

.modal_create_event_mobile {
  display: flex;
  flex-direction: column;
  background: #f3f4f5;
  border-radius: 32px;
  width: 100vw;
  height: 100%;

  .modal_header {
    border-radius: 32px;
    padding: 32px;
    z-index: 1000;

    .camera_btn {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      background-color: white;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .modal_body {
    flex: 1;
    padding: 32px;
    padding-top: 0px;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title_input {
      width: 100%;
      border: none;
      border-width: 0px;
      margin-top: 16px;
      background-color: transparent;
      text-align: center;
      color: white;
      caret-color: #ff3e9a;
      // text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
    }

    .title_input:focus {
      outline-width: 0px;
      background-color: transparent;
      caret-color: #ff3e9a;
      // text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
    }

    .default_title_input {
      width: 100%;
      border: none;
      border-width: 0px;
      margin-top: 16px;
      background-color: transparent;
      text-align: center;
      caret-color: #ff3e9a;
    }
    .default_title_input:focus {
      outline-width: 0px;
      background-color: transparent;
      caret-color: #ff3e9a;
    }
  }

  .event_main_body {
    margin-top: 0px;
    background: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding: 24px;
    padding-bottom: 0px;
    z-index: 1000;

    .sub_title {
      color: rgba(0, 0, 0, 0.6);
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      margin: 16px 0px;
    }

    .desc_input {
      flex: 1;
      width: 100%;
      border: none;
      border-width: 0px;
      margin-top: 16px;
      background-color: transparent;
    }
    .desc_input:focus {
      outline-width: 0px;
      background-color: transparent;
    }

    .date_view {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .date_sub_view {
        flex: 1;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 16px;

        select {
          appearance: none;
        }

        .react-datepicker-ignore-onclickoutside {
          border: none;
        }

        .react-time-picker__wrapper {
          border: none;
        }

        .react-time-picker__clear-button {
          display: none;
        }

        .react-time-picker__clock-button {
          display: none;
        }

        .react-time-picker__clock {
          z-index: 999999999;
          display: none;
        }

        input {
          border: none;
          width: 150px;
        }
      }
    }

    .org_view {
      .org_image {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .action_view {
      margin: 32px 0px;

      display: flex;
      align-items: center;

      .cancel_btn {
        flex: 1;
        height: 45px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 48px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .publish_btn {
        flex: 1;
        height: 45px;
        border-radius: 48px;
        background-color: #ff3e9a;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .image_view {
    width: 100%;
    height: 300px;
    border-radius: 32px;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main_view {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100%);

    display: flex;
    flex-direction: column;
  }
}
