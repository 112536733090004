.mobile_bot_define {
  flex: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  .top_view_container {
    .round_out {
      padding: 16px;
      border-radius: 100%;
      background-color: rgba($color: white, $alpha: 0.5);

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid rgba($color: #ff3e9a, $alpha: 0.05);
    }

    .round_center {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background-color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid rgba(255, 255, 255, 0.36);
      filter: drop-shadow(0px 0px 16px rgba(236, 131, 98, 0.08));
    }
  }

  .bot_define_body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    flex: 1;
    margin-top: 16px;
    padding: 0px 24px;

    .define_title {
      text-align: center;
    }

    .define_desc {
      text-align: center;
    }

    .prompt_box_input {
      flex: 1;
      border: none;
      border-width: 0px;
      background-color: white;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 16px;

      padding: 12px 16px;
      resize: none;
    }

    .prompt_input:focus {
      outline-width: 0px;
    }
  }
}
