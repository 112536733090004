.answer_add_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
    border-radius: 24px;
    margin-bottom: 32px;
    .answer_add_top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        width: 100%;
        margin-bottom: 24px;
        .add_answer_box_top {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            .add_answer_top_user {
                display: flex;
                align-items: center;
            }
            .align-vertical-center {

                .in_label {
                    color: #000000;
                    opacity: 0.6;
                    margin-left: 12px;
                    margin-right: 12px;
                }
            }
            .posted_question {
                color: #000000;
                opacity: 0.6;
                margin-left: 12px;
            }
           
        }
        .question_text {
            min-height: 70px;
            border: none;
            border-width: 0px;
            width: 100%;
            cursor: pointer;
        }
        .add_answer_bar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 36px 0px;
            gap: 24px;
            height: 22px;
            width: 100%;
            .left_side {
                display: flex;
                justify-content: space-between;
                .question_info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 16px;
                    height: 22px;
                    .snack {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0px;
                        gap: 8px;
                        height: 22px;
                        justify-content: center;
                    }
                }
                .question_points {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 2px;
                    height: 20px;
                    margin-left: 36px;
                    border-radius: 32px;
                }
            }
            .right_side {
                display: flex;
                justify-content: space-between;
                .question_date {
                    display: flex;
                    align-items: center;
                    text-align: right;
                    margin-right: 36px;
                    color: #000000;
                }
                .add_answer_btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 16px;
                    gap: 8px;
                    height: 41px;
                    background: #FF3E9A;
                    border: 1px solid #FF3E9A;
                    border-radius: 32px;
                    color: #FFFFFF;
                }
            }
        }
    }
    .normal_answer {
        margin-top:24px;
        margin-bottom: 24px;
        .add_your_edit {
            color: #FF3E9A;
            margin-top:24px;
        }
        
        
    }
    .reference_status_bar {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        margin-top:48px;
        .document_layout_cirfle {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;
            gap: 8px;

            width: 40px;
            height: 40px;

            background: #FFFFFF;
            /* Line/Default */

            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 32px;
        }
        .selected_reference_bar {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            width: 100%;
            .reference_item1 {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 8px 16px 8px 8px;
                gap: 8px;
                height: 40px;
                background: #FFFFFF;
                border-radius: 32px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                .user_avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                }
                .user_name {
                    margin-left: 8px;
                    margin-right: 8px;
                
                }
            }
        }
    }
    .answer_text_area {
        width: 100%;
        height: 300px;
        border: none;
        border-width: 0px;
        margin-top: 32px;
        margin-bottom: 32px;
      }
      .answer_text_area:focus {
        outline: none;
      }
    .add_answer_detail_box {
        width: 100%;
        margin-top: 24px;
        // padding: 24px;
        margin-bottom: 24px;
        
        .add_answer_bottom_bar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            gap: 8px;
            height: 48px;
            .add_answer_bottom_bar_icons {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 16px;
                height: 44px;
                .icon_circle {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 12px;
                    gap: 8px;
                    width: 44px;
                    height: 44px;
                    /* Line/Default */
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 32px;
                }
            }
            .add_answer_bottom_bar_btns {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                gap: 7px;
                height: 48px;
                .add_answer_bottom_bar_referneces {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 8px;
                    height: 48px;
                    .user_star_btn {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 8px;
                        gap: 8px;
                        background: #ffffff;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 32px;
                        margin-left: 8px;
                        .user_avatar {
                            width: 30px;
                            height: 30px;
                            border-radius: 30px;
                        }
                        .user_name {
                            margin-left: 8px;
                            margin-right: 8px;
                        
                        }
                        .star_icon {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 8px;
                            gap: 8px;
                            width: 32px;
                            height: 32px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 40px;
                        }
                    }
                    .user_star_btn_selected {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 8px;
                        gap: 8px;
                        background: #ffffff;
                        border: 1px solid #FF3E9A;
                        border-radius: 32px;
                        margin-left: 8px;
                        .user_avatar {
                            width: 30px;
                            height: 30px;
                            border-radius: 30px;
                        }
                        .user_name {
                            margin-left: 8px;
                            margin-right: 8px;
                        
                        }
                        .star_icon {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 8px;
                            gap: 8px;
                            width: 32px;
                            height: 32px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 40px;
                        }
                    }
                }
                .add_answer_btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 12px 16px 12px 12px;
                    gap: 8px;
                    background: #ff3e9a;
                    border: 1px solid #ff3e9a;
                    border-radius: 32px;
                    color: #ffffff;
                    margin-left: 12px;
                }
            }
        }
    }
    
}