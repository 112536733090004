.card_chat_history {
  height: 130px;
  background-color: white;
  border-radius: 16px;
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;

  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;

    &_image {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_group {
      margin-left: 8px;
    }
  }

  &_name {
    margin-top: 8px;
    margin-bottom: 8px;
    width: calc(100%);
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_date {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.4);
  }
}
