.modal_mobile_select_ai_person {
  background: #ffffff;
  padding: 16px 0px;

  &_header {
    display: flex;
    align-items: center;
  }

  &_sub_title {
    color: rgba($color: #000000, $alpha: 0.4);
    margin-top: 32px;
  }

  &_body {
    &_item {
      display: flex;
      align-items: center;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    }

    .ai_type_icon {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btn_to_creators {
    display: flex;
    padding: 8px 16px;
    width: 100%;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 53px;
  }

  .modal_select_ai_person_body {
    max-height: 350px;
    overflow-y: auto;
  }
  @media only screen and (max-width: 768px) {
    .modal_select_ai_person {
      position: relative;
      width: 90%;
      bottom: 0;
      left: 0;
      top: 0;
      margin: auto;
    }
  }
}
