@import 'shared/utils/colors.scss';

.content_body {

  .send_email_btn {
    margin-top: 16px;
  }

  .already_registered {
    display: flex;
    align-items: center;
    margin-top: 56px;
    margin-left: 10px;

    .check_referral {
      color: $color-main;
      cursor: pointer;
    }
  }

}
