.mobile_card_user_bot {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 12px;
  margin: 8px 16px;

  gap: 8px;

  .bot_image {
    width: 52px;
    height: 52px;
    border-radius: 52px;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }

  .main_info {
    flex: 1;
    margin-left: 12px;

    .bot_description {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;

      opacity: 0.8;
    }
  }

  .action_btn {
    width: 30px;
    height: 30px;

    .default_mark {
      width: 24px;
      height: 24px;

      /* Main/Gray Shade */

      background: #f3f4f5;
      /* Line/Default */

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 32px;
    }
  }

  .bot_icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #ff3e9a;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0px;
    right: -6px;
  }
}
