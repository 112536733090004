.card_saved_highlight {
  margin-top: 24px;
  border: 1.25px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 32px;

  .highlight_txt_bg {
    width: fit-content;
    background-color: #ffe6f2;
    padding: 0px 6px;
    margin-top: 16px;
  }
}
