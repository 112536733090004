.mobile_question_ask_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  gap: 12px;
  background-color: white;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);

  .trascript_btn {
    min-width: 32px;
    height: 32px;
    background-color: 32px;
    border: 1px solid #ff3e9a;
    border-radius: 32px;
    margin-right: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .question_ask_box_input {
    height: 40px;
    border: none;
  }
  .question_ask_box_input:focus {
    outline: none;
  }

  .find_asnwer_btn {
    width: 190px;
    height: 44px;
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
