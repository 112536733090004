.mobile_question_action_modal {
  padding: 12px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .top_div {
    width: 60px;
    height: 4px;
    border-radius: 4px;
    background-color: #d9d9d9;
  }

  .action_item {
    width: 100%;
    height: 64px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 16px;
    gap: 8px;

    .action_icon {
      width: 32px;
      height: 32px;

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 40px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
