@import 'shared/utils/colors.scss';

.waiting_list {
  width: 550px;
  border: 1px solid $color-border;
  border-radius: 40px;
  background-color: $color-white;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .wait_home {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 46px;
    padding: 46px 0px;
  }

  .wait_txt {
    text-align: center;
  }

  .register_input {
    width: 100%;
    margin-top: 12px;
    padding: 16px;
    border: 1px solid $color-border;
    border-radius: 16px;

    .your_email {
      color: rgba($color: $color-black, $alpha: 0.4);
    }
    .email_input {
      width: 100%;
      border: none;
      border-width: 0px;
    }
    .email_input:focus {
      outline-width: 0px;
    }
  }

  .home_back_btn {
    position: absolute;
    top: -50px;
    left: 0px;
  }
  .send_email_btn {
    width: 100%;
  }

  .wait_success {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 46px;
    padding: 46px 0px;

    .wait_tip {
      width: 100%;
      height: 64px;
      background: rgba(243, 244, 245, 0.5);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
