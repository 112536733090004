.template_answer_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 16px;
    background: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin-top: 20px;
    .used_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        gap: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 40px;
        width: 100%;
        cursor: pointer;
    }
    .use_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        gap: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 40px;
        width: 100%;
        color: #FF3E9A;
        cursor: pointer;
    }
    
}