.link_preview_tag_filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 32px;

  &_tag {
    max-width: calc(100% - 140px);
    margin-left: 16px;
  }

  .add_new_tag_btn {
    min-width: 244px;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    gap: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    margin-left: 10px;

    .create_tag_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 14px;
      gap: 8px;

      width: 57px;
      height: 28px;
      color: white;
      justify-content: center;
      background: #ff3e9a;
      border-radius: 32px;
    }
    .tag_name_input {
      border: none;
      background: none;
      width: 100%;
      caret-color: black;
      color: black;
    }
    .tag_name_input:focus {
      outline: none;
    }
    .tag_name_input::placeholder {
      color: #000000;
      opacity: 0.4;
    }
  }
}
