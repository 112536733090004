.link_preview_tag_view {
  height: 37px;
  display: flex;
  align-items: center;
  background-color: #cce7ff;
  margin-left: 8px;
  white-space: nowrap;
  border-radius: 4px;

  .tag_view_marker {
    width: 4px;
    height: 100%;
    background-color: #2f9bff;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .tag_view_text {
    margin-left: 8px;
    margin-right: 16px;
  }

  .tag_view_image {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-left: 12px;
  }
}
