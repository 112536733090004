.folder_card {
  display: flex;
  flex-direction: column;

  .folder_card_top {
    width: fit-content;
    display: flex;
    position: relative;
    z-index: 10;

    .left_view {
      width: 52px;
      height: 100%;

      background: #ffffff;
      border-width: 1px 0px 0px 1px;
      border-style: solid;
      border-color: #ff3e9a;
      border-radius: 12px 0px 0px 0px;

      display: flex;
      align-items: center;
      justify-content: center;

      .number_txt {
        color: #0f0f0f3d;
        margin-top: 4px;
      }
    }

    .right_view {
      width: 48px;
      height: 100%;
      margin-left: -2px;
      overflow: hidden;
    }
  }

  .folder_card_body {
    width: 100%;
    padding: 12px 16px;
    margin-top: -1px;
    background-color: white;

    position: relative;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-top-right-radius: 32px;
  }

  .folder_card_body::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 32px;
    border-top-left-radius: 0px;
    padding: 1px;
    background: linear-gradient(
      180.44deg,
      #ff3e9a 11.34%,
      rgba(216, 62, 255, 0) 90.64%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
