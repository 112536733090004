@import 'shared/utils/colors.scss';

.conversation_reference_box {
  width: 100%;
  padding: 24px 24px 32px;
  margin-top: 16px;
  background-color: #f3f4f580;
  height: 300px;
  overflow-y: auto;
  .reference_text {
    color: #000000;
    opacity: 0.8;
    margin-top: 24px;
    margin-bottom: 8px;
    font-style: italic;
  }

  .reference_user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    .on_label {
      color: #000000;
      opacity: 0.4;
    }
  }

  .row_divide_line {
    width: 100%;
    height: 1px;
    background-color: $color-border;
  }
}
