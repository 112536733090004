@import 'shared/utils/colors.scss';

.top_view {
  width: 100%;
  height: 180px;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  padding: 24px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_view {
    display: flex;
    align-items: center;
  }

  .right_view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;

    .email_input {
      width: 100%;
      border: none;
      border-width: 0px;
      margin-top: 4px;
    }

    .email_input:focus {
      outline-width: 0px;
    }

    .send_invite_btn {
      width: 120px;
      height: 43px;
      border-radius: 43px;
      background-color: $color-main;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
  }

  .sub_container {
    height: 70px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }
}
