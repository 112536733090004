.mobile_feed_question_answers_modal_body {
  height: 100% !important;
  border-radius: 0px;
}

.mobile_question_answers_mdoal {
  z-index: 1000 !important;
}

.mobile_feed_question_answers_modal {
  height: 100%;
  overflow: hidden;
  background-color: #f3f4f5;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .modal_header {
    width: calc(100% - 32px);
    margin: 0px 16px;
    margin-top: 24px;

    .header_info {
      display: flex;
      flex-direction: column;

      width: calc(100% - 40px);
      gap: 4px;

      .question_txt {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .item_btn {
      min-width: 32px;
      min-height: 32px;

      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .topic_list {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      overflow-x: auto;
      gap: 8px;

      .default_topic_item {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .answer_list {
    flex: 1;
    overflow-y: auto;

    padding-bottom: 32px;
  }

  .empty_view_container {
    width: 100%;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    .empty_view {
      width: calc(100% - 32px);
      padding: 24px;
      margin-bottom: 24px;

      background-color: white;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
    }
  }
}
