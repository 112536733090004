.mobile_community_home {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  .community_tab {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 16px;

    .selected_tab {
      flex: 1;
      border-bottom: 4px solid #ff3e9a;
      color: black;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .default_tab {
      flex: 1;
      border-bottom: 4px solid white;
      color: rgba($color: #000000, $alpha: 0.4);

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .community_body {
    flex: 1;

    display: flex;
    flex-direction: column;
  }
}
