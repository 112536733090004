.highlight_conversation {
  display: flex;
  flex-direction: column;
  height: 800px;
  background-color: white;
  margin: 24px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px;

  &_body {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column-reverse;
    padding-left: 24px;
    padding-right: 24px;
  }

  &_input {
    height: 64px;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;

    .chat_box_input {
      flex: 1;
      border: none;
      border-width: 0px;
    }

    .chat_box_input:focus {
      outline-width: 0px;
    }
  }

  .action_btn {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 10px;
    border-radius: 50px;
  }
}
