.mobile_card_feed_collect_filter {
  margin-top: 12px;

  .filter_image {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    object-fit: contain;
  }

  .default_filter_item {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 0px 16px;
  }

  .selected_filter_item {
    width: 100%;
    height: 60px;

    background: rgba(255, 230, 242, 0.5);
    border-radius: 16px;

    padding: 0px 16px;
    margin-bottom: 6px;
  }

  .default_mark {
    width: 24px;
    height: 24px;

    /* Main/Gray Shade */

    background: #f3f4f5;
    /* Line/Default */

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 32px;
  }
}
