.mobile_feed_topics {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4px;

  max-width: calc(100% - 86px);
  padding-top: 16px;
  padding-bottom: 16px;

  overflow-x: auto;

  position: absolute;
  left: 70px;
  padding-left: 50px;
  padding-right: 40px;

  .topic_item {
    padding-left: 12px;
    padding-right: 12px;
    height: 40px;
    margin-right: 4px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    margin-top: 2px;
    margin-bottom: 2px;
  }

  .default_topic_item {
    padding-left: 12px;
    padding-right: 12px;
    height: 40px;
    margin-right: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    margin-top: 2px;
    margin-bottom: 2px;
  }
}
